import React from "react";
import { Container, Image } from "react-bootstrap";

import { StaticImages } from "../../../utils/constants";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";

function MaintenancePage() {
  return (
    <>
      <MainLayout>
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>

          <div className="my-2">
            <div className="p-2 text-center">
              <div className="fw-bold text-appColor1 fs-5">
                Sorry for the inconvenience application is currently undergoing
                maintenance.
              </div>
              <Image
                src={StaticImages.App.AppMaintenance}
                height={"50%"}
                width={"50%"}
                alt="App Maintenance Image"
              />
            </div>
          </div>
        </Container>
      </MainLayout>
    </>
  );
}

export default MaintenancePage;
