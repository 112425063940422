import { createSlice } from "@reduxjs/toolkit";
import { artistThunks } from "./artistThunks";

const initialState = {
  trendingArtists: null,
  recommendedArtists: null,
  topArtists: null,
  allArtists: null,
  favouriteArtists: null,
  artistsByLetter: null,
  artistBioWithSongs: null,
  isLoading: false,
  error: null,
};

/* Artist slice */
const artistSlice = createSlice({
  name: "artist",
  initialState,
  reducers: {
    resetTrendingArtists: (state) => {
      state.trendingArtists = null;
    },
    resetRecommendedArtists: (state) => {
      state.recommendedArtists = null;
    },
    resetTopArtists: (state) => {
      state.topArtists = null;
    },
    resetAllArtists: (state) => {
      state.allArtists = null;
    },
    resetFavouriteArtists: (state) => {
      state.favouriteArtists = null;
    },
    resetArtistsByLetter: (state) => {
      state.artistsByLetter = null;
    },
    resetArtistBioWithSongs: (state) => {
      state.artistBioWithSongs = null;
    },
  },
  extraReducers: (builder) => {
    // Generic handlers for pending, fulfilled, and rejected states
    const handlePending = (state) => {
      state.isLoading = true;
      state.error = null;
    };

    const handleRejected = (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    };

    const handleFulfilled = (state, action, key) => {
      state.isLoading = false;
      state[key] = action.payload;
    };

    builder
      // Trending artists handler
      .addCase(artistThunks.getTrendingArtists.pending, handlePending)
      .addCase(artistThunks.getTrendingArtists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.trendingArtists = action.payload.data;
      })
      .addCase(artistThunks.getTrendingArtists.rejected, handleRejected)
      // Recommended artists handler
      .addCase(artistThunks.getRecommendedArtists.pending, handlePending)
      .addCase(
        artistThunks.getRecommendedArtists.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.recommendedArtists = action.payload.data;
        }
      )
      .addCase(artistThunks.getRecommendedArtists.rejected, handleRejected)
      // Top artists handler
      .addCase(artistThunks.getTopArtists.pending, handlePending)
      .addCase(artistThunks.getTopArtists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.topArtists = action.payload.data.topArtistList;
      })
      .addCase(artistThunks.getTopArtists.rejected, handleRejected)
      // All artists handler
      .addCase(artistThunks.getAllArtists.pending, handlePending)
      .addCase(artistThunks.getAllArtists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allArtists = action.payload.data;
      })
      .addCase(artistThunks.getAllArtists.rejected, handleRejected)
      // Favourite artists handler
      .addCase(artistThunks.getFavouriteArtists.pending, handlePending)
      .addCase(artistThunks.getFavouriteArtists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.favouriteArtists = action.payload.data;
      })
      .addCase(artistThunks.getFavouriteArtists.rejected, handleRejected)
      // Artists by letter handler
      .addCase(artistThunks.getArtistsByLetter.pending, handlePending)
      .addCase(artistThunks.getArtistsByLetter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.artistsByLetter = action.payload.data;
      })
      .addCase(artistThunks.getArtistsByLetter.rejected, handleRejected)
      // Artist bio with songs handler
      .addCase(artistThunks.getArtistBioDetails.pending, handlePending)
      .addCase(artistThunks.getArtistBioDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.artistBioWithSongs = action.payload.data;
      })
      .addCase(artistThunks.getArtistBioDetails.rejected, handleRejected);
  },
});

export const {
  resetTrendingArtists,
  resetRecommendedArtists,
  resetAllArtists,
  resetTopArtists,
  resetFavouriteArtists,
  resetArtistsByLetter,
  resetArtistBioWithSongs,
} = artistSlice.actions;

export const artistController = artistThunks;

export default artistSlice.reducer;
