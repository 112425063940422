import React from "react";
import { Col, Image, Row, Button, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { StaticImages } from "../../../utils/constants";
import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { getConfig } from "../../../services/apiUtils";
import { countryCodes } from "../../../utils/countryCodes";
import { configController } from "../../../features/config/configSlice";
import RenderField from "../../../components/_fields/RenderField";
import { validationSchemas } from "../../../utils/validationSchemas";
import { formFieldTypes } from "../../../utils/formFieldTypes";
import { Form } from "antd";

function ContactUsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.config);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemas.contactUsSchema),
    mode: "onChange",
    defaultValues: formFieldTypes.contactUsFields.reduce((acc, field) => {
      if (field.name === "countryCode") {
        acc[field.name] = countryCodes[0].dial_code; // Set first country code as default
      } else {
        acc[field.name] = ""; // Initialize each field with an empty string
      }
      return acc;
    }, {}),
  });

  const onSubmit = async (payload) => {
    try {
      console.log("Submitting form...", payload);

      await dispatch(
        configController.submitContactUs({
          payload: payload,
          config: getConfig(),
          resetForm: reset,
          navigate,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error Submitting Feedback:", error);
    }
  };

  const seoData = getSEOConfig("contactPage");

  return (
    <MainLayout>
      <SEOManager {...seoData} />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div className="py-4">
          <Row className="m-0 p-0">
            <Col lg={8} md={8} sm={12} xs={12} className="mb-4">
              <div>
                <h1 className="fw-bold my-1">Contact Us</h1>
                <div className="my-3">
                  Our friendly team would love to hear from you.
                </div>

                <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
                  <Row>
                    {formFieldTypes.contactUsFields.map((field) => (
                      <RenderField
                        key={field.name}
                        field={field}
                        control={control}
                        errors={errors}
                      />
                    ))}
                  </Row>

                  <Row>
                    <Col lg={4} md={4} sm={12} xs={12}>
                      <Button
                        type="default"
                        className="mt-3 bg-corbeau text-ghostWhite fw-bold w-100"
                        htmlType="submit"
                        size="large"
                      >
                        Submit
                      </Button>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12}></Col>
                    <Col lg={4} md={4} sm={12} xs={12}></Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="text-center">
              <Image
                fluid
                rounded
                src={StaticImages.GoogleAds.DesktopAd_V}
                className="d-none d-lg-block"
              />
              <Image
                fluid
                rounded
                src={StaticImages.GoogleAds.MobileAd}
                className="d-lg-none"
              />{" "}
            </Col>
          </Row>
        </div>
      </Container>
    </MainLayout>
  );
}

export default ContactUsPage;
