import { createSlice } from "@reduxjs/toolkit";
import { albumThunks } from "./albumThunks";

const initialState = {
  albumList: null,
  album: null,
  albumDetails: null,
  topExploreAlbums: null,
  exploreAlbums: null,
  albumSongs: null,
  isLoading: false,
  error: null,
};

/* Album slice */
const albumSlice = createSlice({
  name: "album",
  initialState,
  reducers: {
    resetAlbumList: (state) => {
      state.albumList = null;
    },
    resetAlbum: (state) => {
      state.album = null;
    },
    resetAlbumDetails: (state) => {
      state.albumDetails = null;
    },
    resetTopExploreAlbums: (state) => {
      state.topExploreAlbums = null;
    },
    resetExploreAlbums: (state) => {
      state.exploreAlbums = null;
    },
    resetAlbumSongs: (state) => {
      state.albumSongs = null;
    },
  },
  extraReducers: (builder) => {
    // Generic handlers for pending, fulfilled, and rejected states
    const handlePending = (state) => {
      state.isLoading = true;
      state.error = null;
    };

    const handleRejected = (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    };

    const handleFulfilled = (state, action, key) => {
      state.isLoading = false;
      state[key] = action.payload;
    };

    // Albums list handler
    builder
      .addCase(albumThunks.getAlbumsList.pending, handlePending)
      .addCase(albumThunks.getAlbumsList.fulfilled, (state, action) =>
        handleFulfilled(state, action, "albumList")
      )
      .addCase(albumThunks.getAlbumsList.rejected, handleRejected)
      // Albums handler
      .addCase(albumThunks.getAlbums.pending, handlePending)
      .addCase(albumThunks.getAlbums.fulfilled, (state, action) =>
        handleFulfilled(state, action, "album")
      )
      .addCase(albumThunks.getAlbums.rejected, handleRejected)
      // Album details handler
      .addCase(albumThunks.getAlbumDetails.pending, handlePending)
      .addCase(albumThunks.getAlbumDetails.fulfilled, (state, action) =>
        handleFulfilled(state, action, "albumDetails")
      )
      .addCase(albumThunks.getAlbumDetails.rejected, handleRejected)
      // Top explore albums handler
      .addCase(albumThunks.getTopExploreAlbums.pending, handlePending)
      .addCase(albumThunks.getTopExploreAlbums.fulfilled, (state, action) => {
        state.isLoading = false;
        state.topExploreAlbums = action.payload.data;
      })
      .addCase(albumThunks.getTopExploreAlbums.rejected, handleRejected)
      // Explore albums handler
      .addCase(albumThunks.getExploreAlbums.pending, handlePending)
      .addCase(albumThunks.getExploreAlbums.fulfilled, (state, action) => {
        state.isLoading = false;
        state.exploreAlbums = action.payload.data;
      })
      .addCase(albumThunks.getExploreAlbums.rejected, handleRejected)
      // Album songs handler
      .addCase(albumThunks.getAlbumSongs.pending, handlePending)
      .addCase(albumThunks.getAlbumSongs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.albumSongs = action.payload.data;
      })
      .addCase(albumThunks.getAlbumSongs.rejected, handleRejected);
  },
});

export const {
  resetAlbumList,
  resetAlbum,
  resetAlbumDetails,
  resetTopExploreAlbums,
  resetExploreAlbums,
  resetAlbumSongs,
} = albumSlice.actions;

export const albumController = albumThunks;

export default albumSlice.reducer;
