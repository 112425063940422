import React from "react";
import { Image } from "react-bootstrap";

function Banner({ src, alt, className = "", ...rest }) {
  return (
    <div className="m-2">
      <Image
        fluid
        src={src}
        className={`w-100 rounded-3 ${className}`}
        alt={alt}
        {...rest} // Spread additional props like style, id, etc.
      />
    </div>
  );
}

export default Banner;
