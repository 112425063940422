import { message, notification } from "antd";

/**
 * Show a global toast message
 * @param {('info' | 'success' | 'error' | 'warning' | 'loading')} type - Type of the message.
 * @param {string} content - Content of the message.
 * @param {number} [duration=3] - Duration for the message to stay, in seconds. Set to 0 for persistent.
 */
export const showToast = (type, content, duration = 3) => {
  message[type](content, duration);
};

/**
 * Show a global notification
 * @param {('info' | 'success' | 'error' | 'warning')} type - Type of the notification.
 * @param {string} title - Title of the notification.
 * @param {string} description - Description of the notification.
 * @param {number} [duration=4.5] - Duration for the notification to stay, in seconds. Set to 0 for persistent.
 * @param {ReactNode} [icon] - Optional custom icon.
 */
export const showNotification = ({
  type,
  title,
  description,
  duration = 4.5,
  icon,
}) => {
  notification[type]({
    message: title,
    description,
    duration,
    icon,
  });
};
