import { createSlice } from "@reduxjs/toolkit";
import { newsThunks } from "./newsThunks";

const initialState = {
  newsList: null,
  specificNews: null,
  isLoading: false,
  error: null,
};

/* News Section */
const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    resetNewsList: (state) => {
      state.newsList = null;
    },
    resetSpecificNews: (state) => {
      state.specificNews = null;
    },
  },
  extraReducers: (builder) => {
    // Generic handlers for pending, fulfilled, and rejected states
    const handlePending = (state) => {
      state.isLoading = true;
      state.error = null;
    };

    const handleRejected = (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    };

    const handleFulfilled = (state, action, key) => {
      state.isLoading = false;
      state[key] = action.payload.data;
    };

    // Add cases for news thunks
    builder
      .addCase(newsThunks.getNewsList.pending, handlePending)
      .addCase(newsThunks.getNewsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.newsList = action.payload.data;
      })
      .addCase(newsThunks.getNewsList.rejected, handleRejected)
      .addCase(newsThunks.getNewsDetails.pending, handlePending)
      .addCase(newsThunks.getNewsDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.specificNews = action.payload.data;
      })
      .addCase(newsThunks.getNewsDetails.rejected, handleRejected);
  },
});

/* Export actions and thunks */
export const { resetNewsList, resetSpecificNews } = newsSlice.actions;

export const newsController = newsThunks;

export default newsSlice.reducer;
