// stringUtils.js
import _ from "lodash";

/**
 * Removes special characters and accents from a string.
 *
 * @param {string} text - The text to sanitize.
 * @returns {string} - The sanitized string.
 */
const sanitizeName = (text) => {
  return _.replace(_.deburr(text), /[^a-zA-Z0-9\s]/g, ""); // Remove accents and special characters
};

export const stringUtils = {
  sanitizeName,
};

// dateUtils.js
/**
 * Utility function to format a date string into "dd.MM.yyyy" format.
 *
 * This function takes a date string, creates a `Date` object, and returns
 * the date formatted as "DD.MM.YYYY". The day and month are padded to ensure
 * they are always two digits.
 *
 * @param {string} dateString - The input date string to be formatted.
 * @returns {string} The formatted date in "DD.MM.YYYY" format.
 *
 * @example
 * formatDate('2024-10-15T12:00:00Z');
 * // returns "15.10.2024"
 */
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = _.padStart(date.getDate(), 2, "0"); // Ensure two-digit day
  const month = _.padStart(date.getMonth() + 1, 2, "0"); // Month is 0-indexed
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

/**
 * Utility function to format a date string into "yyyy-MM-dd" format.
 *
 * @param {string} dateString - The input date string to be formatted.
 * @returns {string} The formatted date in "YYYY-MM-DD" format.
 *
 * @example
 * formatDateISO('2024-10-15T12:00:00Z');
 * // returns "2024-10-15"
 */
const formatDateISO = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = _.padStart(date.getMonth() + 1, 2, "0"); // Month is 0-indexed
  const day = _.padStart(date.getDate(), 2, "0");
  return `${year}-${month}-${day}`;
};

/**
 * Utility function to format a date string into "dd-MMM-yyyy" format.
 *
 * This function takes a date string and returns it in the format "DD-MMM-YYYY"
 * (e.g., "12-Jan-2024").
 *
 * @param {string} dateString - The input date string to be formatted.
 * @returns {string} The formatted date in "DD-MMM-YYYY" format.
 *
 * @example
 * formatDateWithMonthAbbreviation('2024-10-15T12:00:00Z');
 * // returns "15-Oct-2024"
 */
const formatDateWithMonthAbbreviation = (dateString) => {
  const date = new Date(dateString);
  const day = _.padStart(date.getDate(), 2, "0"); // Ensure two-digit day
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()]; // Get month abbreviation
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Exporting as dateUtils object
export const dateUtils = {
  formatDate,
  formatDateISO,
  formatDateWithMonthAbbreviation,
};
