import { showToast } from "../../components/_toasts/notificationUtils";
import { createApiThunk } from "../../utils/thunkUtils";

export const getMostPopularSongs = createApiThunk(
  "song/getMostPopularSongs",
  { method: "GET", endpoint: "/user/most-popular-song-list" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  }
);

export const getRecommendedSongs = createApiThunk(
  "song/getRecommendedSongs",
  { method: "GET", endpoint: "/user/get-recommended-song-list" },
  ({ data, resetForm }) => {},
  ({ data }) => {}
);

export const getExploreSongs = createApiThunk(
  "song/getExploreSongs",
  { method: "GET", endpoint: "/user/get-explore-song-list" },
  ({ data, navigate, resetForm }) => {},
  ({ data }) => {}
);

export const getTopSongs = createApiThunk(
  "song/getTopSongs",
  { method: "GET", endpoint: "/user/get-top-songs" },
  ({ data }) => {},
  ({ data }) => {}
);

export const getNewReleaseSongs = createApiThunk(
  "song/getNewReleaseSongs",
  { method: "GET", endpoint: "/user/get-new-release-songs" },
  ({ data }) => {},
  ({ data }) => {}
);

export const songThunks = {
  getMostPopularSongs,
  getRecommendedSongs,
  getExploreSongs,
  getTopSongs,
  getNewReleaseSongs,
};
