import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Spin, Dropdown, Menu, Button } from "antd";
import { Image } from "react-bootstrap";

import styles from "./ViewLyricsSection.module.css";
import {
  lyricsController,
  resetLyricsMeaning,
} from "../../features/lyrics/lyricsSlice";
import { getConfig } from "../../services/apiUtils";
import { StaticImages } from "../../utils/constants";
import MostPopularSongs from "./MostPopularSongs";
import ViewLyricsVideoCard from "../_cards/ViewLyricsVideoCard";
import ViewLyricsCommentSection from "./ViewLyricsCommentSection";
import LyricsInfoCard from "../_cards/LyricsInfoCard";

const ViewLyricsSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isrcKey } = useParams();
  const { lyrics, lyricsMeaning, isLoading, error } = useSelector(
    (state) => state.lyrics
  );

  const [selectedTab, setSelectedTab] = useState("lyrics");
  const [textSize, setTextSize] = useState("medium");

  const fetchLyrics = useCallback(async () => {
    if (isrcKey) {
      try {
        // Fetch lyrics only
        await dispatch(
          lyricsController.getSongLyrics({
            payload: { isrcKey },
            config: getConfig(),
            navigate,
          })
        ).unwrap();
      } catch (error) {
        console.error("Error fetching lyrics:", error);
      }
    }
  }, [dispatch, isrcKey, navigate]);

  const fetchMeaning = useCallback(async () => {
    if (isrcKey && lyrics?.lyrics) {
      try {
        // Fetch meaning only
        resetLyricsMeaning();
        await dispatch(
          lyricsController.getSongLyricsMeaning({
            payload: { isrc_code: isrcKey, song_lyrics: lyrics.lyrics },
            config: getConfig(),
            navigate,
          })
        ).unwrap();
      } catch (error) {
        console.error("Error fetching meaning:", error);
      }
    }
  }, [dispatch, isrcKey, lyrics?.lyrics, navigate]);

  useEffect(() => {
    if (selectedTab === "lyrics") {
      fetchLyrics();
      resetLyricsMeaning();
    } else if (selectedTab === "meaning") {
      fetchMeaning();
    }

    const disablePrint = () =>
      (window.print = () => console.log("Print functionality is disabled."));
    const disableContextMenu = (event) => event.preventDefault();
    const disablePrintShortcut = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "p") {
        event.preventDefault();
      }
    };

    disablePrint();
    document.addEventListener("contextmenu", disableContextMenu);
    document.addEventListener("keydown", disablePrintShortcut);

    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
      document.removeEventListener("keydown", disablePrintShortcut);
    };
  }, [fetchLyrics, fetchMeaning, selectedTab]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleTextSizeChange = ({ key }) => setTextSize(key);

  const renderContent = () => {
    const formatLyrics = (text) => {
      if (!text) return "";
      return text.replace(/\n/g, "<br />");
    };

    if (isLoading)
      return (
        <div className="d-flex justify-content-center">
          <Spin tip="Loading..." size="large" />
        </div>
      );
    if (error) return <div className={styles.error}>Error: {error}</div>;
    if (!lyrics && selectedTab === "lyrics")
      return <div className={styles.noLyrics}>No Lyrics Found</div>;
    if (!lyricsMeaning && selectedTab === "meaning")
      return <div className={styles.noLyrics}>No Meaning Found</div>;

    return (
      <div className="m-4">
        <div
          className={`${styles.content} ${styles[textSize]}`}
          style={{ whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{
            __html:
              selectedTab === "lyrics"
                ? formatLyrics(lyrics?.lyrics) || "<p>No lyrics available.</p>"
                : lyricsMeaning || "<p>No meaning available.</p>",
          }}
        />
        <div className="bg-sliceHeaven text-white p-4 rounded-4 fw-semibold d-flex flex-column gap-2 my-4">
          <div>{lyrics?.copyright}</div>
          <div>Written by: {lyrics?.writer}</div>
          <div>Lyrics Licensed & Provided by LyricFind</div>
        </div>
        <div>
          <ViewLyricsCommentSection />
        </div>
      </div>
    );
  };

  const menu = (
    <Menu onClick={handleTextSizeChange}>
      <Menu.Item key="small">Small</Menu.Item>
      <Menu.Item key="medium">Medium</Menu.Item>
      <Menu.Item key="large">Large</Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div>
        <LyricsInfoCard lyrics={lyrics} loading={isLoading} />
      </div>
      <Row gutter={24}>
        <Col lg={16} md={16} sm={24} xs={24} className="bg-white rounded-4">
          <div className="d-flex justify-content-between align-items-center m-4">
            <div>
              <Button.Group>
                <Button
                  variant={selectedTab === "lyrics" ? "solid" : "outlined"}
                  color={selectedTab === "lyrics" ? "default" : "default"}
                  onClick={() => handleTabChange("lyrics")}
                  shape="round"
                  className="border-2 border-black fw-bold"
                >
                  Lyrics
                </Button>
                <Button
                  variant={selectedTab === "meaning" ? "solid" : "outlined"}
                  color={selectedTab === "meaning" ? "default" : "default"}
                  onClick={() => handleTabChange("meaning")}
                  shape="round"
                  className="border-2 border-black fw-bold"
                >
                  Meaning
                </Button>
              </Button.Group>
            </div>
            <div>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button variant="solid" color="default" shape="round">
                  Text Size:{" "}
                  {textSize.charAt(0).toUpperCase() + textSize.slice(1)}{" "}
                </Button>
              </Dropdown>
            </div>
          </div>
          {renderContent()}
        </Col>
        <Col lg={8} md={8} sm={24} xs={24} className="d-flex flex-column gap-3">
          <div>
            <ViewLyricsVideoCard />
          </div>
          <div>
            <MostPopularSongs heading={5} viewMode="list" display={6} />
          </div>

          <Image
            fluid
            src={StaticImages.GoogleAds.DesktopAd_V}
            className="w-100 rounded-3 d-none d-lg-block"
            alt="Home Banner"
          />
          <Image
            fluid
            src={StaticImages.GoogleAds.MobileAd}
            className="w-100 rounded-3 d-lg-none"
            alt="Home Banner"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ViewLyricsSection;
