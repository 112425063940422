import { createSlice } from "@reduxjs/toolkit";
import { authThunks } from "./authThunks";

const initialState = {
  user: null,
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Generic handler functions
    const handlePending = (state) => {
      state.isLoading = true;
      state.error = null;
    };

    const handleRejected = (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    };

    const handleFulfilled = (state) => {
      state.isLoading = false;
    };
    // loginUser
    builder
      .addCase(authThunks.loginUser.pending, handlePending)
      .addCase(authThunks.loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(authThunks.loginUser.rejected, handleRejected);
    // getUserProfile
    builder
      .addCase(authThunks.getUserProfile.pending, handlePending)
      .addCase(authThunks.getUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(authThunks.getUserProfile.rejected, handleRejected);
    // editUserProfile
    builder
      .addCase(authThunks.editUserProfile.pending, handlePending)
      .addCase(authThunks.editUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(authThunks.editUserProfile.rejected, handleRejected);
    // createUser
    builder
      .addCase(authThunks.createUser.pending, handlePending)
      .addCase(authThunks.createUser.fulfilled, handleFulfilled) 
      .addCase(authThunks.createUser.rejected, handleRejected);
    // verifyUser
    builder
      .addCase(authThunks.verifyUser.pending, handlePending)
      .addCase(authThunks.verifyUser.fulfilled, handleFulfilled) 
      .addCase(authThunks.verifyUser.rejected, handleRejected);
    // forgetPassword
    builder
      .addCase(authThunks.forgetPassword.pending, handlePending)
      .addCase(authThunks.forgetPassword.fulfilled, handleFulfilled) 
      .addCase(authThunks.forgetPassword.rejected, handleRejected);
    // resetPassword
    builder
      .addCase(authThunks.resetPassword.pending, handlePending)
      .addCase(authThunks.resetPassword.fulfilled, handleFulfilled) 
      .addCase(authThunks.resetPassword.rejected, handleRejected);
    // changeUserPassword
    builder
      .addCase(authThunks.changeUserPassword.pending, handlePending)
      .addCase(authThunks.changeUserPassword.fulfilled, handleFulfilled) 
      .addCase(authThunks.changeUserPassword.rejected, handleRejected);
    // uploadProfilePicture
    builder
      .addCase(authThunks.uploadProfilePicture.pending, handlePending)
      .addCase(authThunks.uploadProfilePicture.fulfilled, handleFulfilled) 
      .addCase(authThunks.uploadProfilePicture.rejected, handleRejected);
  },
});

export const authController = authThunks;

export default authSlice.reducer;
