import React, { useCallback, useState } from "react";
import { Modal, Input, List, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getConfig } from "../../services/apiUtils";
import debounce from "lodash/debounce";
import { searchController } from "../../features/search/searchSlice";
import { getSearchSong } from "../../services/apiServices";
import { useParams } from "react-router-dom";


const AddSongUserPlaylistModal = ({ visible, onClose, onAdd }) => {
  const {playlistId} = useParams();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { searchResult, isLoading, error } = useSelector(
  //   (state) => state.search
  // );

 
  const [searchValue, setSearchValue] = useState("");
  const [searchResult,setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  
  // Debounced search function
  // const fetchSearchResults = useCallback(
  //   debounce(async (query) => {
  //     if (query.trim()) {
  //       try {
  //         await dispatch(
  //           searchController.getSearchSong({
  //             payload: { song_name: query },
  //             config: getConfig(),
  //           })
  //         ).unwrap();
  //       } catch (error) {
  //         console.error("Failed to fetch search results:", error);
  //       }
  //     }
  //   }, 300),
  //   [dispatch]
  // );


  const [isLoading, setIsLoading] = useState(false); 

const fetchSearchResults = async (query) => {
  setIsLoading(true); 
  try {
    const response = await getSearchSong({ song_name: query });
    if (response.success) {
      setSearchResults(response.data);
    }
  } catch (error) {
    console.error("Failed to fetch search results:", error);
  } finally {
    setIsLoading(false); 
  }
};


  const debouncedFetchSearchResults = useCallback(
    debounce((query) => {
      fetchSearchResults(query);
    }, 800),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.trim()) {
      debouncedFetchSearchResults(value); // Call the debounced function
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  // Trigger search on pressing Enter
  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter" && searchValue.trim()) {
  //     navigate(`/search?query=${searchValue.trim()}`);
  //     setShowDropdown(false);
  //   }
  // };


  // const [searchQuery, setSearchQuery] = useState("");
  // const [searchResults, setSearchResults] = useState([]);

  // const handleSearch = async (query) => {
  //   setSearchQuery(query);
  //   if (query.length > 2) {
  //     setLoading(true);
  //     // Replace with actual API call
  //     const payload = { song_name: query };

  //     // setSearchResults(mockResults);
  //     setLoading(false);
  //   } else {
  //     setSearchResults([]);
  //   }
  // };

  return (
    <Modal
      title="Add Songs to Playlist"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Input
        placeholder="Search songs..."
        value={searchValue}
        onChange={handleInputChange}
        // onKeyPress={handleKeyPress}
        allowClear
      />
      <List
        loading={isLoading}
        dataSource={searchResult || []}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button type="link" onClick={() => onAdd(item.song_id,playlistId)}>
                Add to Playlist
              </Button>,
            ]}
          >
            {item.song_name} - {item.artist.artist_name}
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default AddSongUserPlaylistModal;
