// src/components/UIAlerts.js
import React from "react";
import { Alert } from "antd";

// Generic Alert Component
export const GenericAlert = ({
  message,
  description,
  type = "info",
  showIcon = true,
  closable = false,
}) => (
  <Alert
    message={message}
    description={description}
    type={type}
    showIcon={showIcon}
    closable={closable}
  />
);

// Success Alert
export const SuccessAlert = ({
  message = "Success",
  description,
  showIcon = true,
  closable = false,
}) => (
  <Alert
    message={message}
    description={description}
    type="success"
    showIcon={showIcon}
    closable={closable}
  />
);

// Error Alert
export const ErrorAlert = ({
  message = "Error",
  description,
  showIcon = true,
  closable = false,
}) => (
  <Alert
    message={message}
    description={description}
    type="error"
    showIcon={showIcon}
    closable={closable}
  />
);

// Warning Alert
export const WarningAlert = ({
  message = "Warning",
  description,
  showIcon = true,
  closable = false,
}) => (
  <Alert
    message={message}
    description={description}
    type="warning"
    showIcon={showIcon}
    closable={closable}
  />
);

// Info Alert
export const InfoAlert = ({
  message = "Information",
  description,
  showIcon = true,
  closable = false,
}) => (
  <Alert
    message={message}
    description={description}
    type="info"
    showIcon={showIcon}
    closable={closable}
  />
);
