// src/components/UISkeletons.js
import React from "react";
import { Skeleton, Avatar, List, Space } from "antd";

// Skeleton with Avatar and Title
export const AvatarTitleSkeleton = ({ count = 5, avatarSize = 40 }) => {
  const skeletonArray = Array.from({ length: count });
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {skeletonArray.map((_, index) => (
        <Skeleton
          key={index}
          active
          avatar={{ shape: "circle", size: avatarSize }}
          title
          paragraph={false} // No additional lines
        >
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src="https://via.placeholder.com/40" />}
              title={<span>Loading Title...</span>} // Placeholder
            />
          </List.Item>
        </Skeleton>
      ))}
    </Space>
  );
};

// Skeleton for Card Grid
export const CardGridSkeleton = ({ count = 4 }) => {
  const skeletonArray = Array.from({ length: count });
  return (
    <div className="row g-4">
      {skeletonArray.map((_, index) => (
        <div key={index} className="col-md-3">
          <Skeleton.Image active style={{ width: "100%", height: "200px" }} />
          <Skeleton active title paragraph={{ rows: 1 }} />
        </div>
      ))}
    </div>
  );
};

// Skeleton for List Items
export const MostPopularSongSkeleton = ({ count = 5 }) => {
  const skeletonArray = Array.from({ length: count });
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Skeleton active avatar={false} title paragraph={false} />
      {skeletonArray.map((_, index) => (
        <Skeleton
          key={index}
          active
          avatar={{ shape: "circle", size: 40 }}
          title
          paragraph={false}
          className="my-2 p-2"
        />
      ))}
    </Space>
  );
};
