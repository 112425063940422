// src/layouts/MainLayout.js
import React from "react";
import { Col, Image, Row } from "react-bootstrap";

import AppFooter from "../components/AppFooter/AppFooter";
import GoogleAd from "../components/Advertisements/GoogleAd";
import { GoogleAdSense } from "../utils/constants.js";
import styles from "./MainLayout.module.css";
import AppNavigation from "../components/AppNavigation/AppNavigation.jsx";

const MainLayout = ({ children }) => {
  return (
    <>
      <main>
        <Row className="m-0 p-0">
          <Col
            className={`m-0 p-0 d-flex justify-content-center align-items-baseline ${styles.advertisementColumn} ${styles["col-12-5"]}`}
          >
            {/* <GoogleAd
              client={GoogleAdSense.client}
              slot={GoogleAdSense.slot.layout_ad_1}
              style={{ width: "100%", minHeight: "250px" }}
            /> */}
            {/* <Image
              fluid
              src={"/assets/static/GoogleAD1_160x600.png"}
              className="w-100 rounded-3"
              alt="Home Banner"
            /> */}
          </Col>
          <Col className={`m-0 p-0 ${styles["col-75"]}`}>
            <div className="m-2">
              <AppNavigation />
              {children}
            </div>
          </Col>
          <Col
            className={`m-0 p-0 d-flex justify-content-center align-items-baseline ${styles.advertisementColumn} ${styles["col-12-5"]}`}
          >
            {/* <Image
              fluid
              src={"/assets/static/GoogleAD1_160x600.png"}
              className="w-100 rounded-3"
              alt="Home Banner"
            /> */}
            {/* <GoogleAd
              client={GoogleAdSense.client}
              slot={GoogleAdSense.slot.layout_ad_2}
              style={{ width: "100%", minHeight: "250px" }}
            /> */}
          </Col>
        </Row>
      </main>
      <AppFooter />
    </>
  );
};

export default MainLayout;
