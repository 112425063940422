import { kebabCase, startCase, toLower } from "lodash";

import _ from "lodash";

/**
 * Helper function to format strings based on a transformation type.
 * - Converts kebab-case to Start Case (default).
 * - Can be extended for other transformations (e.g., camelCase, snake_case, etc.).
 *
 * @param {string} input - The string to be formatted.
 * @param {string} type - The type of transformation ('startCase' is default).
 * @returns {string} - The transformed string.
 */
export const formatStringType = (input, type = "startCase") => {
  if (!input) return "";

  // Transform input based on the provided type
  switch (type) {
    case "startCase":
      return _.startCase(_.toLower(input)); // Converts 'apna-bana-le' to 'Apna Bana Le'
    case "kebabCase":
      return _.kebabCase(input); // Converts 'Apna Bana Le' to 'apna-bana-le'
    case "camelCase":
      return _.camelCase(input); // Converts 'Apna Bana Le' to 'apnaBanaLe'
    case "snakeCase":
      return _.snakeCase(input); // Converts 'Apna Bana Le' to 'apna_bana_le'
    case "lowerCase":
      return _.toLower(input); // Converts 'Apna Bana Le' to 'apna bana le'
    case "upperCase":
      return _.toUpper(input); // Converts 'Apna Bana Le' to 'APNA BANA LE'
    default:
      return input; // Return the original if no matching case
  }
};

const helpers = { formatStringType };

export default helpers;
