import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./AlphabetNav.module.css"; // Assuming you're using CSS modules
import { letterNavLinks } from "../../utils/constants";

const AlphabetNav = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <div className={styles.alphabetNavContainer}>
      <div className={`d-flex ${styles.scrollContainer}`}>
        {letterNavLinks.map((item, index) => (
          <Button
            shape="circle"
            key={index}
            onClick={() => handleClick(item.path)}
            className={`mx-2 ${styles.navButton}`}
          >
            {item.label}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default AlphabetNav;
