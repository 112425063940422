import React, { useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";

import MainLayout from "../../../layouts/MainLayout";
import { StaticImages } from "../../../utils/constants";
import TopBar from "../../../components/_buttons/TopBar";
import { ErrorAlert } from "../../../components/_alerts/UIAlerts";
import { getConfig } from "../../../services/apiUtils";
import { artistController } from "../../../features/artist/artistSlice";
import { generateDynamicRoute } from "../../../utils/generateDynamicRoute";
import { ROUTES } from "../../../routes/constants";
import _ from "lodash";

function FilterArtistsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { artistLetter } = useParams();
  const {
    artistsByLetter = [],
    isLoading,
    error,
  } = useSelector((state) => state.artist);

  useEffect(() => {
    dispatch(
      artistController.getArtistsByLetter({
        payload: { query: artistLetter, page: 1 },
        config: getConfig(),
      })
    ).catch((error) => console.error("Failed to fetch artists:", error));
  }, [dispatch, artistLetter]);

  console.log(artistsByLetter);

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <div className="m-2">
          <Image fluid src={StaticImages.GoogleAds.DesktopAd_H_large} />
        </div>
        <div className="m-2">
          <Row className="g-4 m-0 p-0">
            <Col lg={8} md={8} sm={12} xs={12}>
              {isLoading ? (
                <div className="text-center my-4">
                  <Spinner animation="border" role="status" variant="primary">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : error ? (
                <>
                  <ErrorAlert description={error} />
                </>
              ) : artistsByLetter?.artistList &&
                artistsByLetter?.artistList.length > 0 ? (
                <Row className="g-4 m-0 p-0">
                  <>
                    <Table borderless responsive className="p-2">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name</th>
                          <th>Songs</th>
                          {/* <th>Times Played</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {artistsByLetter?.artistList &&
                          artistsByLetter?.artistList?.length > 0 &&
                          artistsByLetter?.artistList?.map((artist, index) => (
                            <tr key={index} className="align-middle">
                              <td>{index + 1}</td>
                              <td className="d-flex align-items-center">
                                {/* Render the avatar alongside the song title */}
                                <Image
                                  fluid
                                  src={
                                    artist?.artist_image_link ||
                                    StaticImages.Album.Img1
                                  }
                                  // alt={`${song?.song_name} avatar`}
                                  width="40px"
                                  rounded
                                  className="mx-3"
                                />
                                <span
                                  className="fw-bold"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(
                                      generateDynamicRoute(
                                        ROUTES.MAIN.VIEW_ARTIST_BIO,
                                        {
                                          artistName: _.kebabCase(
                                            artist?.artist_name
                                          ),
                                          artistId: artist?.artist_id,
                                        }
                                      )
                                    )
                                  }
                                >
                                  {artist?.artist_name}
                                </span>
                              </td>
                              <td>{artist?.total_songs}</td>
                              {/* <td>{artist?.times_played}</td> */}
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </>
                  <div className="ms-auto d-flex align-items-center float-end">
                    <span className="fw-bold">See All</span>
                    <Button
                      variant="eigengrau"
                      size="sm"
                      className="fw-bold fs-6 mx-2 rounded-circle"
                    >
                      <MdArrowOutward />
                    </Button>
                  </div>
                </Row>
              ) : (
                <p className="text-center">
                  No artists available at the moment.
                </p>
              )}
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Image fluid src={StaticImages.GoogleAds.DesktopAd_V} />
            </Col>
          </Row>
        </div>
      </Container>
    </MainLayout>
  );
}

export default FilterArtistsPage;
