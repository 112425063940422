import React from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";
import { FaPlay } from "react-icons/fa6";
import styles from "./PodcastCard.module.css";

function PodcastCard({ podcast, index }) {
  const navigate = useNavigate();

  return (
    <>
      <Card key={podcast.id} className={`border-0 mx-2 ${styles.podcastCard}`}>
        <Card.Img
          variant="top"
          src={podcast.image}
          style={{
            objectFit: "cover",
            height: "200px",
          }}
          className="rounded-3"
        />
        <Card.Body className="d-flex flex-column p-2">
          <Card.Title as="h6" className={`${styles.truncateTitle} fw-bold`}>
            {podcast.name}
          </Card.Title>
          <div className="mt-auto">
            <Button
              as={motion.button}
              variant="eigengrau"
              size="sm"
              className="fw-bold mt-2 d-flex align-items-center"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 20,
              }}
            >
              <FaPlay className="mx-1" />
              <span className="fs-6">Watch Podcast</span>
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default PodcastCard;
