import React from "react";
import ReactDOM from "react-dom/client";
import { AnimatePresence } from "framer-motion";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "@fontsource/poppins"; // Defaults to 400 weight

import "./index.css";
import "./styles/customTheme.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { store, persistor } from "./store";
import AppErrorBoundary from "./pages/error/AppErrorBoundary";
import ErrorBoundary from "./pages/error/ReactErrorBoundary";
import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <AnimatePresence>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppErrorBoundary>
            {/* <ErrorBoundary> */}
            <App />
            {/* </ErrorBoundary> */}
          </AppErrorBoundary>
        </PersistGate>
      </Provider>
    </AnimatePresence>
  </Router>
);

reportWebVitals();
