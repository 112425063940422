import * as yup from "yup";
import { passwordRegex } from "../utils/constants";

/**
 * @typedef {Object} ValidationSchemas
 * @property {Object} loginSchema - Validation schema for the login form.
 * @property {Object} signupSchema - Validation schema for the signup form.
 * @property {Object} forgotPasswordSchema - Validation schema for the forgot password form.
 * @property {Object} resetPasswordSchema - Validation schema for the reset password form.
 * @property {Object} mobileValidation - Validation schema for mobile number.
 */

/**
 * validationSchemas - Contains validation schemas for various forms.
 *
 * This object holds several schemas: `loginSchema`, `signupSchema`,
 * `forgotPasswordSchema`, `resetPasswordSchema`, and `mobileValidation`.
 * Each schema is defined using Yup and includes rules for validating the
 * respective fields.
 *
 * @type {ValidationSchemas}
 */
export const validationSchemas = {
  // Login Schema
  loginSchema: yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    password: yup
      .string()
      .matches(
        passwordRegex,
        "Password must contain at least 6 characters, one uppercase, one lowercase, one number, and one special character"
      )
      .required("Password is required"),
  }),

  // Signup Schema
  signupSchema: yup.object().shape({
    // firstName: yup.string().required("First Name is required"),
    // lastName: yup.string().required("Last Name is required"),
    username: yup.string().required("Username is required"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    password: yup
      .string()
      .matches(
        passwordRegex,
        "Password must contain at least 6 characters, one uppercase, one lowercase, one number, and one special character"
      )
      .required("Password is required"),
    // confirmPassword: yup
    //   .string()
    //   .oneOf([yup.ref("password"), null], "Passwords must match")
    //   .required("Confirm password is required"),
    // mobile: yup
    //   .string()
    //   .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
    //   .optional(),
  }),

  // Forgot Password Schema
  forgotPasswordSchema: yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required"),
  }),

  // Reset Password Schema
  resetPasswordSchema: yup.object().shape({
    password: yup
      .string()
      .matches(
        passwordRegex,
        "Password must contain at least 6 characters, one uppercase, one lowercase, one number, and one special character"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  }),

  // Mobile Validation Schema
  mobileValidation: yup.object().shape({
    mobile: yup
      .string()
      .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
      .optional(),
  }),

  // Edit Profile Validation Schema
  editProfileSchema: yup.object().shape({
    username: yup.string().required("Username is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    gender: yup.string().nullable(), // Gender can be null or a string
    dateOfBirth: yup.date().nullable().typeError("Invalid date"),
  }),

  changePasswordSchema: yup.object().shape({
    oldPassword: yup
      .string()
      .required("Old password is required")
      .min(6, "Password must be at least 6 characters"),
    newPassword: yup
      .string()
      .required("New password is required")
      .min(6, "Password must be at least 6 characters")
      .notOneOf(
        [yup.ref("oldPassword")],
        "New password cannot be the same as old password"
      ),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("newPassword")], "Passwords must match"),
  }),

  contactUsSchema: yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    countryCode: yup.string().required("Country code is required"), // countryCode as a string
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      // .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .optional(), // phoneNumber as a string
    message: yup.string().required("Message is required"),
  }),

  addUserPlaylistSchema: yup.object().shape({
    playlist_name: yup.string().required("Playlist Name is required"),
    playlist_type: yup.string().required("Playlist Type is required"),
  }),


};
