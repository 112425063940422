import React, { useEffect, useState } from "react";
import { DislikeOutlined, LikeOutlined, SendOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, List, Space, Typography, Spin } from "antd";
import { getAuthToken } from "../../../utils/authHelpers";
import { ROUTES } from "../../../routes/constants";
import { useNavigate, useParams } from "react-router-dom";
import { getAuthConfig } from "../../../services/apiUtils";
import { toast } from "react-toastify";
import { addCommentToQuiz, getCommentToQuiz } from "../../../services/apiServices";
import { showToast } from "../../../components/_toasts/notificationUtils";

function QuizeCommentSection({currentQuestion}) {
  const navigate = useNavigate();
  const { isrcKey } = useParams();
  const [checkToken, setCheckToken] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [error,setError] = useState();
  const [commentList,setCommentList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCommentList = async () =>{
    try {
      const payload = {
        quiz_id: currentQuestion?._id,
      };

      const config = getAuthConfig();
      const response = await getCommentToQuiz(payload,config);
      if(response.success){
        setCommentList(response.data);
      }
      else{
        setCommentList([]);
      }
    } catch (error) {
      
    }
  }

  useEffect(() => {
    const token = getAuthToken();
    if (token !== null) {
      setCheckToken(true);
    } else {
      setCheckToken(false);
    }
  }, []);

  // submit comment 
  const handleCommentSubmit = async (e) => {
    e.preventDefault();
  
    if (!commentValue.trim()) {
      toast.error("Comment cannot be empty.");
      setError("Comment box not Empty");
      return;
    }
  
    setLoading(true); // Start loading
  
    try {
      const payload = {
        quiz_id: currentQuestion?._id,
        comment: commentValue?.trim(),
      };
  
      const config = getAuthConfig();
      const response = await addCommentToQuiz(payload, config);
      if (response.success) {
        setCommentValue("");
        showToast("success", response.message, 2);
        getCommentList(); // Refresh comment list
      } else {
        showToast("error", response.message, 2);
        getCommentList();
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      const errorMessage = error.response?.data?.message || error.message;
      showToast("error", error.message, 2);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(()=>{
    if(commentValue.length>0){
      setError("");
    }
  },[commentValue]);

useEffect(()=>{
 getCommentList();
},[])
  return (
    <>
      <div>
        <h1 className="d-flex justify-content-between align-items-center">
          <span className="fw-bold">Comments</span>
          {checkToken ? null : (
            <span>
              <Button
                variant="solid"
                color="default"
                size="large"
                onClick={() => navigate(ROUTES.AUTH.LOGIN)}
              >
                Sign In
              </Button>
            </span>
          )}
        </h1>
        {/* Input Field for Comment */}
        <div className="my-3">
          <Input
            placeholder="comment..."
            size="large"
            disabled={!checkToken}
            value={commentValue}
            onChange={(e) => setCommentValue(e.target.value)}
            suffix={
              <Button
                color="default"
                variant="text"
                disabled={!checkToken}
                // loading={loading}
                onClick={handleCommentSubmit}
              >
                {loading ? (<Spin/>) : (<SendOutlined/>)}
                  {/* <SendOutlined /> */}
              </Button>
            }
          />
          { error && error.length>0 && <div className="text-danger fw-bold text-center mt-2" >{error}</div>}
        </div>

        {/* List of Comments */}
        <div style={{ maxHeight: '260px', overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#888 #e0e0e0'}}>
  <List
    bordered={false}
    dataSource={commentList}
    renderItem={(item) => (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar src={item.user.avatar} size={50} />}
          title={
            <Space direction="vertical" size={0}>
              <Typography.Text strong>{item.user.username}</Typography.Text>
              {/* <Typography.Text
                type="secondary"
                style={{ fontSize: "12px" }}
              >
                {item.status}
              </Typography.Text> */}
            </Space>
          }
          description={
            <>
              <div style={{ marginBottom: "8px" }}>{item.comment}</div>
              <div className="d-flex justify-content-between align-items-center">
                <Space size="large">
                  <Space>
                    <LikeOutlined />
                    {item.likes}
                  </Space>
                  <Space>
                    <DislikeOutlined />
                    {item.dislikes}
                  </Space>
                </Space>
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "12px" }}
                >
                  {item.createdAt}
                </Typography.Text>
              </div>
            </>
          }
        />
      </List.Item>
    )}
  />
</div>

      </div>
    </>
  );
}

export default QuizeCommentSection;
