import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Layout, Skeleton, Space, Tabs, Typography } from "antd";

import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { StaticImages } from "../../../utils/constants";
import { artistController } from "../../../features/artist/artistSlice";
import { getConfig } from "../../../services/apiUtils";
import AlbumCard from "../../../components/_cards/AlbumCard";
import SongCard from "../../../components/_cards/SongCard";

const { Text, Title, Paragraph } = Typography;
const { Content } = Layout;

function ViewArtistBioPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { artistId } = useParams();
  const { artistBioWithSongs, isLoading, error } = useSelector(
    (state) => state.artist
  );
  const [selectedTab, setSelectedTab] = useState("about");

  const fetchArtistBio = useCallback(async () => {
    if (artistId) {
      try {
        await dispatch(
          artistController.getArtistBioDetails({
            payload: { artist_id: artistId },
            config: getConfig(),
            navigate,
          })
        ).unwrap();
      } catch (error) {
        console.error("Error fetching artist bio:", error);
      }
    }
  }, [dispatch, artistId, navigate]);

  useEffect(() => {
    fetchArtistBio();
  }, [fetchArtistBio]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const renderUserInterface = (type = "albums") => {
    console.log("renderUserInterface called with type:", type);
    console.log(artistBioWithSongs);
    return (
      <Row className="g-4">
        {type === "albums" &&
          artistBioWithSongs?.topAlbums?.map((album, index) => (
            <Col key={index} lg={6} md={6} sm={6} xs={6}>
              <AlbumCard index={index} album={album} />
            </Col>
          ))}

        {type === "songs" &&
          artistBioWithSongs?.mostPopularSongs?.map((song, index) => (
            <Col key={index} lg={6} md={6} sm={6} xs={6}>
              <SongCard index={index} song={song} viewMode="list" />
            </Col>
          ))}

        {type === "popular-songs" &&
          artistBioWithSongs?.mostPopularSongs?.map((song, index) => (
            <Col key={index} lg={6} md={6} sm={6} xs={6}>
              <SongCard index={index} song={song} viewMode="list" />
            </Col>
          ))}

        {type === "popular-albums" &&
          artistBioWithSongs?.mostPopularAlbums?.map((album, index) => (
            <Col key={index} lg={6} md={6} sm={6} xs={6}>
              <AlbumCard index={index} album={album} />
            </Col>
          ))}
      </Row>
    );
  };

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div className="m-2">
          <Image
            fluid
            src={StaticImages.GoogleAds.DesktopAd_H_large}
            className="w-100 rounded-3"
            alt="Home Banner"
          />
        </div>

        <Row className="m-0 p-0">
          <Layout style={{ minHeight: "100vh", background: "#f9f9f9" }}>
            <Content style={{ margin: "16px" }}>
              <Row>
                <Col lg={4} md={4} sm={4} xs={4}>
                  <div className="m-2">
                    <Image
                      src={artistBioWithSongs?.artist_image_link}
                      alt="Artist Avatar"
                      className="w-100 rounded-3"
                    />
                  </div>

                  <div className="m-2">
                    <Image
                      fluid
                      src={StaticImages.GoogleAds.DesktopAd_V}
                      className="w-100 rounded-3"
                      alt="Home Banner"
                    />
                  </div>
                </Col>

                <Col lg={8} md={8} sm={8} xs={8}>
                  <Skeleton loading={isLoading} active>
                    <Title level={1}>{artistBioWithSongs?.artist_name}</Title>

                    <Tabs
                      activeKey={selectedTab}
                      onChange={handleTabChange}
                      className="my-2"
                    >
                      <Tabs.TabPane
                        tab={
                          <Button
                            variant={
                              selectedTab === "about" ? "solid" : "outline"
                            }
                            color={
                              selectedTab === "about" ? "default" : "white"
                            }
                            className="fw-bold"
                          >
                            About
                          </Button>
                        }
                        key="about"
                      >
                        <Title level={2}>Artist Bio</Title>
                        <Paragraph>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: artistBioWithSongs?.artist_description,
                            }}
                          />
                        </Paragraph>
                        <Space className="m-2">
                          {renderUserInterface("popular-albums")}
                          {renderUserInterface("popular-songs")}
                        </Space>
                      </Tabs.TabPane>

                      <Tabs.TabPane
                        tab={
                          <Button
                            variant={
                              selectedTab === "albums" ? "solid" : "outline"
                            }
                            color={
                              selectedTab === "albums" ? "default" : "white"
                            }
                            className="fw-bold"
                          >
                            Albums
                          </Button>
                        }
                        key="albums"
                      >
                        <Title level={2}>Albums</Title>
                        {renderUserInterface("albums")}
                      </Tabs.TabPane>

                      <Tabs.TabPane
                        tab={
                          <Button
                            variant={
                              selectedTab === "songs" ? "solid" : "outline"
                            }
                            color={
                              selectedTab === "songs" ? "default" : "white"
                            }
                            className="fw-bold"
                          >
                            Songs
                          </Button>
                        }
                        key="songs"
                      >
                        <Title level={2}>Songs</Title>
                        {renderUserInterface("songs")}
                      </Tabs.TabPane>
                    </Tabs>
                  </Skeleton>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Row>
      </Container>
    </MainLayout>
  );
}

export default ViewArtistBioPage;
