import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Typography } from "antd";

import {
  resetSearchResult,
  searchController,
} from "../../../features/search/searchSlice";
import { getConfig } from "../../../services/apiUtils";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import SongCard from "../../../components/_cards/SongCard";
import AlbumCard from "../../../components/_cards/AlbumCard";
import { StaticImages } from "../../../utils/constants";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const { Title } = Typography;

function SearchResultPage() {
  const dispatch = useDispatch();
  const query = useQuery();
  const searchQuery = query.get("query");

  console.log(searchQuery);

  useEffect(() => {
    if (searchQuery) {
      let payload = { query: searchQuery };
      dispatch(
        searchController.getSearchResults({
          payload,
          config: getConfig(),
        })
      );
    }
    return () => {
      dispatch(resetSearchResult());
    };
  }, [searchQuery, dispatch]);

  const { searchResults, isLoading, error } = useSelector(
    (state) => state.search
  );

  console.log(searchResults);

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <div className="m-2">
          <Title level={1}>Top Songs</Title>

          <Row className="m-0 p-0 g-4">
            {searchResults?.artist_top_songs?.map((song, index) => (
              <Col lg={3} md={4} sm={6} xs={6} key={index}>
                <SongCard viewMode="grid" song={song} index={index} />
              </Col>
            ))}
          </Row>
        </div>

        <div className="m-2">
          <Row className="m-0 p-0 g-4">
            <Title level={1}>Albums</Title>

            {searchResults?.artist_albums?.map((album, index) => (
              <Col lg={3} md={4} sm={6} xs={6} key={index}>
                <AlbumCard album={album} index={index} />
              </Col>
            ))}
          </Row>
        </div>

        <div className="m-2">
          <Image src={StaticImages.GoogleAds.DesktopAd_H_small} fluid />
        </div>

        <div className="m-2">
          <Title level={1}>All Songs</Title>

          {searchResults?.artist_songs?.map((song, index) => (
            <Col lg={3} md={4} sm={6} xs={12} key={index}>
              <SongCard viewMode="list" song={song} index={index} />
            </Col>
          ))}
        </div>
      </Container>
    </MainLayout>
  );
}

export default SearchResultPage;
