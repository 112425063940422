import React from "react";
import { Button } from "antd";

function ViewLyricsVideoCard() {
  return (
    <div className="bg-white rounded-4 p-4 d-flex flex-column gap-2">
      <h6 className="fw-bold">Watch Video</h6>
      <iframe
        width="100%" // This will make the iframe responsive
        height="200" // Standard height for YouTube embed
        src="https://www.youtube.com/embed/dQw4w9WgXcQ" // Replace with your YouTube video URL
        title="YouTube video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="rounded-4"
      ></iframe>
      <div>
        <Button variant="solid" color="default" style={{ marginTop: "16px" }}>
          Watch Video
        </Button>
      </div>
    </div>
  );
}

export default ViewLyricsVideoCard;
