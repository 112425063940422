// src/routes.js
import React from "react";
import { Routes } from "react-router-dom";

import authRoutes from "./authRoutes";
import mainRoutes from "./mainRoutes";

const AppRoutes = () => (
  <Routes>
    {mainRoutes}
    {authRoutes}
  </Routes>
);

export default AppRoutes;
