import { createSlice } from "@reduxjs/toolkit";
import { playlistThunks } from "./playlistThunks";

const initialState = {
  adminPlaylists: null,
  playlistSongs: null,
  userMyPlaylists: null,
  userMyPlaylistsSongs: null,
  isLoading: false,
  error: null,
};

/* Playlist Section */

const playlistSlice = createSlice({
  name: "playlist",
  initialState,
  reducers: {
    resetAdminPlaylists: (state) => {
      state.adminPlaylists = null;
    },
    resetPlaylistSongs: (state) => {
      state.playlistSongs = null;
    },
    resetUserMyPlaylists: (state) => {
      state.userMyPlaylists = null;
    },
  },
  extraReducers: (builder) => {
    // Generic handlers for pending, fulfilled, and rejected states
    const handlePending = (state) => {
      state.isLoading = true;
      state.error = null;
    };

    const handleRejected = (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    };

    const handleFulfilled = (state, action, key) => {
      state.isLoading = false;
      state[key] = action.payload;
    };

    builder
      // Add cases for getAllAdminPlaylists
      .addCase(playlistThunks.getAllAdminPlaylists.pending, handlePending)
      .addCase(
        playlistThunks.getAllAdminPlaylists.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.adminPlaylists = action.payload.data;
        }
      )
      .addCase(playlistThunks.getAllAdminPlaylists.rejected, handleRejected)
      // Add cases for getPlaylistSongs
      .addCase(playlistThunks.getPlaylistSongs.pending, handlePending)
      .addCase(playlistThunks.getPlaylistSongs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.playlistSongs = action.payload.data;
      })
      .addCase(playlistThunks.getPlaylistSongs.rejected, handleRejected)
      // Add cases for getUserMyPlaylists
      .addCase(playlistThunks.getUserMyPlaylists.pending, handlePending)
      .addCase(playlistThunks.getUserMyPlaylists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userMyPlaylists = action.payload.data;
      })
      .addCase(playlistThunks.getUserMyPlaylists.rejected, handleRejected)
      // Add cases for addUserPlaylists
      .addCase(playlistThunks.addUserPlaylist.pending, handlePending)
      .addCase(playlistThunks.addUserPlaylist.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.userMyPlaylists = action.payload.data;
      })
      .addCase(playlistThunks.addUserPlaylist.rejected, handleRejected);
  },
});

/* Export actions and thunks */
export const { resetAdminPlaylists, resetPlaylistSongs, resetUserMyPlaylists } =
  playlistSlice.actions;

export const playlistController = playlistThunks;

export default playlistSlice.reducer;
