import React, { useEffect } from "react";
import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MdArrowOutward } from "react-icons/md";

import { albumController } from "../../features/album/albumSlice";
import AlbumCard from "../_cards/AlbumCard";
import { ErrorAlert } from "../_alerts/UIAlerts";

function TopAlbums({ display = 4, genreType = "all" }) {
  const dispatch = useDispatch();
  const { topExploreAlbums, isLoading, error } = useSelector(
    (state) => state.album
  );

  useEffect(() => {
    dispatch(
      albumController.getTopExploreAlbums({
        payload: genreType !== "all" ? { genre: genreType } : {},
        config: {},
      })
    );
  }, [dispatch, genreType]);

  const albums = topExploreAlbums?.albums
    ? [...topExploreAlbums.albums]
        .sort(() => 0.5 - Math.random())
        .slice(0, display)
    : [];

  return (
    <div className="my-4">
      <div className="my-4">
        <h1 className="fw-bold">Top Albums</h1>
      </div>

      <Row className="g-4 m-0 p-0">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : error ? (
          <>
            <ErrorAlert description={error} />
          </>
        ) : albums.length > 0 ? (
          <Row className="g-4 bg-ghostWhite m-0 p-0">
            {albums.map((album, index) => (
              <>
                <Col lg={3} md={4} sm={6} xs={6} key={index}>
                  <AlbumCard album={album} index={index} />
                </Col>
              </>
            ))}

            {/* <div className="ms-auto d-flex align-items-center float-end">
              <span className="fw-bold">See All</span>
              <Button
                variant="eigengrau"
                size="sm"
                className="fw-bold fs-6 mx-2 rounded-circle"
              >
                <MdArrowOutward />
              </Button>
            </div> */}
          </Row>
        ) : (
          <p className="text-center">
            No top explore albums available at the moment.
          </p>
        )}
      </Row>
    </div>
  );
}

export default TopAlbums;
