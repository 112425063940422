import React, { useEffect, useMemo } from "react";
import { Button, Col, Row, Container, Spinner } from "react-bootstrap";
import { MdArrowOutward } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { songController } from "../../features/song/songSlice";
import { getConfig } from "../../services/apiUtils";
import { ErrorAlert } from "../_alerts/UIAlerts";
import SongCard from "../_cards/SongCard";

function NewReleases({ display = 4, genreType = "all" }) {
  const dispatch = useDispatch();

  const { newReleaseSongs, isLoading, error } = useSelector(
    (state) => state.song || {}
  );

  useEffect(() => {
    dispatch(
      songController.getNewReleaseSongs({
        payload: {},
        config: getConfig(),
      })
    ).catch((error) => console.error("Failed to fetch top songs:", error));
  }, [dispatch, genreType]);

  const randomSongs = useMemo(() => {
    return newReleaseSongs?.length > 0
      ? [...newReleaseSongs].sort(() => Math.random() - 0.5).slice(0, display)
      : [];
  }, [newReleaseSongs, display]);

  return (
    <Container fluid className="my-5 bg-corbeau rounded-4 p-4">
      <div className="my-4">
        <h1 className="fw-bold text-white">New Releases</h1>
      </div>

      <Row className="g-4 m-0 p-0 bg-corbeau">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : error ? (
          <>
            <ErrorAlert description={error} />
          </>
        ) : randomSongs.length > 0 ? (
          <Row className="g-4 m-0 p-0">
            {randomSongs.map((song, index) => (
              <Col lg={3} md={4} sm={6} xs={6} key={index}>
                <SongCard viewMode="dark" song={song} index={index} />
              </Col>
            ))}

            {/* <div className="ms-auto d-flex align-items-center float-end">
              <span className="fw-bold text-white">See All</span>
              <Button
                variant="light"
                size="sm"
                className="fw-bold fs-6 mx-2 rounded-circle"
              >
                <MdArrowOutward />
              </Button>
            </div> */}
          </Row>
        ) : (
          <p className="text-center">No top artists available at the moment.</p>
        )}
      </Row>
    </Container>
  );
}

export default NewReleases;
