// src/services/apiService.js
import { showToast } from "../components/_toasts/notificationUtils";
import { ROUTES } from "../routes/constants";
import { setAuthToken } from "../utils/authHelpers";
import { makeRequest } from "./apiRequests";
import { handleApiError } from "./apiUtils";

/* Auth Section */
async function loginUser(payload = {}, config = {}, resetForm, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/login-user",
      payload,
      config
    );
    if (status === 200 && data) {
      showToast("success", data.message, 2);
      setAuthToken(data.data.token);
      navigate(ROUTES.MAIN.HOME);
      resetForm();
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function createUser(payload = {}, config = {}, resetForm, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/create-user",
      payload,
      config
    );
    if (status === 201) {
      showToast("success", data.message, 2);
      navigate(ROUTES.AUTH.LOGIN);
      resetForm();
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function verifyUser(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      "/user/verify-user",
      payload,
      config
    );
    if (status === 200) {
      showToast("success", data.message, 2);
      navigate(ROUTES.AUTH.LOGIN);
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function forgetPassword(payload = {}, config = {}, resetForm, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/forget-password",
      payload,
      config
    );
    if (status === 200) {
      showToast("success", data.message, 2);
      resetForm();
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function resetPassword(payload = {}, config = {}, resetForm, navigate) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      "/user/reset-password",
      payload,
      config
    );
    if (status === 200) {
      showToast("success", data.message, 2);
      navigate(ROUTES.AUTH.LOGIN);
      resetForm();
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function changeUserPassword(
  payload = {},
  config = {},
  resetForm,
  navigate
) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      "/user/change-user-password",
      payload,
      config
    );
    if (status === 200) {
      showToast("success", data.message, 2);
      resetForm();
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function editUserProfile(payload = {}, config = {}, resetForm, navigate) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      "/user/edit-user",
      payload,
      config
    );
    if (status === 200) {
      showToast("success", data.message, 2);
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getUserProfile(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/user-profile",
      payload,
      config
    );
    if (status === 200) {
      // showToast("success", data.message, 2);
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

/* Lyrics Section */
async function getSearchResult(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/get-search-lyrics",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

async function getTrackLyrics(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/get-lyrics",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

/* Others Section */
async function getTestimonialsList(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/get-testimonial",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

async function getNewsList(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/get-news-list",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

async function getNewsDetails(payload = {}, config = {}, navigate) {
  try {
    let { newsId } = payload;
    const { data, status } = await makeRequest(
      "GET",
      `/user/get-news?newsId=${newsId}`,
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

async function submitUserFeedback(
  payload = {},
  config = {},
  resetForm,
  navigate
) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/submit-user-feedback",
      payload,
      config
    );
    if (status === 201) {
      resetForm();
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

export const fetchGenres = async (payload = {}, config = {}) => {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/get-genres",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
};


export const getSearchSong = async (payload = {}, config = {}) => {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/search-song",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
};


 export async function addSongToPlayList(
  payload = {},
  config = {},
  resetForm,
  navigate
) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/add-song-to-playlist",
      payload,
      config
    );
    console.log(data,"data,kkkkkkkkkkk",status,"hhhhhhhhhhhhhhh");
    if (data.success == true) {
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}


export async function addCommentToLyrics(
  payload = {},
  config = {},
  resetForm,
  navigate
) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/add-comment",
      payload,
      config
    );
    console.log(data,"data,kkkkkkkkkkk",status,"hhhhhhhhhhhhhhh");
    if (data.success == true) {
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}


export const getUserComment = async (payload = {}, config = {}) => {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/get-user-comments-list",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
};


export const getQuiz = async (payload = {}, config = {}) => {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/get-quiz",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
};


export async function addCommentToQuiz(
  payload = {},
  config = {},
  resetForm,
  navigate
) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/add-quiz-comment",
      payload,
      config
    );
    if (data.success == true) {
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}


export async function getCommentToQuiz(
  payload = {},
  config = {},
  resetForm,
  navigate
) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/get-quiz-comment-by-quiz-id",
      payload,
      config
    );
    if (data.success == true) {
      return data;
    } else {
      showToast('error', data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

export const controller = {
  auth: {
    createUser,
    loginUser,
    forgetPassword,
    resetPassword,
    changeUserPassword,
    getUserProfile,
    editUserProfile,
    getSearchResult,
    getTrackLyrics,
    getTestimonialsList,
    getNewsList,
    getNewsDetails,
    submitUserFeedback,
    verifyUser,
  },
  config: { fetchGenres },
};

/**
 * Makes a GET / POST / PUT / DELETE / PATCH request to the specified endpoint with optional data.
 *
 * @param {string} endpoint - The endpoint to send the GET / POST / PUT / DELETE / PATCH request to.
 * @param {Object} [data={}] - Optional data to send in the request body.
 * @param {Object} [config={}] - Optional configuration for the request.
 * @returns {Promise} - Axios promise for the POST request.
 */
