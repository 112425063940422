import React from "react";
import { List, Typography, Spin, Avatar } from "antd";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";

const { Title, Text } = Typography;

const AlbumSongsSection = ({ albumSongs, isLoading }) => {
  const navigate = useNavigate();

  const tableData = albumSongs?.songs?.map((song) => ({
    ...song,
    song: song,
  }));

  const isEmpty =
    tableData?.length === 0 ||
    (tableData?.length === 1 && Object.keys(tableData[0]).length === 0);

  return (
    <Container>
      <Title level={1}>Songs</Title>

      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spin tip="Loading..." size="large" />
        </div>
      ) : isEmpty ? (
        <Text type="secondary">Song List is empty</Text>
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={tableData}
          renderItem={(song, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <div className="d-flex align-items-center">
                    <Text strong className="mx-2">
                      #{index + 1}
                    </Text>
                    <Avatar
                      shape="square"
                      src={song.song_image_link}
                      size={40}
                    />
                  </div>
                }
                title={
                  <div className="d-flex justify-content-between align-items-center">
                    <Text
                      strong
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          generateDynamicRoute(ROUTES.MAIN.LYRICS, {
                            songName: song.song_name,
                            isrcKey: song.isrc_code,
                          })
                        )
                      }
                    >
                      {song.song_name || "Song Title"}
                    </Text>
                    <Text type="secondary">{song.views} Views</Text>
                  </div>
                }
                // description={
                //   <div className="d-flex justify-content-between">
                //     <Text type="secondary">{song.views}</Text>
                //     <br />
                //     <Text type="secondary">
                //       {stringUtils.sanitizeName(song.album_name)}
                //     </Text>
                //   </div>
                // }
              />
            </List.Item>
          )}
        />
      )}
    </Container>
  );
};

export default AlbumSongsSection;
