import { Button, Col, Row, Spin } from "antd";
import React from "react";
import { Image } from "react-bootstrap";
import { BsMusicNoteList } from "react-icons/bs";
import { HeartOutlined, ShareAltOutlined } from "@ant-design/icons";

import { StaticImages } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { formatStringType } from "../../utils/helpers";

function LyricsInfoCard({ lyrics, loading }) {
  const navigate = useNavigate();
  // Function to handle sharing
  const handleShare = async () => {
    const shareData = {
      title: lyrics?.title || "Song Title",
      text: `${lyrics?.title || "Song Title"} by ${
        lyrics?.artist?.name || "Artist Name"
      }`,
      url: window.location.href, // Current page URL
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
        console.log("Shared successfully");
      } else {
        console.error("Web Share API is not supported in this browser.");
        alert("Sharing is not supported in this browser.");
      }
    } catch (error) {
      console.error("Error sharing content:", error);
    }
  };
  return (
    <Spin
      spinning={loading}
      tip="Loading..."
      style={{ width: "100%" }}
      size="large"
    >
      <Row gutter={24} className="my-4 d-flex align-items-center">
        {/* Artist Image */}
        <Col span={24} lg={6} md={6} sm={6} xs={6} className="px-lg-4">
          {/* Conditionally render Image or Spinner based on loading state */}
          <Image
            src={
              lyrics?.songData?.song_image_link || StaticImages.App.AppDefaultS
            }
            alt="Artist Image"
            className="rounded-4"
            style={{ width: "100%", height: "auto" }}
          />
        </Col>

        {/* Song Details */}
        <Col span={24} lg={18} md={18} sm={18} xs={18}>
          {/* Song details */}
          <h6>
            {lyrics?.songData?.artist?.artist_name || "Artist Name"} -{" "}
            {lyrics?.songData?.genre || "Not Found"}
          </h6>
          <h2>{lyrics?.songData?.song_name || "Song Title"}</h2>
          <h6>{lyrics?.songData?.views} Views</h6>

          {/* Action buttons */}
          <div className="my-2 d-flex gap-2">
            {console.log(
              lyrics?.songData?.artist?.artist_name,
              "uidaudoadnand al"
            )}
            <Button
              variant="solid"
              color="default"
              icon={<BsMusicNoteList />}
              onClick={() =>
                navigate(
                  generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                    artistName: formatStringType(
                      lyrics?.songData?.artist?.artist_name,
                      "kebabCase"
                    ),
                    artistId: lyrics?.songData?.artist?.artist_id,
                  })
                )
              }
            >
              View Artist
            </Button>
            <Button variant="solid" color="default" icon={<HeartOutlined />}>
              Add to Playlist
            </Button>
            <Button
              variant="text"
              color="default"
              icon={<ShareAltOutlined className="fs-4 fw-bold" />}
              onClick={handleShare}
            />
          </div>
        </Col>
      </Row>
    </Spin>
  );
}

export default LyricsInfoCard;
