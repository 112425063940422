import React from "react";
import { Badge, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { motion } from "framer-motion";
import { FaFire } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import { StaticImages } from "../../utils/constants";
import styles from "./ArtistCard.module.css";
import _ from "lodash";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";

function ArtistCard({ viewMode = "card", artist, index }) {
  const navigate = useNavigate();
  return (
    <>
      {viewMode === "badge" ? (
        <>
          <motion.div
            whileHover={{
              backgroundColor: "#FF4163",
              transition: { duration: 0.3 },
            }}
            className="p-3 rounded-4 text-center position-relative border"
            style={{
              maxWidth: "250px",
            }}
          >
            <div className="position-relative">
              {/* Artist Image */}
              <Image
                src={artist.artist_image_link || StaticImages.App.AppDefaultS}
                alt={`Artist ${artist.artist_name || "Unknown"}`}
                roundedCircle
                className="shadow"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                }}
              />

              {/* Badge Icon */}
              <Badge
                pill
                className="bg-hotPink position-absolute top-0 end-0 rounded-circle"
                style={{
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "14px",
                }}
              >
                <FaFire />
              </Badge>
            </div>

            {/* Artist Name and Role */}
            <p
              className="fw-bold mt-3 mb-0"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                    artistName: _.kebabCase(artist?.artist_name),
                    artistId: artist?.artist_id,
                  })
                )
              }
            >
              {artist.artist_name || "Unknown Artist"}
            </p>
            <p className="text-muted">{artist.role || "Explorer"}</p>
          </motion.div>
        </>
      ) : (
        <>
          <motion.div
            whileHover={{
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              transition: { duration: 0.3 },
            }}
            className={`position-relative rounded-4 ${styles.artistCard}`}
          >
            <Image
              src={artist.artist_image_link || StaticImages.App.AppDefaultS}
              alt={`Artist ${artist.artist_name || "Unknown"}`}
              className={`w-100 h-100 ${styles.artistImage}`}
            />
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{artist.artist_name}</Tooltip>}
            >
              <p
                className={`text-center fw-semibold text-white ${styles.artistName}`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                      artistName: _.kebabCase(artist?.artist_name),
                      artistId: artist?.artist_id,
                    })
                  )
                }
              >
                {artist.artist_name || "Unknown Artist"}
              </p>
            </OverlayTrigger>
          </motion.div>
        </>
      )}
    </>
  );
}

export default ArtistCard;
