import React from "react";

import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { Col, Container, Image, Row } from "react-bootstrap";
import { StaticImages } from "../../../utils/constants";

// Reusable component for section headers
const SectionHeader = ({ text }) => (
  <div className="fw-bold fs-4 my-3">{text}</div>
);

// Reusable component for section content
const SectionContent = ({ children }) => <p>{children}</p>;

// Main component
function TermsOfUsePage() {
  // Terms content
  const termsContent = {
    title: "Terms of Use",
    lastUpdated: "21 May 2024",
    sections: [
      {
        heading: "Welcome",
        content:
          "Welcome to our music-related website. By accessing and using our site, you agree to comply with the following terms of use. Please read them carefully.",
      },
      {
        heading: "Acceptance of Terms",
        content:
          "By accessing or using our website, you agree to be bound by these Terms of Use and any applicable laws and regulations. If you do not agree with any part of these terms, you should not use our site.",
      },
      {
        heading: "Use of Site Content",
        content:
          "All content provided on our website, including music files, articles, and images, is owned or licensed by us. You may not reproduce, distribute, modify, or use any part of our content without prior written consent, except for personal, non-commercial purposes.",
      },
      {
        heading: "User Conduct",
        content:
          "You agree not to use our site for any unlawful or prohibited activities. This includes, but is not limited to, posting offensive material, attempting to gain unauthorized access to our systems, or infringing on the rights of others.",
      },
      {
        heading: "Account Registration",
        content:
          "Certain features may require you to register for an account. You agree to provide accurate information and to keep your account details secure. You are responsible for all activity under your account.",
      },
      {
        heading: "Third-Party Links",
        content:
          "Our website may contain links to third-party sites. We are not responsible for the content or practices of these external sites and encourage you to review their terms and privacy policies.",
      },
      {
        heading: "Limitation of Liability",
        content:
          "We are not liable for any damages that may result from the use or inability to use our site. This includes, but is not limited to, errors, interruptions, or loss of data.",
      },
      {
        heading: "Changes to Terms",
        content:
          "We reserve the right to modify these Terms of Use at any time. Any changes will be posted on this page, and your continued use of the site signifies acceptance of those changes.",
      },
      {
        heading: "Contact Us",
        content:
          "If you have any questions about these Terms of Use, please contact us at [support email].",
      },
    ],
  };

  const seoData = getSEOConfig("termsPage");

  return (
    <MainLayout>
      <SEOManager {...seoData} />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div>
          <Image fluid rounded src={StaticImages.GoogleAds.DesktopAd_H_large} />
        </div>

        <Row className="m-0 p-0">
          <Col lg={9} md={9} sm={12} xs={12}>
            <div className="p-4">
              <h1 className="fw-bold my-4">Terms of Use</h1>
              <p className="fw-bold">
                Last Updated: {termsContent.lastUpdated}
              </p>
              <div className="terms-content">
                {termsContent.sections.map((section, index) => (
                  <div key={index}>
                    <SectionHeader text={section.heading} />
                    <SectionContent>{section.content}</SectionContent>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={12} xs={12}></Col>
        </Row>
      </Container>
    </MainLayout>
  );
}

export default TermsOfUsePage;
