import { createApiThunk } from "../../utils/thunkUtils";

export const getNewsList = createApiThunk(
  "news/getNewsList",
  { method: "GET", endpoint: "/user/get-news-list" },
  ({ data }) => {},
  (error) => {}
);

export const getNewsDetails = createApiThunk(
  "news/getNewsDetails",
  { method: "GET", endpoint: "/user/get-news" },
  ({ data }) => {},
  (error) => {}
);

export const newsThunks = {
  getNewsList,
  getNewsDetails,
};
