import React from "react";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { StaticImages } from "../../utils/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";

function SongCard({ viewMode = "grid", song, index }) {
  const navigate = useNavigate();
  const renderSongDetails = (song, maxWidth = "150px") => (
    <>
      <div className="d-flex align-items-center">
        <Image
          src={song?.song_image_link || StaticImages.App.AppDefaultS}
          alt={`${song?.song_name} avatar`}
          roundedCircle
          height={40}
          width={40}
        />
        <div className="ms-3">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{song?.song_name}</Tooltip>}
          >
            <div
              className="text-truncate fw-bold"
              style={{ maxWidth, cursor: "pointer" }}
              onClick={() =>
                navigate(
                  generateDynamicRoute(ROUTES.MAIN.LYRICS, {
                    songName: _.kebabCase(song?.song_name),
                    isrcKey: song?.isrc_code,
                  })
                )
              }
            >
              {song?.song_name}
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{song?.artist?.artist_name}</Tooltip>}
          >
            <div
              className="text-truncate"
              style={{ maxWidth, cursor: "pointer" }}
              onClick={() =>
                navigate(
                  generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                    artistName: _.kebabCase(song?.artist?.artist_name),
                    artistId: song?.artist?.artist_id,
                  })
                )
              }
            >
              {song?.artist?.artist_name}
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </>
  );

  return (
    <>
      {viewMode === "grid" ? (
        <>
          <div className="rounded-4 p-2 bg-white overflow-hidden shadow-sm">
            <div
              className="position-relative"
              style={{ paddingBottom: "100%" }}
            >
              <Image
                fluid
                src={song?.song_image_link || StaticImages.App.AppDefaultS}
                alt={`Top song - ${song?.song_name}`}
                className="w-100 h-100 rounded-4 position-absolute top-0 start-0"
                style={{ objectFit: "cover" }}
              />
            </div>

            <div className="p-2">{renderSongDetails(song)}</div>
          </div>
        </>
      ) : viewMode === "dark" ? (
        <>
          <div className="rounded-4 p-2 bg-corbeau text-white overflow-hidden shadow-sm">
            <div
              className="position-relative bg-corbeau"
              style={{ paddingBottom: "100%" }}
            >
              <Image
                fluid
                src={song?.song_image_link || StaticImages.App.AppDefaultS}
                alt={`Top song - ${song?.song_name}`}
                className="w-100 h-100 rounded-4 position-absolute top-0 start-0"
                style={{ objectFit: "cover" }}
              />
            </div>

            <div className="p-2 bg-corbeau text-white">
              {renderSongDetails(song)}
            </div>
          </div>
        </>
      ) : viewMode === "myLyricsList" ? (
        <>
          <div key={index} className="d-flex align-items-center mb-2 rounded-4">
            <div className="fw-bold me-3">#{index + 1}</div>
            <Image
              src={song?.song_image_link || StaticImages.App.AppDefaultS}
              alt={`${song?.songName || "Unknown Song"} avatar`}
              className="rounded me-3"
              style={{ width: "50px", height: "50px" }}
            />
            <div>
              <div className="d-flex align-items-center">
                <div className="ms-3">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{song?.songName}</Tooltip>}
                  >
                    <div
                      className="text-truncate fw-bold"
                      style={{ maxWidth: "150px", cursor: "pointer" }}
                    >
                      {song?.songName}
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{song?.artist?.artist_name}</Tooltip>}
                  >
                    <div
                      className="text-truncate"
                      style={{ maxWidth: "150px", cursor: "pointer" }}
                    >
                      {song?.artist?.artist_name}
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div key={index} className="d-flex align-items-center mb-2 rounded-4">
            <div className="fw-bold me-3">#{index + 1}</div>
            <Image
              src={song?.song_image_link || StaticImages.App.AppDefaultS}
              alt={`${song?.song_name || "Unknown Song"} avatar`}
              className="rounded me-3"
              style={{ width: "50px", height: "50px" }}
            />
            <div>
              <div className="d-flex align-items-center">
                <div className="ms-3">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{song?.song_name}</Tooltip>}
                  >
                    <div
                      className="text-truncate fw-bold"
                      style={{ maxWidth: "150px", cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          generateDynamicRoute(ROUTES.MAIN.VIEW_SONG_LYRICS, {
                            songName: _.kebabCase(song?.song_name),
                            isrcKey: song?.isrc_code,
                          })
                        )
                      }
                    >
                      {song?.song_name}
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{song?.artist?.artist_name}</Tooltip>}
                  >
                    <div
                      className="text-truncate"
                      style={{ maxWidth: "150px", cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                            artistName: _.kebabCase(song?.artist?.artist_name),
                            artistId: song?.artist?.artist_id,
                          })
                        )
                      }
                    >
                      {song?.artist?.artist_name}
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
              {/* {renderSongDetails(song?.song_name, song?.artist?.artist_name)} */}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SongCard;
