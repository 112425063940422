import { showToast } from "../../components/_toasts/notificationUtils";
import { createApiThunk } from "../../utils/thunkUtils";

export const getQuiz = createApiThunk(
  "quiz/getQuiz",
  { method: "POST", endpoint: "/user/get-quiz" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  }
);

export const getValidateQuiz = createApiThunk(
  "quiz/getValidateQuiz",
  { method: "POST", endpoint: "/user/validate-quiz" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  }
);

export const quizThunks = {
  getValidateQuiz,
  getQuiz,
};
