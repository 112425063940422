import React from "react";
import { Container } from "react-bootstrap";

import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import LatestMusicNews from "../../../components/_sections/LatestMusicNews";
import TopBar from "../../../components/_buttons/TopBar";

function NewsPage() {
  const seoData = getSEOConfig("newsPage");

  return (
    <>
      <MainLayout>
        <SEOManager {...seoData} />
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>

          <div>
            <LatestMusicNews display={12} />
          </div>
        </Container>
      </MainLayout>
    </>
  );
}

export default NewsPage;
