import React, { useEffect, useMemo } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { MdArrowOutward } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import _ from "lodash";

import { StaticImages } from "../../utils/constants";
import { songController } from "../../features/song/songSlice";
import { getConfig } from "../../services/apiUtils";
import { stringUtils } from "../../utils/utilities";
import { ErrorAlert } from "../_alerts/UIAlerts";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";

function RecommendedSongs({ display = 5, genreType = "all" }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Select recommended songs and loading/error states from Redux
  const { recommendedSongs, isLoading, error } = useSelector(
    (state) => state.song
  );

  useEffect(() => {
    const fetchSongs = async () => {
      try {
        await dispatch(
          songController.getRecommendedSongs({
            payload: genreType !== "all" ? { genre: genreType } : {},
            config: getConfig(),
          })
        ).unwrap();
      } catch (error) {
        console.error("Failed to fetch recommended songs:", error);
      }
    };

    fetchSongs();
  }, [dispatch, genreType]);

  // Shuffle and slice songs based on display count
  const randomSongs = useMemo(() => {
    return recommendedSongs?.songs?.length
      ? [...recommendedSongs.songs]
          .sort(() => Math.random() - 0.5)
          .slice(0, display)
      : [];
  }, [recommendedSongs, display]);

  return (
    <div className="my-4 p-4 bg-corbeau">
      <div className="d-flex justify-content-center align-items-center text-ghostWhite my-3">
        <h1 className="fw-bold">
          Recommended {genreType !== "all" ? _.capitalize(genreType) : ""} Songs
        </h1>
      </div>

      <Row className="g-4">
        <Col
          lg={3}
          className="d-flex align-items-center justify-content-center d-none d-lg-block"
        >
          <Image fluid src={StaticImages.GoogleAds.DesktopAd_V} />
        </Col>

        <Col lg={9}>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "50vh" }}
            >
              <Spin tip="Loading..." size="large" />
            </div>
          ) : error ? (
            <>
              <ErrorAlert description={error} />
            </>
          ) : randomSongs.length > 0 ? (
            <>
              <div>
                <div className="d-flex justify-content-between align-items-center text-wetAsphalt fw-bold">
                  <div className="col-2"></div>
                  <div className="col-4">Song</div>
                  <div className="col-2"></div>
                  <div className="col-4 text-end">Album</div>
                </div>
                {randomSongs.map((song, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center p-3 mb-3 bg-darkKnight text-wetAsphalt rounded-3"
                  >
                    <div className="fw-bold me-3">0{index + 1}</div>
                    <Image
                      src={song?.song_image_link || StaticImages.HotAlbum.Img2}
                      alt={`${song?.song_name || "Unknown Song"} avatar`}
                      className="rounded-circle me-3"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <div className="flex-grow-1">
                      <div
                        className="text-white fw-bold"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            generateDynamicRoute(ROUTES.MAIN.LYRICS, {
                              songName: _.kebabCase(song?.song_name),
                              isrcKey: song?.isrc_code,
                            })
                          )
                        }
                      >
                        {song?.song_name || "Unknown Song"}
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                              artistName: _.kebabCase(
                                song?.artist?.artist_name
                              ),
                              artistId: song?.artist?.artist_id,
                            })
                          )
                        }
                      >
                        {song?.artist?.artist_name || "Unknown Artist"}
                      </div>
                    </div>
                    <div>
                      {stringUtils.sanitizeName(song?.album?.album_name) ||
                        "Unknown Album"}
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex align-items-center mt-3 text-ghostWhite">
                <span className="fw-bold mx-2">See All</span>
                <Button
                  variant="light"
                  size="sm"
                  className="fw-bold fs-6 me-auto rounded-circle"
                  onClick={() => navigate(ROUTES.MAIN.SONGS)}
                >
                  <MdArrowOutward />
                </Button>
              </div>
            </>
          ) : (
            <p className="text-center">
              No recommended songs available at the moment.
            </p>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default RecommendedSongs;
