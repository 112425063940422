import axios from "axios";

const httpInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "",
});

/**
 * Makes an HTTP request using the Axios instance.
 *
 * @param {string} method - HTTP method (e.g., 'GET', 'POST').
 * @param {string} endpoint - API endpoint.
 * @param {Object} data - Request body or query params.
 * @param {Object} config - Additional Axios config.
 * @returns {Promise} Axios response.
 */
export async function makeRequest(method, endpoint, data = {}, config = {}) {
  const requestMethod = method.toUpperCase();
  const options = { ...config };

  try {
    if (requestMethod === "GET" || requestMethod === "DELETE") {
      return await httpInstance[requestMethod.toLowerCase()](endpoint, {
        params: data,
        ...options,
      });
    }
    return await httpInstance[requestMethod.toLowerCase()](
      endpoint,
      data,
      options
    );
  } catch (error) {
    // handleApiError(error);
    throw error;
  }
}

export default httpInstance;
