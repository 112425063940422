import React from "react";
import { Accordion, Col, Container, Image, Row } from "react-bootstrap";

import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { StaticImages } from "../../../utils/constants";

function HelpPage() {
  const seoData = getSEOConfig("helpPage");

  // FAQ items
  const faqs = [
    {
      question: "How can I create an account and start using the site?",
      answer:
        "To create an account, go to the 'Sign Up' page, fill in the required details, and submit the form. You will then be able to access our site's features.",
    },
    {
      question: "What types of content can I access on this website?",
      answer:
        "Our website provides access to a variety of music, articles, and community features to enhance your experience.",
    },
    {
      question:
        "Are there any costs associated with using the website's features?",
      answer:
        "Basic access is free, but we offer premium features and content through subscription plans.",
    },
    {
      question: "How is my personal information protected on the site?",
      answer:
        "We prioritize your privacy and use industry-standard security measures to protect your data.",
    },
    {
      question:
        "What should I do if I encounter a technical issue or need support?",
      answer:
        "If you experience any technical issues, please contact our support team at [support email] or use the contact form on our website. We strive to respond within 24-48 hours.",
    },
    {
      question:
        "Can I download or share the music and content from the website?",
      answer:
        "Content on our site is for streaming only. Downloading and sharing are restricted to protect copyright.",
    },
    {
      question: "What is your policy on user-generated content and comments?",
      answer:
        "We encourage positive engagement and expect users to follow community guidelines, avoiding offensive or inappropriate content.",
    },
  ];

  return (
    <MainLayout>
      {/* <SEOManager {...seoData} /> */}
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <div className="my-4 p-4">
          <h1 className="fw-bold mb-4">FAQs</h1>

          <Row>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Accordion>
                {faqs.map((faq, index) => (
                  <Accordion.Item
                    eventKey={String(index)}
                    key={index}
                    className="mb-3"
                  >
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body>{faq.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>

            <Col lg={4} md={4} sm={12} xs={12} className="text-center">
              <Image
                fluid
                rounded
                src={StaticImages.GoogleAds.DesktopAd_V}
                className="d-none d-lg-block"
              />
              <Image
                fluid
                rounded
                src={StaticImages.GoogleAds.MobileAd}
                className="d-lg-none"
              />
            </Col>
          </Row>

          {/* Search Section */}
          {/* <div className="text-center mt-5 bg-sliceHeaven text-white py-5 rounded-3">
          <h3>Can’t find what you’re looking for?</h3>
          <Form className="d-inline-flex align-items-center my-3">
            <Form.Control
              type="text"
              placeholder="Ask your questions here"
              className="me-2"
              style={{ width: "300px" }}
            />
            <button type="submit" className="btn btn-light">
              <i className="bi bi-search"></i>
            </button>
          </Form>
        </div> */}
        </div>
      </Container>
    </MainLayout>
  );
}

export default HelpPage;
