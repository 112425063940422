import React, { useEffect } from "react";
import { Button, Col, Image, Row, Spinner, Table } from "react-bootstrap";
import { StaticImages } from "../../utils/constants";
import { MdArrowOutward } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { songController } from "../../features/song/songSlice";
import { getConfig } from "../../services/apiUtils";
import { ErrorAlert } from "../_alerts/UIAlerts";
import { stringUtils } from "../../utils/utilities";
import { ROUTES } from "../../routes/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";

function ExploreSongs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { exploreSongs, isLoading, error } = useSelector(
    (state) => state.song || {}
  );

  useEffect(() => {
    dispatch(
      songController.getExploreSongs({
        payload: {},
        config: getConfig(),
      })
    ).catch((error) => console.error("Failed to fetch artists:", error));
  }, [dispatch]);

  return (
    <div className="my-4">
      <div className="my-4">
        <h1 className="fw-bold">Explore Songs</h1>
      </div>

      <Row className="g-4 m-0 p-0">
        <Col lg={9} md={9} sm={12} xs={12}>
          {isLoading ? (
            <div className="text-center my-4">
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : error ? (
            <>
              <ErrorAlert description={error} />
            </>
          ) : exploreSongs && exploreSongs.length > 0 ? (
            <Row className="g-4 m-0 p-0 bg-white rounded-4">
              <>
                <Table borderless responsive className="p-2">
                  <thead>
                    <tr>
                      <th>Song</th>
                      <th>Album</th>
                      <th>Artist</th>
                      <th>Views</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exploreSongs &&
                      exploreSongs?.length > 0 &&
                      exploreSongs?.map((song, index) => (
                        <tr key={index} className="align-middle">
                          <td className="d-flex align-items-center">
                            {/* Render the avatar alongside the song title */}
                            <Image
                              fluid
                              src={
                                song?.song_image_link ||
                                StaticImages.App.AppDefaultS
                              }
                              // alt={`${song?.song_name} avatar`}
                              width="40px"
                              rounded
                              className="mx-3"
                            />
                            <span
                              className="fw-bold"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  generateDynamicRoute(ROUTES.MAIN.LYRICS, {
                                    songName: _.kebabCase(song?.song_name),
                                    isrcKey: song?.isrc_code,
                                  })
                                )
                              }
                            >
                              {song?.song_name}
                            </span>
                          </td>
                          <td>
                            <small
                              className="text-muted"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  generateDynamicRoute(
                                    ROUTES.MAIN.ALBUM_SONGS,
                                    {
                                      albumName: _.kebabCase(
                                        song?.album?.album_name
                                      ),
                                      albumId: song?.album?.album_id,
                                    }
                                  )
                                )
                              }
                            >
                              {stringUtils.sanitizeName(
                                song?.album?.album_name
                              )}
                            </small>
                          </td>
                          <td>
                            <small
                              className="text-muted"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  generateDynamicRoute(
                                    ROUTES.MAIN.VIEW_ARTIST_BIO,
                                    {
                                      artistName: _.kebabCase(
                                        song?.artist?.artist_name
                                      ),
                                      artistId: song?.artist?.artist_id,
                                    }
                                  )
                                )
                              }
                            >
                              {song?.artist?.artist_name}
                            </small>
                          </td>
                          <td>
                            <small className="text-muted">100</small>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </>
              {/* <div className="ms-auto d-flex align-items-center float-end">
                <span className="fw-bold">See All</span>
                <Button
                  variant="eigengrau"
                  size="sm"
                  className="fw-bold fs-6 mx-2 rounded-circle"
                >
                  <MdArrowOutward />
                </Button>
              </div> */}
            </Row>
          ) : (
            <p className="text-center">
              No trending artists available at the moment.
            </p>
          )}
        </Col>
        <Col
          lg={3}
          md={3}
          sm={12}
          xs={12}
          className="d-flex align-items-center justify-content-center flex-column"
        >
          <Image fluid src={StaticImages.GoogleAds.DesktopAd_V} />

          <Image fluid src={StaticImages.GoogleAds.DesktopAd_S} />
        </Col>
      </Row>
    </div>
  );
}

export default ExploreSongs;
