import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Image } from "react-bootstrap";

import MainLayout from "../../../layouts/MainLayout";
import TopSongs from "../../../components/_sections/TopSongs";
import NewReleases from "../../../components/_sections/NewReleases";
import ExploreSongs from "../../../components/_sections/ExploreSongs";
import TopBar from "../../../components/_buttons/TopBar";
import { StaticImages } from "../../../utils/constants";

function SongsPage() {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <div className="m-2">
          <Image
            fluid
            src={StaticImages.GoogleAds.DesktopAd_H_large}
            className="w-100 rounded-3"
            alt="Home Banner"
          />
        </div>
        <div className="m-2">
          <TopSongs />
        </div>
        <div className="m-2">
          <NewReleases />
        </div>
        <div className="m-2">
          <ExploreSongs />
        </div>
      </Container>
    </MainLayout>
  );
}

export default SongsPage;
