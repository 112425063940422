import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
import { Avatar, Button, Spin, Tag, Typography } from "antd";
import { StaticImages } from "../../../utils/constants";
import SEOManager from "../../../utils/SEOManager";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import moment from "moment";
import { getAuthConfig } from "../../../services/apiUtils";
import { authController } from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaCrown, FaRegCommentDots } from "react-icons/fa6";
import { MdQueueMusic } from "react-icons/md";
import { EditOutlined } from "@ant-design/icons";
import MyLyrics from "../../../components/_sections/MyLyrics";
import FavouriteArtists from "../../../components/_sections/FavouriteArtists";
import { ROUTES } from "../../../routes/constants";
import { formatStringType } from "../../../utils/helpers";
import { ErrorAlert } from "../../../components/_alerts/UIAlerts";

function ProfilePreviewPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, error } = useSelector((state) => state.auth);

  const [userProfile, setUserProfile] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);

  const fetchUserProfile = useCallback(async () => {
    try {
      const { data } = await dispatch(
        authController.getUserProfile({ config: getAuthConfig(), navigate })
      ).unwrap();
      setUserProfile(data);
    } catch (error) {
      console.error("Error Fetching User Profile:", error);
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  return (
    <MainLayout>
      <SEOManager
        title="Profile - LyricsWeb"
        ogUrl="www.lyricsweb.com/auth/profile"
      />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        {/* Spinner or Content */}
        <Row gutter={16} className="m-2 justify-content-center">
          {isLoading || !userProfile ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "300px", width: "100%" }}
            >
              <Spin tip="Loading..." size="large" />
            </div>
          ) : error ? (
            <ErrorAlert description={error} />
          ) : (
            <>
              {/* Avatar Column */}
              <Col className="d-flex justify-content-center">
                <Avatar
                  size={200}
                  src={avatarFile || StaticImages.App.AppDefaultS}
                  alt="User Avatar"
                  className="mb-3"
                />
              </Col>

              {/* User Info Column */}
              <Col
                lg={8}
                md={8}
                sm={12}
                xs={12}
                className="d-flex flex-column justify-content-center"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <Typography.Title level={1} className="mb-0">
                    {formatStringType(userProfile?.username) || "Username N/A"}{" "}
                    <FaCrown className="text-hotPink mx-2" />
                  </Typography.Title>
                  <Tag
                    bordered={false}
                    color="error"
                    className="fw-bold rounded-pill fs-6 p-2 ms-2"
                  >
                    Points {userProfile?.points || 0}
                  </Tag>
                </div>

                <div className="d-flex justify-content-between align-items-start my-3">
                  <div className="d-flex flex-column">
                    <Typography.Paragraph className="d-flex align-items-center mb-1">
                      <MdQueueMusic className="fs-4 mx-2" />
                      Lyrics Contributed: <strong>15</strong>
                    </Typography.Paragraph>
                    <Typography.Paragraph className="d-flex align-items-center mb-0">
                      <FaRegCommentDots className="fs-4 mx-2" />
                      Comments Received: <strong>25</strong>
                    </Typography.Paragraph>
                  </div>

                  <Button
                    variant="solid"
                    color="default"
                    className="mt-3 ms-3"
                    onClick={() => navigate(ROUTES.AUTH.PROFILE)}
                  >
                    <EditOutlined /> Edit Profile
                  </Button>
                </div>
              </Col>
            </>
          )}
        </Row>

        {/* Sections */}
        <div className="m-2">
          <MyLyrics />
        </div>

        <div className="m-2">
          <FavouriteArtists />
        </div>

        {/* Ad Banner */}
        <div className="m-2 mt-5">
          <Image
            fluid
            src={StaticImages.GoogleAds.DesktopAd_H_small}
            className="w-100 rounded-3"
            alt="Home Banner"
          />
        </div>
      </Container>
    </MainLayout>
  );
}

export default ProfilePreviewPage;
