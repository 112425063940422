import { setAuthToken } from "../../utils/authHelpers";
import { ROUTES } from "../../routes/constants";
import { createApiThunk } from "../../utils/thunkUtils";
import { showToast } from "../../components/_toasts/notificationUtils";

export const loginUser = createApiThunk(
  "auth/loginUser",
  { method: "POST", endpoint: "/user/login-user" },
  ({ data, navigate, resetForm }) => {
    console.log(data.data.token);

    setAuthToken(data.data.token);
    navigate(ROUTES.MAIN.HOME);
    showToast("success", data.message, 2);
    resetForm();
  }
);

export const createUser = createApiThunk(
  "auth/createUser",
  { method: "POST", endpoint: "/user/create-user" },
  ({ data, navigate, resetForm }) => {
    navigate(ROUTES.AUTH.LOGIN);
    showToast("success", data.message, 2);
    resetForm();
  }
);

export const verifyUser = createApiThunk(
  "auth/verifyUser",
  { method: "PUT", endpoint: "/user/verify-user" },
  ({ data, navigate }) => {
    showToast("success", data.message, 2);
    // navigate(ROUTES.AUTH.LOGIN);
  }
);

export const forgetPassword = createApiThunk(
  "auth/forgetPassword",
  { method: "POST", endpoint: "/user/forget-password" },
  ({ data, resetForm }) => {
    showToast("success", data.message, 2);
    resetForm();
  }
);

export const resetPassword = createApiThunk(
  "auth/resetPassword",
  { method: "PUT", endpoint: "/user/reset-password" },
  ({ resetForm }) => resetForm()
);

export const changeUserPassword = createApiThunk(
  "auth/changeUserPassword",
  { method: "PUT", endpoint: "/user/change-user-password" },
  ({ resetForm }) => resetForm()
);

export const editUserProfile = createApiThunk(
  "auth/editUserProfile",
  {
    method: "PUT",
    endpoint: "/user/edit-user",
  },
  ({ data }) => {
    showToast("success", data.message, 2);
  }
);

export const getUserProfile = createApiThunk(
  "auth/getUserProfile",
  {
    method: "GET",
    endpoint: "/user/user-profile",
  },
  ({ data }) => {
    // showToast("success", data.message, 2);
  },
  (error) => {
    console.log(error);
  }
);

export const uploadProfilePicture = createApiThunk(
  "auth/uploadProfilePicture",
  {
    method: "POST",
    endpoint: "/user/upload-profile-picture",
  }
);

export const authThunks = {
  loginUser,
  createUser,
  verifyUser,
  forgetPassword,
  resetPassword,
  changeUserPassword,
  editUserProfile,
  getUserProfile,
  uploadProfilePicture,
};
