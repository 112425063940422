import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import styles from "./MusicMasterQuiz.module.css"; // Import the CSS module
import { StaticImages } from "../../utils/constants";
import { ROUTES } from "../../routes/constants";

function MusicMasterQuiz({ genreType = "all" }) {
  const navigate = useNavigate();
  return (
    <div
      className={`${styles.quizContainer} position-relative bg-sliceHeaven rounded-4`}
    >
      <Container fluid>
        <div className={`${styles.quizContent} p-5`}>
          {/* Header */}
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="fw-bold text-white">
              {genreType !== "all" ? _.capitalize(genreType) : ""} Music Master
              Quiz
            </h2>
            <Button
              variant="hotPink"
              className="text-white rounded-pill px-4"
              onClick={() => navigate(ROUTES.MAIN.QUIZ)}
            >
              Advance Quiz
            </Button>
          </div>

          {/* Question and Choices */}
          <Row className="my-5">
            <Col
              className="p-4"
              style={{
                backgroundImage: `url(${StaticImages.Utils.MusicMasterQuizBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover", // or use 'contain' if you want it to fit within the area without cropping
                backgroundPosition: "left center", // aligns the image to the left and centers it vertically
              }}
            >
              <p className={`${styles.questionText} text-white`}>
                Question 1 of 14
              </p>
              <h3 className="text-white mb-3">
                How much do you know about music?
              </h3>
            </Col>
            <Col className="p-4">
              <Row className="g-3">
                <Button
                  variant="outline-opulentBlue"
                  className={`${styles.choiceButton} w-100 border-cornflowerBlue border-2`}
                >
                  a. I know everything but don’t know anything
                </Button>
                <Button
                  variant="outline-opulentBlue"
                  className={`${styles.choiceButton} w-100 border-cornflowerBlue border-2`}
                >
                  b. I know everything but don’t know anything
                </Button>
                <Button
                  variant="outline-opulentBlue"
                  className={`${styles.choiceButton} w-100 border-cornflowerBlue border-2`}
                >
                  c. I know everything but don’t know anything
                </Button>
                <Button
                  variant="outline-opulentBlue"
                  className={`${styles.choiceButton} w-100 border-cornflowerBlue border-2`}
                >
                  d. I don’t know
                </Button>
              </Row>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <Button
              variant="light"
              className={`${styles.nextButton} px-4 py-2`}
            >
              Next Question →
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MusicMasterQuiz;
