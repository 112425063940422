import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import AppRoutes from "./routes/routes";
import useScrollToTop from "./hooks/useScrollToTop";
import withSessionTimeout from "./utils/withSessionTimeoutHOC";

import { getConfig } from "./services/apiUtils";
import { configController } from "./features/config/configSlice";

const ScrollToTopWrapper = ({ children }) => {
  useScrollToTop();
  return children;
};

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    try {
      dispatch(
        configController.getGenres({ payload: {}, config: getConfig() })
      );
    } catch (error) {
      console.error("Failed to fetch genres:", error);
    }
  }, [dispatch]);

  // useEffect(() => {
  //   // This sends a pageview event to Google Analytics
  //   window.gtag("config", "G-7P88QXT9CV", {
  //     page_path: location.pathname + location.search,
  //   });
  // }, [location]);

  return (
    <div>
      <ScrollToTopWrapper>
        <AppRoutes />
      </ScrollToTopWrapper>
    </div>
  );
}

export default withSessionTimeout(App);
