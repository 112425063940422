import { showToast } from "../../components/_toasts/notificationUtils";
import { createApiThunk } from "../../utils/thunkUtils";

export const getUserCommentsList = createApiThunk(
  "comment/getUserCommentsList",
  { method: "GET", endpoint: "/user/get-user-comments-list" },
  ({ data, payload }) => {
    // Optional success handling
  }
);

export const addUserComment = createApiThunk(
  "comment/addUserComment",
  { method: "POST", endpoint: "/user/add-comment" },
  ({ data, resetForm }) => {
    showToast("success", data.message, 2);
    resetForm();
  }
);

export const deleteUserComment = createApiThunk(
  "comment/deleteUserComment",
  { method: "DELETE", endpoint: "/user/delete-user-comment" },
  ({ data, payload }) => {
    showToast("success", data.message, 2);
  }
);

export const commentThunks = {
  getUserCommentsList,
  addUserComment,
  deleteUserComment,
};
