import React from "react";
import { List, Typography, Spin, Avatar } from "antd";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { stringUtils } from "../../utils/utilities";
import { ROUTES } from "../../routes/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";

const { Title, Text } = Typography;

const PlaylistSongsSection = ({ playlistSongs, isLoading }) => {
  const navigate = useNavigate();
  const tableData = playlistSongs?.song_data?.map((song) => ({
    ...song,
    song: song,
  }));

  const isEmpty =
    tableData?.length === 0 ||
    (tableData?.length === 1 && Object.keys(tableData.length == 0));

  return (
    <Container>
      <Title level={1}>Songs</Title>

      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spin tip="Loading..." size="large" />
        </div>
      ) : isEmpty ? (
        <Text type="secondary">Playlist is empty</Text>
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={tableData}
          renderItem={(song, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <div className="d-flex align-items-center">
                    <Text strong className="mx-2">
                      #{index + 1}
                    </Text>
                    <Avatar
                      shape="square"
                      src={song?.song_image_link}
                      size={50}
                    />
                  </div>
                }
                title={
                  <div className="d-flex align-items-center">
                    <Text
                      strong
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          generateDynamicRoute(ROUTES.MAIN.LYRICS, {
                            songName: _.kebabCase(song?.song_name),
                            isrcKey: song?.isrc_code,
                          })
                        )
                      }
                    >
                      {song.song_name || "Song Title"}
                    </Text>
                  </div>
                }
                description={
                  <div className="d-flex justify-content-between">
                    <Text
                      type="secondary"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                            artistName: _.kebabCase(song?.artist_name),
                            artistId: song?.artist_id,
                          })
                        )
                      }
                    >
                      {song.artist_name}
                    </Text>
                    <br />
                    <Text
                      type="secondary"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          generateDynamicRoute(ROUTES.MAIN.ALBUM_SONGS, {
                            albumName: _.kebabCase(song?.album_name),
                            albumId: song?.album_id,
                          })
                        )
                      }
                    >
                      {stringUtils.sanitizeName(song.album_name)}
                    </Text>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      )}
    </Container>
  );
};

export default PlaylistSongsSection;
