import React, { useEffect, useState } from "react";
import { Button, Tag, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaRegCircleCheck,
  FaArrowLeft,
  FaRegCircleXmark,
} from "react-icons/fa6";

import { ROUTES } from "../../../routes/constants";
import MainLayout from "../../../layouts/MainLayout";
import { authController } from "../../../features/auth/authSlice";
import SEOManager from "../../../utils/SEOManager";
import { getConfig } from "../../../services/apiUtils";
import TopBar from "../../../components/_buttons/TopBar";

function VerifyUserPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  const { verifyToken } = useParams();
  const [verificationResult, setVerificationResult] = useState(null); // null | true | false

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const { status } = await dispatch(
          authController.verifyUser({
            payload: { token: verifyToken },
            config: getConfig(),
            navigate,
          })
        ).unwrap();

        if (status === 200) {
          setVerificationResult(true); // Success
        } else {
          setVerificationResult(false); // Failure
        }
      } catch (error) {
        console.error("Error verifying user:", error);
        setVerificationResult(false);
      }
    };

    verifyUser();
  }, [verifyToken, dispatch, navigate]);

  return (
    <MainLayout>
      <SEOManager
        title="Verify Your Account - LyricsWeb"
        ogUrl={`www.lyricsweb.com/auth/verify/${verifyToken}`}
      />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <Row className="m-0 p-0">
          <Col lg={2} md={2} sm={0} xs={0}></Col>
          <Col lg={8} md={8} sm={12} xs={12}>
            {/* Show Spinner while loading */}
            {verificationResult === null && (
              <div className="text-center">
                <Spin size="large" tip="Verifying your account..." />
              </div>
            )}

            {/* Render success message */}
            {verificationResult === true && (
              <div className="text-center">
                <div className="d-flex justify-content-center mb-3">
                  <Tag className="shadow px-3 py-2 rounded-3">
                    <FaRegCircleCheck />
                  </Tag>
                </div>
                <h3 className="fw-bold">Account Verified Successfully</h3>
                <p className="mt-3">
                  Your account has been verified. You can now log in and access
                  your account.
                </p>
                {/* <Button
                  variant="solid"
                  color="default"
                  className="fw-bold"
                  size="large"
                  onClick={() => navigate(ROUTES.AUTH.LOGIN)}
                >
                  <FaArrowLeft className="me-2" />
                  Back to Login
                </Button> */}
              </div>
            )}

            {/* Render failure message */}
            {verificationResult === false && (
              <div className="text-center">
                <div className="d-flex justify-content-center mb-3">
                  <Tag className="shadow px-3 py-2 rounded-3">
                    <FaRegCircleXmark />
                  </Tag>
                </div>
                <h3 className="fw-bold">Verification Failed</h3>
                <p className="mt-3">
                  We could not verify your account. This may happen if your
                  verification link has expired or is invalid. Please try again
                  or contact support for assistance.
                </p>
                <Button
                  variant="solid"
                  color="default"
                  className="fw-bold"
                  size="large"
                  onClick={() => navigate(ROUTES.AUTH.REQUEST_VERIFICATION)}
                >
                  Resend Verification Email
                </Button>
              </div>
            )}
            {/* Common "Back to Login" Button */}
            <div className="d-flex justify-content-center align-items-center">
              <Button
                type="text"
                className="fw-bold"
                size="large"
                onClick={() => navigate(ROUTES.AUTH.LOGIN)}
              >
                <FaArrowLeft className="me-2" />
                Back to Login
              </Button>
            </div>
          </Col>
          <Col lg={2} md={2} sm={0} xs={0}></Col>
        </Row>
      </Container>
    </MainLayout>
  );
}

export default VerifyUserPage;
