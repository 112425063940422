import React from 'react'
import { HiMiniSpeakerWave } from "react-icons/hi2";
import { Button, Col, Container, Row, Alert } from "react-bootstrap";
import { Card } from 'react-bootstrap';

import { FaTelegramPlane } from "react-icons/fa";
const QuizCard = () => {
  return (
    <div className=''>
      <Card style={{ backgroundColor: '#0C091E', color: '#fff', borderRadius: '18px', padding: '22px 22px 0 22px;' }}>
        <Button
          style={{
            backgroundColor: '#ff007f',
            border: 'none',
            borderRadius: '8px',
            padding: '5px 15px',
            fontSize: '14px',
            position: 'absolute',
            top: '15px',
            left: '15px',
          }}
        >
          <FaTelegramPlane size={20} color="white" className="me-2"/><span className="text-light">Share</span>
        </Button>
        <Card.Body className="mt-4" style={{ padding: '33px 42px 15px 42px' }}>
          <Card.Title style={{ fontSize: '24px' }} className='mt-3 fw-bold'>The Lyrics Quiz</Card.Title>
          <Card.Text style={{ fontSize: '16px', marginTop: '15px' }} className="mb-5">
            Are you a music master? Dare to take the ultimate music challenge? Don’t be so shy —
            Our Fun Music Quiz presents questions and answers on old and modern music to test your knowledge.
          </Card.Text>
          <div style={{ marginTop: '20px' }} className='d-flex justify-content-between'>
            <span style={{ fontSize: '14px' }}>Sound: <HiMiniSpeakerWave size={20} /></span>
            <span style={{ fontSize: '14px' }}>Time: 26:35</span>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default QuizCard
