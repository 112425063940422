import React from "react";
import { Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsMusicNoteList } from "react-icons/bs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { stringUtils } from "../../utils/utilities";
import { StaticImages } from "../../utils/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";
import { formatStringType } from "../../utils/helpers";

function AlbumCard({ album, index }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="rounded-4 p-2 bg-white overflow-hidden shadow-sm">
        {/* Image of the album */}
        <Image
          src={album.image_link || StaticImages.App.AppDefaultS}
          alt={album.album_name}
          className="w-100 rounded rounded-4"
          style={{ objectFit: "cover", height: "200px" }} // Set a fixed height for the image
        />

        {/* Content below the image */}
        <div className="p-3">
          {/* Album Name with Overlay Trigger */}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-${album.id}`} className="text-white">
                {stringUtils.sanitizeName(album.album_name)}
              </Tooltip>
            }
          >
            <div className="position-relative mb-2">
              <h6
                className="fw-bold mb-0 text-truncate"
                style={{
                  whiteSpace: "nowrap", // Prevent text wrapping
                  overflow: "hidden", // Ensure text doesn't overflow
                  textOverflow: "ellipsis", // Show ellipsis if the text is too long
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    generateDynamicRoute(ROUTES.MAIN.ALBUM_SONGS, {
                      albumName: formatStringType(
                        album.album_name,
                        "kebabCase"
                      ),
                      albumId: album.album_id,
                    })
                  )
                }
              >
                {stringUtils.sanitizeName(album.album_name)}
              </h6>
            </div>
          </OverlayTrigger>

          {/* View Album Button with Hover Effect */}

          <Button
            as={motion.button}
            variant="eigengrau"
            className="fw-bold"
            size="sm"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
            }}
            onClick={() =>
              navigate(
                generateDynamicRoute(ROUTES.MAIN.ALBUM_SONGS, {
                  albumName: formatStringType(album.album_name, "kebabCase"),
                  albumId: album.album_id,
                })
              )
            }
          >
            <BsMusicNoteList className="me-2" />
            View Album
          </Button>
        </div>
      </div>
    </>
  );
}

export default AlbumCard;
