import { createApiThunk } from "../../utils/thunkUtils";

/* Define thunks for each API endpoint */
export const getTrendingArtists = createApiThunk(
  "artist/getTrendingArtists",
  {
    method: "GET",
    endpoint: "/user/get-trending-artists",
  },
  ({ data }) => {},
  (error) => {}
);

export const getRecommendedArtists = createApiThunk(
  "artist/getRecommendedArtists",
  { method: "GET", endpoint: "/user/get-recommended-artists" },
  ({ data }) => {},
  (error) => {}
);

export const getTopArtists = createApiThunk(
  "artist/getTopArtists",
  { method: "GET", endpoint: "/user/get-top-artists" },
  ({ data }) => {},
  (error) => {}
);

export const getAllArtists = createApiThunk(
  "artist/getAllArtists",
  { method: "GET", endpoint: "/user/get-all-artist" },
  ({ data }) => {},
  (error) => {}
);

export const getFavouriteArtists = createApiThunk(
  "artist/getFavouriteArtists",
  { method: "GET", endpoint: "/user/get-favourite-artist" },
  ({ data }) => {},
  (error) => {}
);

export const getArtistsByLetter = createApiThunk(
  "artist/getArtistsByLetter",
  { method: "GET", endpoint: "/user/get-artists-by-letter" },
  ({ data }) => {},
  (error) => {}
);

export const getArtistBioDetails = createApiThunk(
  "artist/getArtistBioDetails",
  { method: "GET", endpoint: "/user/get-artist-bio-with-top" },
  ({ data }) => {},
  (error) => {}
);

export const artistThunks = {
  getTrendingArtists,
  getRecommendedArtists,
  getTopArtists,
  getAllArtists,
  getFavouriteArtists,
  getArtistsByLetter,
  getArtistBioDetails,
};
