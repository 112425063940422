import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { motion } from "framer-motion";

import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { StaticImages } from "../../../utils/constants";
import styles from "./QuizPage.module.css";
import { quizController } from "../../../features/quiz/quizSlice";
import { getAuthConfig, getConfig } from "../../../services/apiUtils";
import { MdArrowForward } from "react-icons/md";
import ViewLyricsCommentSection from "../../../components/_sections/ViewLyricsCommentSection";
import QuizeCommentSection from "./QuizeCommentSection";
import QuizCard from "./QuizCard";
import { getQuiz } from "../../../services/apiServices";

function QuizPage() {
  const dispatch = useDispatch();
  const { quiz, isLoading, error } = useSelector((state) => state.quiz);

  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isAnswerChecked, setIsAnswerChecked] = useState(false);
  const [quizId,setQuizId] = useState("");

 

  // const fetchQuestion = async (questionIndex) => {
  //   try {
  //     const result = await dispatch(
  //       quizController.getQuiz({
  //         payload: { genre: "all" },
  //         config: getConfig(),
  //       })
  //     ).unwrap();
  //     setCurrentQuestion(quiz);
  //     setSelectedAnswer(null);
  //     setIsAnswerChecked(false);
  //   } catch (error) {
  //     console.error("Failed to fetch question:", error);
  //   }
  // };

const fetchQuestion = async () =>{
  try {
    const payload = {
       genre:"all"
    }
    const config = getAuthConfig();
    const response = await getQuiz(payload,config)
    if(response.success){
      setCurrentQuestion(response.data);
      setSelectedAnswer(null);
      setIsAnswerChecked(false);
    }
  } catch (error) {
    console.error("Failed to fetch question:",error)
  }
}


useEffect(() => {
  fetchQuestion();
}, []);

  console.log(currentQuestion,"hlllllllllllllll");
  const handleAnswerSelect = (selectedOption) => {
    setSelectedAnswer(selectedOption);
    setIsAnswerChecked(true);
  };

  const handleNextQuestion = () => {
    const nextIndex = currentQuestionIndex + 1;
    setCurrentQuestionIndex(nextIndex);
    fetchQuestion(nextIndex);
  };

  const renderOptions = () => {
    if (!currentQuestion) return null;

    const { correctAnswer } = currentQuestion;

    return ["A", "B", "C", "D"].map((option, index) => {
      const optionKey = `option${option}`;
      const isCorrect = option === correctAnswer;
      const isSelected = option === selectedAnswer;
      const showCorrectGreen = isAnswerChecked && isCorrect;
      const showIncorrectRed = isAnswerChecked && isSelected && !isCorrect;

      return (
        <motion.div
          key={index}
          whileHover={{
            backgroundColor: "white",
            color: "black",
            scale: 1.02, // Slight scaling effect
            transition: { duration: 0.3 }, // Smooth transition
          }}
          whileTap={{ scale: 0.98 }} // Add tap effect
          style={{
            backgroundColor: showCorrectGreen
              ? "#32FF78"
              : showIncorrectRed
              ? "#FF4081"
              : "transparent",
            color: showCorrectGreen || showIncorrectRed ? "white" : "inherit",
            border: "2px solid cornflowerblue",
            borderRadius: "8px",
            padding: "10px",
            width: "100%",
          }}
        >
          <Button
            variant="none" // No variant styling to avoid conflicts
            className={`w-100`}
            onClick={() => handleAnswerSelect(option)}
            disabled={isAnswerChecked}
            style={{
              background: "transparent",
              border: "none",
              padding: "0",
              color: "inherit",
            }}
          >
            {currentQuestion[optionKey]}
          </Button>
        </motion.div>
      );
    });
  };

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <div className="m-2">
          <div
            className={`${styles.quizContainer} position-relative bg-sliceHeaven rounded-4`}
            style={{
              backgroundImage: `url(${StaticImages.Utils.MusicMasterQuizBg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "50% auto",
              backgroundPosition: "left center",
            }}
          >
            <div className={`${styles.quizContent} p-5`}>
              <Row className="m-0 p-0">
                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className="text-white">
                    <h2 className="fw-bold">Quizzes</h2>
                    <p>Which quiz would you take next?</p>
                  </div>
                  {Array.from({ length: 8 }, (_, index) => (
                    <div key={index} className="my-2">
                      <Button
                        variant="outline-opulentBlue"
                        className={`w-100 text-white border-cornflowerBlue border-2 rounded-4`}
                      >
                        Quiz Type {index + 1}
                      </Button>
                    </div>
                  ))}
                </Col>
                <Col lg={8} md={8} sm={12} xs={12}>
                  {/* Header */}
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="fw-bold text-white">Let's Play a Quiz</h2>
                    <Button
                      variant="hotPink"
                      className="text-white rounded-pill px-4"
                    >
                      Advance Quiz
                    </Button>
                  </div>

                  {isLoading ? (
                    <Row className="my-5">
                      <Col className="p-4">
                        <Spin tip="Loading..." size="large" />
                      </Col>
                    </Row>
                  ) : error ? (
                    <Row className="my-5">
                      <Col className="p-4">
                        <Alert variant="danger">
                          Error fetching quiz data.
                        </Alert>
                      </Col>
                    </Row>
                  ) : currentQuestion ? (
                    <Row className="my-5">
                      <Col className="p-4">
                        <p className={`fw-bold text-white`}>
                          Question {currentQuestionIndex + 1}
                        </p>
                        <h3 className="text-white mb-3">
                          {currentQuestion.question}
                        </h3>
                      </Col>
                      <Col className="p-4">
                        <Row className="g-3">{renderOptions()}</Row>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}

                  <div className="d-flex justify-content-end">
                    <Button
                      variant="light"
                      className={`text-sliceHeaven fw-bold rounded-4 px-4 py-2`}
                      onClick={handleNextQuestion}
                      disabled={!currentQuestion || isLoading}
                    >
                      Next Question <MdArrowForward className="ms-2 fw-bold" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div>
          <Row className="p-4">
            <Col sm={12} md={4} className="mb-3">
              <QuizCard />
            </Col>
            <Col sm={12} md={8}>
              <QuizeCommentSection currentQuestion={currentQuestion} />
            </Col>
          </Row>
        </div>
      </Container>
    </MainLayout>
  );
}

export default QuizPage;

{
  /*  */
}
