import React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

import AppDefaultBtn from "../../../components/_buttons/AppDefaultBtn";
import appStyles from "../../../App.module.css";
import { ROUTES } from "../../../routes/constants";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";

function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <>
      <MainLayout>
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>

          <div className="d-flex justify-content-center align-items-center pt-5">
            <AppDefaultBtn
              btnTxt="Back to Home"
              onClick={() => navigate(ROUTES.MAIN.HOME)}
              className={`${appStyles.appDefaultBtn} w-50`}
            />
          </div>
        </Container>
      </MainLayout>
    </>
  );
}

export default NotFoundPage;
