import React, { useEffect } from "react";
import { Alert, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { StaticImages } from "../../utils/constants";
import { playlistController } from "../../features/playlist/playlistSlice";
import { getConfig } from "../../services/apiUtils";
import PlaylistCard from "../_cards/PlaylistCard";
import { ErrorAlert } from "../_alerts/UIAlerts";

function TopPlaylists({ display = 4 }) {
  const dispatch = useDispatch();

  const { adminPlaylists, isLoading, error } = useSelector(
    (state) => state.playlist
  );

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        await dispatch(
          playlistController.getAllAdminPlaylists({
            payload: {},
            config: getConfig(),
          })
        ).unwrap();
      } catch (error) {
        console.error("Failed to fetch trending playlists:", error);
      }
    };

    fetchPlaylists();
  }, [dispatch]);

  const playlistImages = [
    StaticImages.Album.Img1,
    StaticImages.Album.Img2,
    StaticImages.Album.Img3,
    StaticImages.Album.Img4,
  ];

  const displayedPlaylists = adminPlaylists?.slice(0, display) || [];

  return (
    <>
      <div className="my-4">
        <div className="my-4">
          <h1 className="fw-bold">Top Playlists</h1>
        </div>

        <Row className="g-4 m-0 p-0">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : error ? (
            <>
              <ErrorAlert description={error} />
            </>
          ) : displayedPlaylists.length > 0 ? (
            <Row className="g-4 bg-ghostWhite m-0 p-0">
              {displayedPlaylists.map((playlist, index) => {
                const randomImage =
                  playlistImages[
                    Math.floor(Math.random() * playlistImages.length)
                  ];

                return (
                  <Col key={playlist.id || index} lg={3} md={4} sm={6} xs={6}>
                    <PlaylistCard
                      playlist={playlist}
                      index={index}
                      randomImage={randomImage}
                      type="card"
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <p className="text-center">
              No trending playlists available at the moment.
            </p>
          )}
        </Row>
      </div>
    </>
  );
}

export default TopPlaylists;
