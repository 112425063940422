// src/constants.js

export const ROUTES = {
  AUTH: {
    LOGIN: "/auth/login",
    LOGOUT: "/auth/logout",
    SIGNUP: "/auth/signup",
    FORGOT_PASSWORD: "/auth/forgot-password",
    PROFILE: "/auth/profile",
    PROFILE_PREVIEW: "/auth/profile-preview",
    VERIFY: "/auth/verify/:verifyToken",
    RESET_PASSWORD: "/auth/reset-password/:resetToken",
  },
  MAIN: {
    HOME: "/",
    HOME_: "/home",
    ABOUT_US: "/about-us",
    CONTACT_US: "/contact-us",
    HELP: "/help",
    PRIVACY_POLICY: "/privacy-policy",
    TERMS_OF_USE: "/terms-of-use",
    NEWS: "/news",
    GENRES: "/genres",
    PLAYLISTS: "/playlists",
    ALBUMS: "/albums",
    SONGS: "/songs",
    ARTISTS: "/artists",
    MAINTENANCE: "/maintenance",
    SEARCH_RESULT: "/search-result",
    QUIZ: "/quiz",
    // user routes
    USER_PLAYLISTS: "/user/my-playlists",
    ADD_NEW_SONG_LYRICS: "/add-new-song-lyrics",
    // dynamic routes
    SEARCH_PAGE: "/search/:searchQuery?",
    FILTER_ARTISTS: "/artist/:artistLetter",
    FILTER_GENRES: "/genre/:genreName",
    NEWS_DETAILS: "/news/:newsId",
    VIEW_SONG_LYRICS: "/lyrics/:songName?/:isrcKey?",
    VIEW_ARTIST_BIO: "/artist/:artistName?/:artistId?",
    PLAYLIST_SONGS: "/playlist/:playlistName?/:playlistId?",
    USER_PLAYLIST_SONGS: "/user/playlist/:playlistName?/:playlistId?",
    ALBUM_SONGS: "/album/:albumName?/:albumId?",
    LYRICS: "/lyrics/:songName?/:isrcKey?",
    NOT_FOUND: "*",
  },
};
