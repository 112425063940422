import { showToast } from "../../components/_toasts/notificationUtils";
import { createApiThunk } from "../../utils/thunkUtils";

export const getGenres = createApiThunk(
  "config/getGenres",
  { method: "GET", endpoint: "/user/get-genres" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  }
);

export const submitContactUs = createApiThunk(
  "config/submitContactUs",
  { method: "POST", endpoint: "/user/contact-us" },
  ({ data, resetForm }) => {
    showToast("success", data.message, 2);
    resetForm();
  },
  ({ data }) => {
    showToast("success", data.message, 2);
  }
);

export const configThunks = {
  getGenres,
  submitContactUs,
};
