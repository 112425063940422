import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Image, Row } from "react-bootstrap";

import MainLayout from "../../../layouts/MainLayout";
import { StaticImages } from "../../../utils/constants";

import MostPopularSongs from "../../../components/_sections/MostPopularSongs";
import TrendingPlaylists from "../../../components/_sections/TrendingPlaylists";
import TrendingArtists from "../../../components/_sections/TrendingArtists";
import TopExploreAlbums from "../../../components/_sections/TopExploreAlbums";
import MusicMasterQuiz from "../../../components/_sections/MusicMasterQuiz";
import RecommendedSongs from "../../../components/_sections/RecommendedSongs";
import FeaturedMusicVideos from "../../../components/_sections/FeaturedMusicVideo";
import LatestMusicNews from "../../../components/_sections/LatestMusicNews";
import FollowUs from "../../../components/_sections/FollowUs";
import Banner from "../../../components/_cards/Banner";
import TrendingPodcasts from "../../../components/_sections/TrendingPodcasts";
import TopBar from "../../../components/_buttons/TopBar";

function HomePage() {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div className="m-2">
          <Row className="d-flex align-items-stretch">
            <Col lg={8} md={8} sm={12} xs={12} className="d-flex flex-column">
              <Banner
                src={StaticImages.Banner.AppHome}
                alt="Home Banner"
                className="custom-class flex-grow-1"
              />
              <div className="my-4 flex-grow-1">
                <Image
                  fluid
                  src={StaticImages.GoogleAds.DesktopAd_H_small}
                  className="w-100 rounded-3 d-lg-block d-none"
                  alt="Home Banner"
                />
                <Image
                  fluid
                  src={StaticImages.GoogleAds.MobileAd}
                  className="w-100 rounded-3 d-lg-none"
                  alt="Home Banner"
                />
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="d-flex flex-column">
              <MostPopularSongs display={6} heading={6} viewMode="list" />
            </Col>
          </Row>
        </div>

        {/* Add margin for small screens */}
        <div className="m-2 m-sm-3">
          <TrendingPlaylists />
        </div>
        <div className="m-2 m-sm-3">
          <TrendingArtists mode="dark" />
        </div>
        <div className="m-2 m-sm-3">
          <TopExploreAlbums />
        </div>
        <div className="m-2 m-sm-3">
          <MusicMasterQuiz />
        </div>
        <div className="m-2 m-sm-3">
          <RecommendedSongs />
        </div>

        <div className="m-2 m-sm-3">
          <Image
            fluid
            src={StaticImages.GoogleAds.DesktopAd_H_small}
            className="w-100 rounded-3"
            alt="Home Banner"
          />
        </div>

        <div className="m-2 m-sm-3">
          <FeaturedMusicVideos />
        </div>

        <div className="m-2 m-sm-3">
          <TrendingPodcasts />
        </div>

        <div className="m-2 m-sm-3">
          <LatestMusicNews />
        </div>

        <div className="m-2 m-sm-3">
          <Image
            fluid
            src={StaticImages.GoogleAds.DesktopAd_H_small}
            className="w-100 rounded-3"
            alt="Home Banner"
          />
        </div>

        <div className="m-2 m-sm-3">
          <FollowUs />
        </div>

        <div className="m-2 m-sm-3">
          <Image
            fluid
            src={StaticImages.GoogleAds.DesktopAd_H_small}
            className="w-100 rounded-3"
            alt="Home Banner"
          />
        </div>
      </Container>
    </MainLayout>
  );
}

export default HomePage;
