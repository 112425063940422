// utils/thunkUtils.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../services/apiRequests";
import { handleApiError } from "../services/apiUtils";

export const createApiThunk = (
  type,
  requestConfig,
  onSuccess = () => { },
  onFailure = () => { }
) =>
  createAsyncThunk(type, async (args, { rejectWithValue }) => {
    try {
      const { payload, config, navigate, resetForm } = args;
      const { method, endpoint } = requestConfig;

      // Make the API request
      const response = await makeRequest(method, endpoint, payload, config);

      // Ensure the response is valid
      if (response && response.status >= 200 && response.status < 300) {
        const { data } = response; // Safely destructure 'data'
        onSuccess({ data, navigate, resetForm });
        return data;
      } else {
        // Handle unexpected response structure or status codes
        const errorMessage =
          response?.data?.message || "Unexpected error occurred.";
        onFailure(response?.data || {});
        return rejectWithValue(errorMessage);
      }
    } catch (error) {
      // console.error("API Error:", error);

      handleApiError(error);
      onFailure(error);
      return rejectWithValue(error.message);
    }
  });
