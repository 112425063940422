import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { StaticImages } from "../../../utils/constants";
import PlaylistSongsSection from "../../../components/_sections/PlaylistSongsSection";
import { playlistController } from "../../../features/playlist/playlistSlice";
import { getConfig } from "../../../services/apiUtils";

function PlaylistSongsPage() {
  const dispatch = useDispatch();
  const { playlistId, playlistName } = useParams();

  const { playlistSongs, isLoading, error } = useSelector(
    (state) => state.playlist
  );

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        await dispatch(
          playlistController.getPlaylistSongs({
            payload: { playlist_id: playlistId },
            config: getConfig(),
          })
        ).unwrap();
      } catch (error) {
        console.error("Failed to fetch playlists songs:", error);
      }
    };

    fetchPlaylists();
  }, [dispatch, playlistId]);

  console.log(playlistSongs);

  return (
    <>
      <MainLayout>
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>

          <div className="m-2">
            <div className="my-2">
              <Image
                fluid
                src={
                  StaticImages.Pages.PlaylistCover ||
                  StaticImages.Pages.PlaylistCover
                }
                width={"100%"}
                className="rounded-4"
                alt="Home Banner"
              />
            </div>
            <Row>
              <Col lg={8} md={8} sm={12} xs={12}>
                <PlaylistSongsSection
                  playlistSongs={playlistSongs}
                  isLoading={isLoading}
                />
              </Col>
              <Col lg={4} md={4} sm={12} xs={12} className="d-none d-lg-block">
                <Image
                  fluid
                  src={StaticImages.GoogleAds.DesktopAd_V}
                  className="w-100 rounded-3"
                  alt="Home Banner"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </MainLayout>
    </>
  );
}

export default PlaylistSongsPage;
