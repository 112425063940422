import { createSlice } from "@reduxjs/toolkit";
import { lyricsThunks } from "./lyricsThunks";

const initialState = {
  lyrics: null,
  lyricsMeaning: null,
  myLyricsSongs: null,
  isLoading: false,
  error: null,
};

/* Lyrics Section */
const lyricsSlice = createSlice({
  name: "lyrics",
  initialState,
  reducers: {
    resetLyrics: (state) => {
      state.lyrics = null;
    },
    resetLyricsMeaning: (state) => {
      state.lyricsMeaning = null;
    },
  },
  extraReducers: (builder) => {
    // Generic handlers for pending, fulfilled, and rejected states
    const handlePending = (state) => {
      state.isLoading = true;
      state.error = null;
    };

    const handleRejected = (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    };

    const handleFulfilled = (state, action, key) => {
      state.isLoading = false;
      state[key] = action.payload.data;
    };

    builder
      // Add cases for lyrics thunks
      .addCase(lyricsThunks.getSongLyrics.pending, handlePending)
      .addCase(lyricsThunks.getSongLyrics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lyrics = action.payload.data;
        state.lyricsMeaning = null;
      })
      .addCase(lyricsThunks.getSongLyrics.rejected, handleRejected)
      // Add cases for lyrics meaning thunks
      .addCase(lyricsThunks.getSongLyricsMeaning.pending, handlePending)
      .addCase(lyricsThunks.getSongLyricsMeaning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lyricsMeaning = action.payload.data;
      })
      .addCase(lyricsThunks.getSongLyricsMeaning.rejected, handleRejected)
      // Add cases for my lyrics thunks
      .addCase(lyricsThunks.getMyLyricsSongs.pending, handlePending)
      .addCase(lyricsThunks.getMyLyricsSongs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.myLyricsSongs = action.payload.data;
      })
      .addCase(lyricsThunks.getMyLyricsSongs.rejected, handleRejected);
  },
});

/* Export actions and thunks */
export const { resetLyrics, resetLyricsMeaning } = lyricsSlice.actions;

export const lyricsController = lyricsThunks;

export default lyricsSlice.reducer;
