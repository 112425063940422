import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Typography } from "antd";

import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { StaticImages } from "../../../utils/constants";
import { getConfig } from "../../../services/apiUtils";
import { albumController } from "../../../features/album/albumSlice";
import AlbumSongsSection from "../../../components/_sections/AlbumSongsSection";
import { formatStringType } from "../../../utils/helpers";

const { Text, Title } = Typography;

function AlbumSongsPage() {
  const dispatch = useDispatch();
  const { albumId, albumName } = useParams();

  const { albumSongs, isLoading, error } = useSelector((state) => state.album);

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        await dispatch(
          albumController.getAlbumSongs({
            payload: { album_id: albumId },
            config: getConfig(),
          })
        ).unwrap();
      } catch (error) {
        console.error("Failed to fetch album songs:", error);
      }
    };

    fetchPlaylists();
  }, [dispatch, albumId]);

  let albumData = {};

  if (albumSongs && albumSongs.length > 0) {
    albumData = albumSongs[0];
  }

  return (
    <>
      <MainLayout>
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>

          <div className="m-2">
            <div className="my-5">
              <div
                className="bg-eigengrau rounded-4 p-5"
                style={{ position: "relative" }}
              >
                {isLoading ? (
                  <div className="d-flex justify-content-center">
                    <Spin size="large" spinning={isLoading} />
                  </div>
                ) : (
                  <div>
                    <Row className="m-0 p-0">
                      <Col lg={8} md={8} sm={8} xs={8}>
                        <h1 className="text-white fw-bold">
                          {formatStringType(albumData?.album_name)}
                        </h1>
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={4}>
                        <Image
                          fluid
                          height={200}
                          width={200}
                          src={albumData?.image_link}
                          className="rounded-4"
                          alt="Album Cover Art"
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
              <div className="my-4">
                <Row className="align-items-center">
                  <Col xs={12} md={4} className="text-center text-md-start">
                    <Image
                      height={150}
                      width={150}
                      roundedCircle
                      src={
                        albumData?.artist?.artist_image_link ||
                        StaticImages.Album.Img1
                      }
                      alt="Artist Cover Art"
                      className="shadow-lg"
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={8}
                    className="mt-3 mt-md-0 text-center text-md-start"
                  >
                    <Title level={2} className="fw-bold">
                      {formatStringType(albumData?.artist?.artist_name)}
                    </Title>
                    <Text>
                      {albumData?.artist?.bio ||
                        "A passionate artist creating beautiful music."}
                    </Text>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col lg={8} md={8} sm={12} xs={12}>
                {albumSongs && albumSongs.length > 0 && (
                  <AlbumSongsSection
                    albumSongs={albumData}
                    isLoading={isLoading}
                  />
                )}
              </Col>
              <Col lg={4} md={4} sm={12} xs={12} className="d-none d-lg-block">
                <Image
                  fluid
                  src={StaticImages.GoogleAds.DesktopAd_V}
                  className="w-100 rounded-3"
                  alt="Home Banner"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </MainLayout>
    </>
  );
}

export default AlbumSongsPage;
