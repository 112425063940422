import React from "react";
import { Container, Image } from "react-bootstrap";

import styles from "./ViewSongLyricsPage.module.css";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { StaticImages } from "../../../utils/constants";
import ViewLyricsSection from "../../../components/_sections/ViewLyricsSection";

function ViewSongLyricsPage() {
  return (
    <MainLayout className={styles.printStyles_body}>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div className="m-2">
          <Image
            fluid
            src={StaticImages.GoogleAds.DesktopAd_H_large}
            className="w-100 rounded-3"
            alt="Home Banner"
          />
        </div>

        <div id="noPrint" className={`m-2 ${styles.printStyles_noPrint}`}>
          <ViewLyricsSection />
        </div>
      </Container>
    </MainLayout>
  );
}

export default ViewSongLyricsPage;
