import React, { useEffect } from "react";
import { Button, Row, Spinner, Col } from "react-bootstrap";
import { MdArrowOutward } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { getAuthConfig } from "../../services/apiUtils";
import ArtistCard from "../_cards/ArtistCard";
import { ErrorAlert } from "../_alerts/UIAlerts";
import { lyricsController } from "../../features/lyrics/lyricsSlice";
import SongCard from "../_cards/SongCard";

function MyLyrics({ display = 4, mode = "dark" }) {
  const dispatch = useDispatch();

  const { myLyricsSongs, isLoading, error } = useSelector(
    (state) => state.lyrics || {}
  );

  useEffect(() => {
    dispatch(
      lyricsController.getMyLyricsSongs({
        payload: {},
        config: getAuthConfig(),
      })
    ).catch((error) => console.error("Failed to fetch my lyrics:", error));
  }, [dispatch]);

  return (
    <div>
      <div className="my-4">
        <div className="my-4">
          <h1 className="fw-bold">My Lyrics</h1>
        </div>

        <Row className="g-4 m-0 p-0">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : error ? (
            <>
              <ErrorAlert description={error} />
            </>
          ) : myLyricsSongs?.length > 0 ? (
            <Row className="g-4 bg-ghostWhite m-0 p-0">
              {myLyricsSongs?.map((song, index) => (
                <>
                  <Col lg={6} md={6} sm={12} xs={12} key={index}>
                    <SongCard
                      viewMode="myLyricsList"
                      song={song}
                      index={index}
                    />
                  </Col>
                </>
              ))}

              <div className="ms-auto d-flex align-items-center float-end">
                <span className="fw-bold">See More</span>
                <Button
                  variant="eigengrau"
                  size="sm"
                  className="fw-bold fs-6 mx-2 rounded-circle"
                >
                  <MdArrowOutward />
                </Button>
              </div>
            </Row>
          ) : (
            <p className="text-center">No my lyrics available at the moment.</p>
          )}
        </Row>
      </div>
    </div>
  );
}

export default MyLyrics;
