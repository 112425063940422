import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import { Avatar, Spin } from "antd";
import { FaInstagram, FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SEOManager from "../../../utils/SEOManager";
import MainLayout from "../../../layouts/MainLayout";
import { StaticImages } from "../../../utils/constants";
import TopBar from "../../../components/_buttons/TopBar";
import { newsController } from "../../../features/news/newsSlice";
import { getConfig } from "../../../services/apiUtils";

const NewsDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { newsId } = useParams();

  const formatLyrics = (text) => {
    if (!text) return "";
    return text.replace(/\n/g, "<br />");
  };

  const {
    specificNews = {},
    isLoading,
    error,
  } = useSelector((state) => state.news);

  useEffect(() => {
    if (newsId) {
      dispatch(
        newsController.getNewsDetails({
          payload: { newsId },
          config: getConfig(),
        })
      ).catch((error) => {
        console.error("Failed to fetch news:", error);
      });
    }
  }, [dispatch, newsId]);

  // Fallback content
  const fallbackImage = "/path/to/placeholder/image.jpg";
  const defaultDescription = "No description available for this news item.";

  return (
    <MainLayout>
      <SEOManager
        title={`News Details - ${specificNews?.title || "News"}`}
        ogUrl={`www.lyricsweb.com/news/${newsId || ""}`}
      />

      <Container fluid className="m-0 p-0">
        {/* Top Navigation Bar */}
        <div className="m-2">
          <TopBar />
        </div>

        {/* Main Content */}
        <div className="my-4 h-100">
          <Row className="m-0 p-0">
            {/* Left Column: News Details */}
            <Col lg={8} md={8} sm={12} xs={12} className="d-flex flex-column">
              {isLoading ? (
                <div
                  style={{ height: "50vh" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Spin tip="Loading..." size="large" />
                </div>
              ) : error ? (
                <div className="alert alert-danger text-center my-5">
                  Failed to load the news. Please try again later.
                </div>
              ) : (
                <>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Image
                      src={specificNews?.coverImg || fallbackImage}
                      alt={specificNews?.title || "News Image"}
                      fluid
                      className="rounded-3"
                    />
                  </motion.div>

                  <div className="my-4">
                    <h1 className="fw-bold">
                      {specificNews?.title || "Untitled News"}
                    </h1>

                    {/* Meta Information */}
                    <div className="d-flex flex-column flex-lg-row align-items-start my-2">
                      {/* Date */}
                      <div className="d-flex align-items-center mb-2 mb-lg-0 me-lg-4">
                        <FaRegCalendarAlt className="me-2" />
                        {specificNews?.publishDate
                          ? new Date(
                            specificNews.publishDate
                          ).toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })
                          : "N/A"}
                      </div>

                      {/* Read Time */}
                      <div className="d-flex align-items-center mb-2 mb-lg-0 me-lg-4">
                        <FaRegClock className="me-2" />
                        {specificNews?.readTime
                          ? `${specificNews.readTime} minutes read`
                          : "Read time not available"}
                      </div>

                      {/* Instagram */}
                      <div className="d-flex align-items-center">
                        <FaInstagram className="me-2" />
                        Instagram
                      </div>
                    </div>

                    {/* Author Information */}
                    <div className="d-flex align-items-center my-3">
                      <Avatar size={50} className="me-2" />
                      <span>
                        By{" "}
                        <strong>
                          {specificNews?.author || "Unknown Author"}
                        </strong>
                      </span>
                    </div>

                    {/* Description */}
                    <div className="fs-5 my-4"
                      dangerouslySetInnerHTML={{
                        __html: (specificNews?.description)
                      }} />
                    {/* {specificNews?.description || defaultDescription} */}

                    {/* </div> */}
                  </div>
                </>
              )}
            </Col>

            {/* Right Column: Advertisement */}
            <Col lg={4} md={4} sm={12} xs={12} className="d-none d-lg-block">
              <div className="m-2">
                <Image
                  fluid
                  src={StaticImages.GoogleAds.DesktopAd_V}
                  className="w-100 rounded-3"
                  alt="Advertisement"
                />
              </div>
            </Col>
          </Row>

          {/* Horizontal Advertisement */}
          <div className="m-2">
            <Image
              fluid
              src={StaticImages.GoogleAds.DesktopAd_H_large}
              className="w-100 rounded-3"
              alt="Advertisement"
            />
          </div>
        </div>
      </Container>
    </MainLayout>
  );
};

export default NewsDetailPage;
