// hooks/useErrorBoundary.js
import { useState } from "react";
import { showToast } from "../components/_toasts/notificationUtils";

const useErrorBoundary = () => {
  const [hasError, setHasError] = useState(false);

  const handleError = (error, errorInfo) => {
    // You can log the error to an external service here
    console.log("Error caught in useErrorBoundary:", error, errorInfo);
    setHasError(true);
    showToast("error", "Something went wrong", 2);
  };

  return { hasError, handleError };
};

export default useErrorBoundary;
