import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tag } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaArrowLeft, FaKey } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";

import { getConfig } from "../../../services/apiUtils";
import { authController } from "../../../features/auth/authSlice";
import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import { formFieldTypes } from "../../../utils/formFieldTypes";
import { validationSchemas } from "../../../utils/validationSchemas";
import TopBar from "../../../components/_buttons/TopBar";
import RenderField from "../../../components/_fields/RenderField";
import { ROUTES } from "../../../routes/constants";

function ForgotPasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.auth);

  const [email, setEmail] = useState("");
  const [currentView, setCurrentView] = useState("form");

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemas.forgotPasswordSchema),
    mode: "onChange",
  });

  const handleResetPassword = async (email) => {
    try {
      const { data, status } = await dispatch(
        authController.forgetPassword({
          payload: { email },
          config: getConfig(),
          resetForm: reset,
          navigate,
        })
      ).unwrap();

      console.log(data, status);

      if (status === 200) {
        setCurrentView("success");
        setEmail(email);
      }
    } catch (error) {
      console.error("Error Resetting Password:", error);
    }
  };

  const onSubmit = async (data) => {
    await handleResetPassword(data.email);
  };

  const handleResendEmail = async () => {
    await handleResetPassword(email);
  };

  const seoData = getSEOConfig("forgotPasswordPage");

  return (
    <MainLayout>
      <SEOManager {...seoData} />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>{" "}
        <Row className="m-0 p-0">
          <Col lg={2} md={2} sm={0} xs={0}></Col>
          <Col lg={8} md={8} sm={12} xs={12}>
            <div className="w-100 p-lg-5 p-md-3 p-sm-2">
              {currentView === "form" && (
                <>
                  <div className="d-flex justify-content-center py-2">
                    <Tag
                      bordered={false}
                      className="shadow px-3 py-2 rounded-3"
                    >
                      <FaKey />
                    </Tag>
                  </div>
                  <h3 className="my-4 fw-bold">Forgot Password?</h3>
                  <h6 className="my-4">
                    No worries, we’ll send you reset instructions.
                  </h6>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {formFieldTypes.forgotPasswordFields.map((field) => (
                      <RenderField
                        key={field.name}
                        field={field}
                        control={control}
                        errors={errors}
                      />
                    ))}

                    <Button
                      variant="solid "
                      color="default"
                      className="mt-3 fw-bold w-100"
                      htmlType="submit"
                      size="large"
                      loading={isLoading} // Disable while loading
                    >
                      Send Mail
                    </Button>
                  </form>
                </>
              )}

              {currentView === "success" && (
                <>
                  <div className="d-flex justify-content-center py-2">
                    <Tag
                      bordered={false}
                      className="shadow px-3 py-2 rounded-3"
                    >
                      <MdOutlineMail />
                    </Tag>
                  </div>
                  <h3 className="my-4 fw-bold d-flex justify-content-center">
                    Check your email
                  </h3>
                  <h6 className="my-4 d-flex justify-content-center">
                    We sent a password reset link to <strong>{email}</strong>
                  </h6>
                  <div className="my-3 d-flex justify-content-center align-items-center text-center">
                    <div>Didn’t receive the email?</div>
                    <div>
                      <Button
                        type="link"
                        className="fw-bold text-decoration-none"
                        onClick={handleResendEmail}
                        disabled={isLoading} // Disable while loading
                      >
                        Click here to Resend
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {/* Common "Back to Login" Button */}
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  type="text"
                  className="mt-3 fw-bold"
                  size="large"
                  onClick={() => navigate(ROUTES.AUTH.LOGIN)}
                >
                  <FaArrowLeft className="me-2" />
                  Back to Login
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={2} md={2} sm={0} xs={0}></Col>
        </Row>
      </Container>
    </MainLayout>
  );
}

export default ForgotPasswordPage;
