import { countryCodes, selectOptions } from "../utils/countryCodes";

/**
 * @typedef {Object} FieldConfig
 * @property {string} name - The name attribute of the input field.
 * @property {string} type - The type of input field (e.g., text, password, email).
 * @property {string} placeholder - Placeholder text for the input field.
 * @property {React.ElementType} [icon] - An optional icon component to display inside the input field.
 */

/**
 * @typedef {Object} FormFieldTypes
 * @property {FieldConfig[]} loginFields - Configuration for fields in the login form.
 * @property {FieldConfig[]} signupFields - Configuration for fields in the signup form.
 * @property {FieldConfig[]} forgotPasswordFields - Configuration for fields in the forgot password form.
 * @property {FieldConfig[]} resetPasswordFields - Configuration for fields in the reset password form.
 * @property {FieldConfig[]} verificationFields - Configuration for fields in the verification form.
 */

/**
 * formFieldTypes - Configuration for form fields used in various forms like login, signup, forgot password, reset password, and verification.
 *
 * This object contains several arrays, each representing a set of fields for different forms.
 *
 * @type {FormFieldTypes}
 */
export const formFieldTypes = {
  loginFields: [
    {
      name: "email",
      label: "Email",
      placeholder: "Enter your email",
      type: "text",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaEnvelope,
    },
    {
      name: "password",
      label: "Password",
      placeholder: "Enter your password",
      type: "password",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaLock,
    },
  ],

  signupFields: [
    // {
    //   name: "firstName",
    //   label: "First Name",
    //   placeholder: "Enter your First Name",
    //   type: "text",
    //   size: "large",
    //   colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
    //   // icon: FaUser,
    // },
    // {
    //   name: "lastName",
    //   label: "Last Name",
    //   placeholder: "Enter your Last Name",
    //   type: "text",
    //   size: "large",
    //   colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
    //   // icon: FaUser,
    // },
    {
      name: "username",
      label: "Username",
      placeholder: "Enter your username",
      type: "text",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaUser,
    },
    {
      name: "email",
      label: "Email",
      placeholder: "Enter your email",
      type: "text",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaEnvelope,
    },
    {
      name: "password",
      label: "Password",
      placeholder: "Enter your password",
      type: "password",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaLock,
    },
    // {
    //   name: "confirmPassword",
    //   label: "Confirm Password",
    //   placeholder: "Confirm your password",
    //   type: "password",
    //   size: "large",
    //   colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
    //   // icon: FaLock,
    // },
    // {
    //   name: "mobile",
    //   label: "Mobile",
    //   placeholder: "Enter your mobile number",
    //   type: "text",
    //   size: "large",
    //   colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
    //   // icon: FaPhone,
    // },
  ],

  forgotPasswordFields: [
    {
      name: "email",
      label: "Email",
      placeholder: "Enter email to reset password",
      type: "text",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaEnvelope,
    },
  ],

  resetPasswordFields: [
    {
      name: "password",
      label: "Password",
      placeholder: "Enter your new password",
      type: "password",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaLock,
    },
    {
      name: "confirmPassword",
      label: "Confirm Password",
      placeholder: "Confirm your new password",
      type: "password",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaLock,
    },
  ],

  verificationFields: [
    {
      name: "verificationCode",
      label: "Verification Code",
      placeholder: "Enter verification code",
      type: "text",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaLock,
    },
  ],

  editProfileFields: [
    {
      name: "username",
      label: "Username",
      placeholder: "Enter User Name",
      type: "text",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
    },
    {
      name: "email",
      label: "Email",
      placeholder: "Enter your Email",
      type: "text",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
    },
    // {
    //   name: "countryCode",
    //   label: "Country Code",
    //   placeholder: "Select your country code",
    //   type: "select",
    //   size: "large",
    //   colSpan: { xs: 4, sm: 4, md: 4, lg: 4 },
    //   options: countryCodes.map((code) => ({
    //     label: `${code.name} ${code.dial_code}`,
    //     value: code.dial_code,
    //   })),
    // },
    // {
    //   name: "phoneNumber",
    //   label: "Phone Number",
    //   placeholder: "Enter your phone number",
    //   type: "text",
    //   size: "large",
    //   colSpan: { xs: 8, sm: 8, md: 8, lg: 8 },
    //   // icon: FaPhone,
    //   // countries: countryCodes,
    // },
    // {
    //   name: "mobile",
    //   label: "Mobile No.",
    //   type: "compact",
    //   size: "large",
    //   colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
    //   compactFields: [
    //     {
    //       type: "select",
    //       placeholder: "Select Country Code",
    //       options: selectOptions,
    //       defaultValue: "",
    //     },
    //     {
    //       type: "text",
    //       placeholder: "Enter Mobile Number",
    //       defaultValue: "",
    //     },
    //   ],
    // },
    // {
    //   name: "dateOfBirth",
    //   label: "Date of Birth",
    //   placeholder: "Select date of birth",
    //   type: "date",
    //   size: "large",
    //   colSpan: { xs: 12, sm: 12, md: 6, lg: 6 },
    // },
    {
      name: "gender",
      label: "Gender",
      placeholder: "Select gender",
      type: "select",
      options: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
      ],
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 6, lg: 6 },
    },
  ],

  changePasswordFields: [
    {
      name: "oldPassword",
      label: "Old Password",
      placeholder: "Enter your Old Password",
      type: "password",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaLock,
    },
    {
      name: "newPassword",
      label: "New Password",
      placeholder: "Enter your New Password",
      type: "password",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaLock,
    },
    {
      name: "confirmPassword",
      label: "Confirm Password",
      placeholder: "Re-enter your New Password",
      type: "password",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaLock,
    },
  ],

  contactUsFields: [
    {
      name: "firstName",
      label: "First Name",
      placeholder: "Enter your First Name",
      type: "text",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 6, lg: 6 },
      // icon: FaUser,
    },
    {
      name: "lastName",
      label: "Last Name",
      placeholder: "Enter your Last Name",
      type: "text",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 6, lg: 6 },
      // icon: FaUser,
    },
    {
      name: "email",
      label: "Email",
      placeholder: "Enter your Email",
      type: "text",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaEnvelope,
    },
    {
      name: "countryCode",
      label: "Country Code",
      placeholder: "Select your country code",
      type: "select",
      size: "large",
      colSpan: { xs: 4, sm: 4, md: 4, lg: 4 },
      options: countryCodes.map((code) => ({
        label: `${code.name} ${code.dial_code}`,
        value: code.dial_code,
      })),
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      placeholder: "Enter your phone number",
      type: "text",
      size: "large",
      colSpan: { xs: 8, sm: 8, md: 8, lg: 8 },
      // icon: FaPhone,
      // countries: countryCodes,
    },
    {
      name: "message",
      label: "Message",
      placeholder: "Your message",
      type: "textarea",
      size: "large",
      colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
      // icon: FaCommentDots,
    },
  ],
  
  addUserPlaylistFields: [
    {
      name: "playlist_name",
      label: "Playlist Name",
      placeholder: "Enter Playlist Name",
      type: "text",
      // size: "large",
      colSpan: { xs: 12, sm: 12, md: 6, lg: 6 },
      // icon: FaUser,
    },
    {
      name: "playlist_type",
      label: "Playlist Type",
      placeholder: "Enter Playlist Type",
      type: "text",
      // size: "large",
      colSpan: { xs: 12, sm: 12, md: 6, lg: 6 },
      // icon: FaUser,
    },
  ],
};
