import React from "react";

import SEOManager from "../../../utils/SEOManager";
import MainLayout from "../../../layouts/MainLayout";
import ChangePasswordSection from "../../../components/_sections/ChangePasswordSection";
import EditProfileSection from "../../../components/_sections/EditProfileSection";
import TopBar from "../../../components/_buttons/TopBar";
import { Col, Container, Image, Row } from "react-bootstrap";
import { StaticImages } from "../../../utils/constants";

function ProfilePage() {
  return (
    <MainLayout>
      <SEOManager
        title="Profile - LyricsWeb"
        ogUrl="www.lyricsweb.com/auth/profile"
      />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <Row>
          <Col lg={9} md={9} sm={12} xs={12}>
            <div className="m-4">
              <EditProfileSection />
            </div>
            <div className="m-4">
              <ChangePasswordSection />
            </div>
          </Col>
          <Col lg={3} md={3} sm={12} xs={12} className="d-none d-lg-block">
            <div className="m-2">
              <Image
                fluid
                src={StaticImages.GoogleAds.DesktopAd_V}
                className="w-100 rounded-3"
                alt="Home Banner"
              />
            </div>
          </Col>
        </Row>
        <div className="m-2">
          <Image
            fluid
            src={StaticImages.GoogleAds.DesktopAd_H_small}
            className="w-100 rounded-3"
            alt="Home Banner"
          />
        </div>
      </Container>
    </MainLayout>
  );
}

export default ProfilePage;
