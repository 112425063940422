import React, { useState } from "react";
import { Button, Input, Tooltip } from "antd"; // Import Ant Design components
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchemas } from "../../utils/validationSchemas"; // Ensure this is defined correctly
import { authController } from "../../features/auth/authSlice";
import { getAuthConfig } from "../../services/apiUtils";
import { formFieldTypes } from "../../utils/formFieldTypes"; // Assuming fields are defined here
import RenderField from "../_fields/RenderField";

function ChangePasswordSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemas.changePasswordSchema),
    mode: "onChange",
  });

  const onSubmitChangePassword = async (data) => {
    console.log(data);

    try {
      const { confirmPassword, ...formData } = data;
      let payload = { ...formData };
      await dispatch(
        authController.changeUserPassword({
          payload,
          config: getAuthConfig(),
          resetForm: reset,
          navigate,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error Changing Password:", error);
    }
  };

  return (
    <div>
      <h2 className="fw-bold mb-4">Change Password</h2>

      <div>
        <p>To change your password, please fill in the fields below.</p>
        <p>
          Your password must contain at least 8 characters, including at least
          one uppercase letter, one lowercase letter, one number, and one
          special character.
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmitChangePassword)}>
        {formFieldTypes.changePasswordFields.map((field) => (
          <RenderField
            key={field.name}
            field={field}
            control={control}
            errors={errors}
          />
        ))}

        <Button
          type="default"
          className="mt-3 bg-corbeau text-ghostWhite fw-bold"
          htmlType="submit"
          size="large"
        >
          Change Password
        </Button>
      </form>
    </div>
  );
}

export default ChangePasswordSection;
