import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { motion } from "framer-motion";

import styles from "./FeaturedMusicVideo.module.css";
import { FaPlay } from "react-icons/fa6";
import _ from "lodash";

const FeaturedMusicVideo = ({ genreType = "all" }) => {
  // Sample data for videos (including title, subtitle, author, and date)
  const videos = [
    {
      id: 1,
      title: "Jazzy Night",
      subtitle: "Discover the latest hits and exclusive performances.",
      author: "Eden Muñoz",
      date: "Sept 30, 2024",
      url: "https://www.youtube.com/embed/dQw4w9WgXcQ",
    },
    {
      id: 2,
      title: "Video 2",
      subtitle: "Discover the latest hits and exclusive performances.",
      author: "Eden Muñoz",
      date: "Sept 30, 2024",
      url: "https://www.youtube.com/embed/kJQP7kiw5Fk",
    },
    {
      id: 3,
      title: "Video 3",
      subtitle: "Discover the latest hits and exclusive performances.",
      author: "Eden Muñoz",
      date: "Sept 30, 2024",
      url: "https://www.youtube.com/embed/3JZ_D3ELwOQ",
    },
    {
      id: 4,
      title: "Video 4",
      subtitle: "Discover the latest hits and exclusive performances.",
      author: "Eden Muñoz",
      date: "Sept 30, 2024",
      url: "https://www.youtube.com/embed/2Vv-BfVoq4g",
    },
  ];

  return (
    <div className="my-4">
      <h1 className="fw-bold mb-4">
        Featured {genreType !== "all" ? _.capitalize(genreType) : ""} Music
        Videos
      </h1>
      <Row className="g-4">
        {/* Large featured video */}
        <Col lg={8}>
          <Card className="border-0 bg-ghostWhite">
            <div className={styles.largeVideoContainer}>
              <iframe
                src={videos[0].url}
                title={videos[0].title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-100 h-100 rounded-4"
              ></iframe>
            </div>
            <Card.Body>
              <Row>
                <Col lg={8}>
                  <h4 className="fw-bold">{videos[0].title}</h4>
                  <p className="text-muted">{videos[0].subtitle}</p>
                </Col>
                <Col lg={4}>
                  <p className="text-muted">
                    By <strong>{videos[0].author}</strong> / {videos[0].date}
                  </p>
                  <motion.div
                    whileHover={{
                      scale: 1.05, // Scale the button slightly on hover
                      transition: { duration: 0.3 },
                    }}
                  >
                    <Button
                      variant="eigengrau"
                      size="sm"
                      className="mt-2 d-flex align-items-center"
                    >
                      <FaPlay className="mx-1" />
                      <span>Watch Now</span>{" "}
                    </Button>
                  </motion.div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        {/* Small video listing */}
        <Col lg={4}>
          {videos.slice(1).map((video) => (
            <Card key={video.id} className="mb-3 border-0 bg-ghostWhite">
              <Row className="g-0 align-items-center">
                <Col xs={4} className="align-self-start">
                  <div className={styles.smallVideoContainer}>
                    <iframe
                      src={video.url}
                      title={video.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="w-100 h-100 rounded-3"
                    ></iframe>
                  </div>
                </Col>
                <Col xs={8}>
                  <Card.Body className="p-2">
                    <p
                      className="text-muted mb-1"
                      style={{ fontSize: "0.75rem" }}
                    >
                      By {video.author} / {video.date}
                    </p>
                    <h6 className="fw-bold mb-1">{video.subtitle}</h6>

                    <Button
                      as={motion.button}
                      variant="eigengrau"
                      size="sm"
                      className="mt-2 d-flex align-items-center"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 20,
                      }}
                    >
                      <FaPlay className="mx-1" />
                      <span>Watch Now</span>{" "}
                    </Button>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default FeaturedMusicVideo;
