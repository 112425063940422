import React, { useEffect, useMemo } from "react";
import { Button, Col, Image, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { StaticImages } from "../../utils/constants";
import ArtistCard from "../_cards/ArtistCard";
import { artistController } from "../../features/artist/artistSlice";
import { getConfig } from "../../services/apiUtils";
import { ErrorAlert } from "../_alerts/UIAlerts";
import { MdArrowOutward } from "react-icons/md";

function AllArtists({ display = 4, genreType = "all" }) {
  const dispatch = useDispatch();

  const { allArtists, isLoading, error } = useSelector(
    (state) => state.artist || {}
  );

  useEffect(() => {
    dispatch(
      artistController.getAllArtists({
        payload: genreType !== "all" ? { genre: genreType } : {},
        config: getConfig(),
      })
    ).catch((error) => console.error("Failed to fetch artists:", error));
  }, [dispatch, genreType]);

  const randomArtists = useMemo(() => {
    return allArtists?.length > 0
      ? [...allArtists].sort(() => Math.random() - 0.5).slice(0, display)
      : [];
  }, [allArtists, display]);

  return (
    <div className="my-4">
      <div className="my-4">
        <h1 className="fw-bold">All Artists</h1>
      </div>

      <Row className="g-4">
        <Col lg={8}>
          <Row className="g-4 m-0 p-0">
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status" variant="primary">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : error ? (
              <>
                <ErrorAlert description={error} />
              </>
            ) : randomArtists.length > 0 ? (
              <Row className="g-4 bg-ghostWhite m-0 p-0">
                {randomArtists.map((artist, index) => (
                  <>
                    <Col lg={3} md={4} sm={6} xs={6} key={index}>
                      <ArtistCard artist={artist} index={index} />
                    </Col>
                  </>
                ))}

                {/* <div className="ms-auto d-flex align-items-center float-end">
                  <span className="fw-bold">See All</span>
                  <Button
                    variant="eigengrau"
                    size="sm"
                    className="fw-bold fs-6 mx-2 rounded-circle"
                  >
                    <MdArrowOutward />
                  </Button>
                </div> */}
              </Row>
            ) : (
              <p className="text-center">No artists available at the moment.</p>
            )}
          </Row>
        </Col>
        <Col lg={4} className="d-none d-lg-block">
          <Image fluid src={StaticImages.GoogleAds.DesktopAd_V} />
        </Col>
      </Row>
    </div>
  );
}

export default AllArtists;
