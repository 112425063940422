import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import { StaticImages } from "../../../utils/constants";
import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";

function AboutUsPage() {
  const seoData = getSEOConfig("aboutPage");

  return (
    <>
      <MainLayout>
        <SEOManager {...seoData} />
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>

          <Container className="p-4">
            <Row>
              <Col lg={8} md={8} sm={12} xs={12}>
                <Section title="Welcome to LyricsWeb" heading={1}>
                  <p>
                    Welcome to LyricsWeb – your ultimate destination for
                    exploring, enjoying, and discovering the world of music. At
                    LyricsWeb, we believe that music is more than just sound;
                    it's an experience that connects people, tells stories, and
                    evokes emotions.
                  </p>
                </Section>

                <Section title="Our Mission" heading={3}>
                  <p>
                    Our mission is to provide music enthusiasts with a seamless
                    platform where they can access comprehensive lyrics, delve
                    into song meanings, and stay up-to-date with the latest in
                    the music world. We aim to make the process of finding and
                    understanding music easy, engaging, and enjoyable for
                    everyone.
                  </p>
                </Section>

                <Section title="What we offer" heading={3}>
                  <ul>
                    <li className="my-3">
                      <strong>Extensive Lyrics Library:</strong> From timeless
                      classics to the latest hits, we curate an expansive
                      collection of song lyrics across various genres and
                      languages.
                    </li>
                    <li className="my-3">
                      <strong>Song Interpretations:</strong> Dive deeper into
                      the stories behind the songs, gaining insights into the
                      meanings and themes that inspire your favorite tracks.
                    </li>
                    <li className="my-3">
                      <strong>Music News and Updates:</strong> Stay informed
                      about music industry news, new releases, and trending
                      artists.
                    </li>
                  </ul>
                </Section>

                <Section heading={3}>
                  <Image
                    fluid
                    src={StaticImages.App.AppLogoLyrics}
                    className="my-3"
                  />
                  <p>
                    At LyricsWeb, we are passionate about music and dedicated to
                    creating a space where music lovers can come together to
                    explore and appreciate the art in its many forms. Thank you
                    for choosing LyricsWeb as your go-to music companion!
                  </p>
                </Section>
              </Col>

              {/* Sidebar Column */}
              <Col lg={4} md={4} sm={12} xs={12} className="text-center">
                <Image
                  fluid
                  rounded
                  src={StaticImages.GoogleAds.DesktopAd_V}
                  className="d-none d-lg-block"
                />
                <Image
                  fluid
                  rounded
                  src={StaticImages.GoogleAds.MobileAd}
                  className="d-lg-none"
                />
              </Col>
            </Row>
          </Container>
        </Container>
      </MainLayout>
    </>
  );
}

function Section({ title, heading = 3, children }) {
  const HeadingTag = `h${heading}`;

  return (
    <div className="my-4">
      {title && <HeadingTag className="mb-3 fw-bold">{title}</HeadingTag>}
      {children}
    </div>
  );
}

export default AboutUsPage;
