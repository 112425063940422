import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Form, Button, Radio, Select } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import RenderField from "../../../components/_fields/RenderField";
import { StaticImages } from "../../../utils/constants";

const { Option } = Select;

const fields = [
  {
    name: "songName",
    label: "Song Name",
    placeholder: "Enter the song name",
    type: "text",
    size: "large",
    colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
  },
  {
    name: "lyrics",
    label: "Lyrics",
    placeholder: "Enter the lyrics",
    type: "textarea",
    size: "large",
    colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
  },
  {
    name: "languages",
    label: "Languages",
    placeholder: "Select the languages",
    type: "select",
    size: "large",
    colSpan: { xs: 12, sm: 12, md: 6, lg: 6 },
    options: [
      { value: "english", label: "English" },
      { value: "spanish", label: "Spanish" },
      { value: "french", label: "French" },
      { value: "hindi", label: "Hindi" },
    ],
    multiple: true, // Multi-select enabled
  },
  {
    name: "releaseYear",
    label: "Release Year",
    placeholder: "Select the release year",
    type: "date",
    size: "large",
    colSpan: { xs: 12, sm: 12, md: 6, lg: 6 },
  },
  {
    name: "genres",
    label: "Genres",
    placeholder: "Select genres",
    type: "select",
    size: "large",
    colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
    options: [
      { value: "pop", label: "Pop" },
      { value: "rock", label: "Rock" },
      { value: "hiphop", label: "Hip-Hop" },
      { value: "classical", label: "Classical" },
    ],
    multiple: true, // Multi-select enabled
  },
  {
    name: "performedBy",
    label: "Performed By",
    placeholder: "Enter the performer's name",
    type: "text",
    size: "large",
    colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
  },
  {
    name: "artistPicture",
    label: "Artist Picture",
    placeholder: "Upload the artist's picture",
    type: "upload", // Changed to 'upload'
    size: "large",
    colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
  },
  {
    name: "gender",
    label: "Gender",
    placeholder: "Select the gender",
    type: "radio",
    size: "large",
    colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
    options: [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "group", label: "Group" },
    ],
  },
  {
    name: "artistBio",
    label: "Artist Bio",
    placeholder: "Enter a brief artist bio",
    type: "textarea",
    size: "large",
    colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
  },
  {
    name: "youtubeUrl",
    label: "YouTube URL",
    placeholder: "Enter the YouTube URL",
    type: "text",
    size: "large",
    colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
  },
  {
    name: "artistWebsite",
    label: "Artist Website",
    placeholder: "Enter the artist's website URL",
    type: "text",
    size: "large",
    colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
  },
  {
    name: "writtenBy",
    label: "Written By",
    placeholder: "Enter the writer's name",
    type: "text",
    size: "large",
    colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
  },
  {
    name: "emailAddress",
    label: "Your Email Address",
    placeholder: "Enter your email address",
    type: "text",
    size: "large",
    colSpan: { xs: 12, sm: 12, md: 12, lg: 12 },
  },
];

const validationSchema = yup.object().shape({
  songName: yup.string().required("Song Name is required"),
  lyrics: yup.string().required("Lyrics are required"),
  languages: yup
    .array()
    .min(1, "Please select at least one language")
    .required("Languages are required"),
  releaseYear: yup.date().required("Release Year is required"),
  genres: yup
    .array()
    .min(1, "Please select at least one genre")
    .required("Genres are required"),
  performedBy: yup.string().required("Performer is required"),
  artistPicture: yup
    .string()
    .url("Artist picture must be a valid URL")
    .required("Artist picture URL is required"),
  gender: yup.string().required("Gender is required"),
  artistBio: yup.string().required("Artist bio is required"),
  youtubeUrl: yup.string().url("YouTube URL must be valid"),
  artistWebsite: yup.string().url("Artist website must be valid"),
  writtenBy: yup.string().required("Writer's name is required"),
  emailAddress: yup
    .string()
    .email("Email must be valid")
    .required("Email is required"),
});

function AddNewSongLyricsPage() {
  const seoData = getSEOConfig("addLyricsPage");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      songName: "",
      lyrics: "",
      languages: [],
      releaseYear: null,
      genres: [],
      performedBy: "",
      artistPicture: "",
      gender: "",
      artistBio: "",
      youtubeUrl: "",
      artistWebsite: "",
      writtenBy: "",
      emailAddress: "",
    },
  });

  const onSubmit = (data) => {
    console.log("Submitted Data:", data);
    // Handle form submission logic
  };

  return (
    <>
      <MainLayout>
        <SEOManager {...seoData} />
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>
          <Row className="m-0 p-0">
            <Col lg={8} md={8} sm={12} xs={12}>
              <Container fluid>
                <h1 className="my-4 fw-bold">Contribute New Lyrics</h1>
                <p className="my-4 fw-light">
                  Join us in expanding the ultimate lyrics database online!
                  Kindly complete the form below and submit your contribution:
                </p>
                <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
                  <Row className="g-4">
                    {fields.map((field) => (
                      <RenderField
                        key={field.name}
                        field={field}
                        control={control}
                        errors={errors}
                      />
                    ))}
                  </Row>
                  <div className="mt-4">
                    <Button
                      variant="solid"
                      type="default"
                      color="default"
                      htmlType="submit"
                      size="large"
                    >
                      Submit Lyrics
                    </Button>
                  </div>
                </Form>
              </Container>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Image
                fluid
                rounded
                src={StaticImages.GoogleAds.DesktopAd_V}
                className="d-none d-lg-block"
              />
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </>
  );
}

export default AddNewSongLyricsPage;
