import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { StaticImages } from "../../../utils/constants";
import { getConfig } from "../../../services/apiUtils";
import { authController } from "../../../features/auth/authSlice";
import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import { validationSchemas } from "../../../utils/validationSchemas";
import { formFieldTypes } from "../../../utils/formFieldTypes";
import { ROUTES } from "../../../routes/constants";
import TopBar from "../../../components/_buttons/TopBar";
import RenderField from "../../../components/_fields/RenderField";

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemas.loginSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    try {
      await dispatch(
        authController.loginUser({
          payload: data,
          config: getConfig(),
          resetForm: reset,
          navigate,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error Logging In:", error);
    }
  };

  const seoData = getSEOConfig("loginPage");

  return (
    <MainLayout>
      <SEOManager {...seoData} />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div>
          <Row className="m-0 p-0">
            <Col lg={6} md={6} sm={12} xs={12} className={`rounded-5`}>
              <div className="w-100 p-lg-5 p-sm-2">
                <h3 className="my-4">Welcome Back</h3>
                <h6 className="my-4">Login in to access the website</h6>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {formFieldTypes.loginFields.map((field) => (
                    <RenderField
                      key={field.name}
                      field={field}
                      control={control}
                      errors={errors}
                    />
                  ))}
                  <div className="my-3 d-flex justify-content-end align-items-center text-center">
                    <div>
                      <Button
                        variant="link"
                        onClick={() => navigate(ROUTES.AUTH.FORGOT_PASSWORD)}
                        className="fw-bold text-decoration-none"
                      >
                        Forget Password?
                      </Button>
                    </div>
                  </div>

                  <Button
                    type="default"
                    className="bg-corbeau text-ghostWhite fw-bold w-100"
                    htmlType="submit"
                    size="large"
                  >
                    Login
                  </Button>
                </form>

                <div className="my-3 d-flex justify-content-center align-items-center text-center">
                  <div>Doesn't Have an Account?</div>
                  <div>
                    <Button
                      variant="link"
                      onClick={() => navigate(ROUTES.AUTH.SIGNUP)}
                      className="fw-bold text-decoration-none"
                    >
                      Sign Up
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="d-none d-lg-flex position-relative bg-sliceHeaven rounded-4"
            >
              <div className="w-100" style={{ paddingTop: "100%" }}>
                {/* This padding maintains the 1:1 aspect ratio */}
                <Image
                  fluid
                  className="position-absolute top-0 start-0 w-100 h-100 p-5"
                  src={StaticImages.App.AppDefaultWhite}
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </MainLayout>
  );
}

export default LoginPage;
