import React, { useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { MdArrowOutward } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { albumController } from "../../features/album/albumSlice";
import AlbumCard from "../_cards/AlbumCard";
import { ErrorAlert } from "../_alerts/UIAlerts";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/constants";
import { Spin } from "antd";

function TopExploreAlbums({ display = 4, genreType = "all" }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { topExploreAlbums, isLoading, error } = useSelector(
    (state) => state.album
  );

  useEffect(() => {
    dispatch(
      albumController.getTopExploreAlbums({
        payload: genreType !== "all" ? { genre: genreType } : {},
        config: {},
      })
    );
  }, [dispatch, genreType]);

  const albums = topExploreAlbums?.albums
    ? [...topExploreAlbums.albums]
        .sort(() => 0.5 - Math.random())
        .slice(0, display)
    : [];

  return (
    <div className="my-4">
      <div className="d-flex justify-content-center align-items-center my-5">
        <h1 className="fw-bold">
          Top Explore {genreType !== "all" ? _.capitalize(genreType) : ""}{" "}
          Albums
        </h1>
      </div>

      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <Spin tip="Loading..." size="large" />
        </div>
      ) : error ? (
        <>
          <ErrorAlert description={error} />
        </>
      ) : albums.length > 0 ? (
        <Row className="g-4">
          {albums.map((album, index) => (
            <>
              <Col lg={3} md={3} sm={6} xs={6} key={index}>
                <AlbumCard album={album} index={index} />
              </Col>
            </>
          ))}

          <div className="ms-auto d-flex align-items-center float-end">
            <span className="fw-bold">See All</span>
            <Button
              variant="eigengrau"
              size="sm"
              className="fw-bold fs-6 mx-2 rounded-circle"
              onClick={() => navigate(ROUTES.MAIN.ALBUMS)}
            >
              <MdArrowOutward />
            </Button>
          </div>
        </Row>
      ) : (
        <p className="text-center">
          No top explore albums available at the moment.
        </p>
      )}
    </div>
  );
}

export default TopExploreAlbums;
