import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  footerNavLinks,
  letterNavLinks,
  mainNavLinks,
} from "../../utils/constants";
import {
  FaFacebook,
  FaInstagram,
  FaPinterest,
  FaYoutube,
} from "react-icons/fa6";
import styles from "./AppFooter.module.css";
import { NavLink } from "react-router-dom";
import ThemeToggle from "../../styles/ThemeToggle";
import AppDefaultBtn from "../_buttons/AppDefaultBtn";
import AlphabetNav from "../_buttons/AlphabetNav";

export default function AppFooter() {
  const socialMediaLinks = [
    { icon: FaFacebook, label: "Facebook" },
    { icon: FaInstagram, label: "Instagram" },
    { icon: FaYoutube, label: "Youtube" },
    { icon: FaPinterest, label: "Pinterest" },
  ];

  return (
    <Container fluid className={`${styles.AppFooter} fw-semibold`}>
      <hr />

      <Container className="py-4 fl">
        <AlphabetNav />
      </Container>

      <hr />

      <Row className="justify-content-around m-5">
        {footerNavLinks.map((section, sectionIndex) => (
          <Col
            lg={3}
            md={3}
            sm={6}
            xs={6}
            key={sectionIndex}
            className={`mb-4`}
          >
            <h5 className={`mb-5 fw-bold`}>{section.mainHeading}</h5>
            <ul className={`list-unstyled`}>
              {section.subLinks.map((link, linkIndex) => (
                <li key={linkIndex} className={styles.footerLink}>
                  <NavLink to={link.path} className={styles.footerLink}>
                    {link.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </Col>
        ))}
        <Col lg={3} md={3} sm={6} xs={6}>
          <div>
            <h5 className={`mb-5 fw-bold`}>Follow Us</h5>
            <div className="d-flex flex-column">
              {socialMediaLinks.map((social, index) => (
                <AppDefaultBtn
                  key={index}
                  variant="whiteBtn"
                  btnTxt={social.label}
                  className="my-2"
                  icon={social.icon}
                />
              ))}
            </div>
          </div>
        </Col>
      </Row>

      <div className="py-3 d-flex align-items-center justify-content-evenly">
        <Row className="mx-5 g-4 text-center">
          <Col lg={6} md={6} sm={12} xs={12}>
            <div>All rights reserved to Global Media Holdings Ltd. 2024</div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="d-flex justify-content-sm-between">
              <NavLink
                to={"/terms-of-use"}
                className={`${styles.footerLink} mx-2`}
              >
                Terms of Use
              </NavLink>
              <NavLink
                to={"/privacy-policy"}
                className={`${styles.footerLink} mx-2`}
              >
                Privacy Policy
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
