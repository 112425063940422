import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { letterNavLinks } from "../../utils/constants";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import styles from "./TopBar.module.css";
import { useSelector } from "react-redux";
import { ROUTES } from "../../routes/constants";
import AlphabetNav from "./AlphabetNav";

const TopBar = () => {
  const {
    genres = [],
    isLoading,
    error,
  } = useSelector((state) => state.config);
  const { genreName: genre } = useParams();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [filteredGenres, setFilteredGenres] = useState(genres);
  const [selectedGenre, setSelectedGenre] = useState(genre ? genre : "All");

  useEffect(() => {
    setFilteredGenres(
      genres?.filter((genre) =>
        genre?.genreName.toLowerCase().includes(searchValue?.toLowerCase())
      )
    );
  }, [searchValue]);

  const handleGenreSelect = (genre) => {
    const selectedGenre =
      typeof genre === "string" ? genre : genre.genreName.toLowerCase();

    console.log("Selected genre:", selectedGenre);

    // setSelectedGenre(selectedGenre);
    navigate(`/genre/${selectedGenre}`);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <div className="d-flex justify-content-start align-items-center flex-wrap">
        <div className="my-3 d-flex justify-content-start flex-wrap">
          <Button
            variant={`${selectedGenre === "all" ? "eigengrau" : "light"}`}
            className={`mx-1 border border-dark fw-bold`}
            onClick={() => navigate(ROUTES.MAIN.HOME)}
            size="sm"
          >
            All
          </Button>

          <Button
            variant={`${selectedGenre === "rock" ? "eigengrau" : "light"}`}
            className={`mx-1 border border-dark fw-bold`}
            onClick={() => handleGenreSelect("rock")}
            size="sm"
          >
            Rock
          </Button>

          <Button
            variant={`${selectedGenre === "pop" ? "eigengrau" : "light"}`}
            className={`mx-1 border border-dark fw-bold`}
            onClick={() => handleGenreSelect("pop")}
            size="sm"
          >
            Pop
          </Button>

          <Button
            variant={`${selectedGenre === "pop" ? "eigengrau" : "light"}`}
            className={`mx-1 border border-dark fw-bold`}
            onClick={() => navigate(ROUTES.MAIN.GENRES)}
            size="sm"
          >
            More
          </Button>
        </div>

        <div className="my-2 d-none d-lg-flex flex-wrap justify-content-end">
          {letterNavLinks.map((item, index) => (
            <NavLink
              key={index}
              to={item.path}
              className={`${styles.navLink}`}
              style={{ whiteSpace: "nowrap" }}
            >
              {item.label}
            </NavLink>
          ))}
        </div>
      </div>
      <div className="d-block d-sm-none">
        <Container fluid className="py-4">
          <AlphabetNav />
        </Container>
      </div>
    </>
  );
};

export default TopBar;
