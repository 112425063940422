import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { MdDarkMode, MdLightMode } from "react-icons/md";

const ThemeToggle = () => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    // Set the initial theme by checking localStorage or default to light mode
    const savedTheme = localStorage.getItem("theme") || "light";
    setTheme(savedTheme);
    // Set the data-bs-theme attribute on the body
    document.body.setAttribute("data-bs-theme", savedTheme);
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    // Update the data-bs-theme attribute on the body
    document.body.setAttribute("data-bs-theme", newTheme);
    localStorage.setItem("theme", newTheme); // Save theme preference
  };

  return (
    <Button
      variant={theme === "light" ? "dark" : "light"}
      onClick={toggleTheme}
      // size="sm"
    >
      {theme === "light" ? (
        <>
          <MdDarkMode className="mx-2" />
        </>
      ) : (
        <>
          <MdLightMode className="mx-2" />
        </>
      )}{" "}
    </Button>
  );
};

export default ThemeToggle;
