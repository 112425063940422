import { UploadOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Radio,
  message,
  Upload,
} from "antd";
import moment from "moment";
import React from "react";
import { Col } from "react-bootstrap";
import { Controller } from "react-hook-form";

const { Option } = Select;

const RenderField = ({ field, control, errors }) => {
  const {
    name,
    label,
    placeholder,
    type,
    options,
    size,
    colSpan,
    icon,
    compactFields,
    defaultValue, // Added to support prefilled data
  } = field;

  const renderIcon = () => {
    if (icon) {
      return <span className="mx-1">{React.createElement(icon)}</span>; // Add margin to the icon
    }
    return null;
  };

  const handleFileChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <Col key={name} {...colSpan}>
      {label && <label className="fw-bold">{label}</label>}
      <Controller
        name={name}
        control={control}
        render={({ field: controllerField }) => (
          <Form.Item
            validateStatus={errors[name] ? "error" : ""}
            help={errors[name]?.message}
          >
            {/* Text Input */}
            {type === "text" && (
              <Input
                {...controllerField}
                placeholder={placeholder}
                size={size}
                prefix={renderIcon()} // Render icon if available
                defaultValue={defaultValue} // Prefilled value support
              />
            )}

            {/* Date Picker */}
            {type === "date" && (
              <DatePicker
                {...controllerField}
                placeholder={placeholder}
                value={
                  controllerField.value ? moment(controllerField.value) : null
                } // Ensure moment compatibility
                onChange={controllerField.onChange} // Simplify event handling
                style={{ width: "100%" }}
                size={size}
                suffixIcon={renderIcon()} // Render icon if available
              />
            )}

            {/* Select (Single and Multi-Select) */}
            {type === "select" && (
              <Select
                {...controllerField}
                placeholder={placeholder}
                size={size}
                prefix={renderIcon()} // Render icon if available
                mode={field.multiple ? "multiple" : undefined} // Multi-select mode
                defaultValue={defaultValue || []} // Prefilled value support for multi-select
              >
                {options.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            )}

            {/* Upload (File Upload) */}
            {type === "upload" && (
              <Upload
                {...controllerField}
                name="file"
                action="/upload" // Set your upload endpoint here
                onChange={handleFileChange}
                listType="picture-card"
                beforeUpload={(file) => {
                  // Handle file validation here (e.g., size, type)
                  const isImage = file.type.startsWith("image/");
                  if (!isImage) {
                    message.error("You can only upload image files!");
                  }
                  return isImage;
                }}
                maxCount={1}
              >
                {controllerField.value ? (
                  <img
                    src={controllerField.value}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  <div>
                    <UploadOutlined />
                    <div>Upload</div>
                  </div>
                )}
              </Upload>
            )}

            {/* Password Input */}
            {type === "password" && (
              <Input.Password
                {...controllerField}
                placeholder={placeholder}
                size={size}
                status={errors[name] ? "error" : ""}
                onChange={(e) => controllerField.onChange(e)}
                prefix={renderIcon()} // Render icon if available
                defaultValue={defaultValue} // Prefilled value support
              />
            )}

            {/* Text Area */}
            {type === "textarea" && (
              <Input.TextArea
                {...controllerField}
                placeholder={placeholder}
                size={size}
                prefix={renderIcon()} // Render icon if available
                defaultValue={defaultValue} // Prefilled value support
              />
            )}

            {/* Radio Buttons */}
            {type === "radio" && (
              <Radio.Group
                {...controllerField}
                defaultValue={defaultValue} // Prefilled value support for radio
              >
                {options.map((option) => (
                  <Radio key={option.value} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </Radio.Group>
            )}

            {/* Compact Fields */}
            {type === "compact" && (
              <Space.Compact style={{ display: "flex", width: "100%" }}>
                {compactFields.map((compactField, index) => {
                  if (compactField.type === "select") {
                    return (
                      <Select
                        key={`${name}-select-${index}`}
                        options={compactField.options}
                        placeholder={compactField.placeholder}
                        defaultValue={compactField.defaultValue}
                        onChange={(value) => {
                          const mobileNumber =
                            controllerField.value?.mobileNumber || [];
                          controllerField.onChange({
                            countryCode: value, // Store dial_code as countryCode
                            mobileNumber: mobileNumber,
                          });
                        }}
                        size={size}
                        style={{ flex: 1 }}
                      />
                    );
                  }
                  if (compactField.type === "text") {
                    return (
                      <Input
                        key={`${name}-input-${index}`}
                        placeholder={compactField.placeholder}
                        defaultValue={compactField.defaultValue}
                        onChange={(e) => {
                          const mobileNumber = e.target.value
                            .split("")
                            .map((digit) => parseInt(digit, 10));
                          const countryCode =
                            controllerField.value?.countryCode || "";
                          controllerField.onChange({
                            countryCode: countryCode,
                            mobileNumber: mobileNumber,
                          });
                        }}
                        size={size}
                        style={{ flex: 2 }}
                      />
                    );
                  }
                  return null;
                })}
              </Space.Compact>
            )}
          </Form.Item>
        )}
      />
    </Col>
  );
};

export default RenderField;
