import { createSlice } from "@reduxjs/toolkit";
import { quizThunks } from "./quizThunks";

const initialState = {
  quiz: null,
  validateQuiz: null,
  isLoading: false,
  error: null,
};

/* Quiz Section */
const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    resetQuiz: (state) => {
      state.quiz = null;
    },
    resetValidateQuiz: (state) => {
      state.validateQuiz = null;
    },
  },
  extraReducers: (builder) => {
    // Generic handlers for pending, fulfilled, and rejected states
    const handlePending = (state) => {
      state.isLoading = true;
      state.error = null;
    };

    const handleRejected = (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    };

    const handleFulfilled = (state, action, key) => {
      state.isLoading = false;
      state[key] = action.payload;
    };

    builder
      // Add cases for get quiz
      .addCase(quizThunks.getQuiz.pending, handlePending)
      .addCase(quizThunks.getQuiz.fulfilled, (state, action) => {
        state.isLoading = false;
        state.quiz = action.payload.data;
      })
      .addCase(quizThunks.getQuiz.rejected, handleRejected)
      // Add cases for validate quiz
      .addCase(quizThunks.getValidateQuiz.pending, handlePending)
      .addCase(quizThunks.getValidateQuiz.fulfilled, (state, action) => {
        state.isLoading = false;
        state.validateQuiz = action.payload.data;
      })
      .addCase(quizThunks.getValidateQuiz.rejected, handleRejected);
  },
});

export const { resetQuiz, resetValidateQuiz } = quizSlice.actions;

export const quizController = quizThunks;

export default quizSlice.reducer;
