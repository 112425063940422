import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { StaticImages } from "../../../utils/constants";
import { playlistController } from "../../../features/playlist/playlistSlice";
import { getAuthConfig } from "../../../services/apiUtils";
import PlaylistCard from "../../../components/_cards/PlaylistCard";
import { ErrorAlert } from "../../../components/_alerts/UIAlerts";
import { Button } from "antd";
import AddUserPlaylistModal from "../../../components/_modals/AddUserPlaylistModal";

function UserMyPlaylistPage() {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const { userMyPlaylists, isLoading, error } = useSelector(
    (state) => state.playlist
  );

  const fetchUserPlaylists = async () => {
    try {
      await dispatch(
        playlistController.getUserMyPlaylists({
          payload: {},
          config: getAuthConfig(),
        })
      ).unwrap();
    } catch (error) {
      console.error("Failed to fetch user my playlists:", error);
    }
  };

  useEffect(() => {
    fetchUserPlaylists();
  }, [dispatch]);

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div className="m-2">
          <Card className="bg-sliceHeaven rounded-4 ">
            <Row>
              <Col
                lg={8}
                md={8}
                sm={8}
                xs={8}
                className="d-flex flex-column-reverse"
              >
                <div className="fw-bold fs-1 text-white p-4">My Playlists</div>
              </Col>
              <Col lg={4} md={4} sm={4} xs={4}>
                <Image fluid src={StaticImages.App.AppDefaultWhite} />
              </Col>
            </Row>
          </Card>
        </div>

        <div className="m-4">
          <Button
            variant="solid"
            color="default"
            onClick={() => setShowModal(true)}
          >
            Add Playlist
          </Button>
        </div>

        <div className="m-2">
          <Row className="g-4 m-0 p-0">
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status" variant="primary">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : error ? (
              <ErrorAlert description={error} />
            ) : userMyPlaylists?.length > 0 ? (
              <Row className="g-4 bg-ghostWhite m-0 p-0">
                {userMyPlaylists?.map((playlist, index) => (
                  <Col key={playlist.id || index} lg={3} md={4} sm={6} xs={6}>
                    <PlaylistCard
                      playlist={playlist}
                      index={index}
                      type="card"
                      user="user"
                    />
                  </Col>
                ))}
              </Row>
            ) : (
              <p className="text-center">
                No User playlists available at the moment.
                <Button onClick={() => setShowModal(true)}>Add Playlist</Button>
              </p>
            )}
          </Row>
        </div>
      </Container>

      {/* Add Playlist Modal */}
      <AddUserPlaylistModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={() => {
          setShowModal(false);
        }}
        fetchPlayList={fetchUserPlaylists}
      />
    </MainLayout>
  );
}

export default UserMyPlaylistPage;
