import React from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { motion } from "framer-motion";

import { StaticImages } from "../../utils/constants";
import styles from "./TrendingPodcasts.module.css";
import { MdArrowOutward } from "react-icons/md";
import PodcastCard from "../_cards/PodcastCard";
import { FaPlay } from "react-icons/fa6";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";

const TrendingPodcasts = ({ genreType = "all" }) => {
  const navigate = useNavigate();

  // Array of podcast data
  const podcasts = [
    {
      id: 1,
      name: "Exploring the Music World",
      date: "October 15, 2024",
      image: StaticImages.Podcasts.Img1,
    },
    {
      id: 2,
      name: "Inside the Mind of a Musician",
      date: "October 12, 2024",
      image: StaticImages.Podcasts.Img2,
    },
    {
      id: 3,
      name: "The Evolution of Music Genres",
      date: "October 10, 2024",
      image: StaticImages.Podcasts.Img3,
    },
    {
      id: 4,
      name: "Breaking into the Music Industry",
      date: "October 9, 2024",
      image: StaticImages.Podcasts.Img4,
    },
  ];

  return (
    <div className="bg-foundationWhite rounded-4 p-4">
      <div className="py-4">
        <h1 className="fw-bold">Trending Podcasts</h1>
      </div>
      <div className={`d-flex overflow-x-scroll ${styles.horizontalScroll}`}>
        {podcasts.map((podcast, index) => (
          <PodcastCard podcast={podcast} index={index} />
        ))}
      </div>

      <div className="d-flex align-items-center p-2">
        <span className="fw-bold me-2">See All</span>
        <Button
          variant="eigengrau"
          size="sm"
          className="fw-bold fs-6 rounded-circle"
        >
          <MdArrowOutward />
        </Button>
      </div>
    </div>
  );
};

export default TrendingPodcasts;
