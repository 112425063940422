import { createSlice } from "@reduxjs/toolkit";
import { searchThunks } from "./searchThunks";

const initialState = {
  searchResult: null,
  searchResults: null,
  isLoading: false,
  error: null,
};

/* Search Section */
const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    resetSearchResult: (state) => {
      state.searchResult = null;
    },
    resetSearchResults: (state) => {
      state.searchResults = null;
    },
  },
  extraReducers: (builder) => {
    // Generic handlers for pending, fulfilled, and rejected states
    const handlePending = (state) => {
      state.isLoading = true;
      state.error = null;
    };

    const handleRejected = (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    };

    const handleFulfilled = (state, action, key) => {
      state.isLoading = false;
      state[key] = action.payload;
    };

    builder
      // Add cases for getSearch
      .addCase(searchThunks.getSearch.pending, handlePending)
      .addCase(searchThunks.getSearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchResult = action.payload.data;
      })
      .addCase(searchThunks.getSearch.rejected, handleRejected)
      // Add cases for getSearchResults
      .addCase(searchThunks.getSearchResults.pending, handlePending)
      .addCase(searchThunks.getSearchResults.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchResults = action.payload.data;
      })
      .addCase(searchThunks.getSearchResults.rejected, handleRejected);
  },
});

export const { resetSearchResult, resetSearchResults } = searchSlice.actions;

export const searchController = searchThunks;

export default searchSlice.reducer;
