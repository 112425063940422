import React, { useEffect, useMemo } from "react";
import { Button, Card, Row, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MdArrowOutward } from "react-icons/md";
import _ from "lodash";

import { songController } from "../../features/song/songSlice";
import { getConfig } from "../../services/apiUtils";
import SongCard from "../_cards/SongCard";
import { MostPopularSongSkeleton } from "../_skeletons/UISkeletons";
import { ErrorAlert } from "../_alerts/UIAlerts";
import { useNavigate } from "react-router-dom";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";

function MostPopularSongs({
  display = 4,
  heading = 1,
  viewMode = "grid",
  genreType = "all",
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mostPopularSongs, isLoading, error } = useSelector(
    (state) => state.song
  );

  useEffect(() => {
    dispatch(
      songController.getMostPopularSongs({
        payload: genreType !== "all" ? { genre: genreType } : {},
        config: getConfig(),
      })
    ).catch((err) => console.error("Failed to fetch popular songs:", err));
  }, [dispatch, genreType]);

  const randomSongs = useMemo(() => {
    return mostPopularSongs?.songs?.length > 0
      ? [...mostPopularSongs.songs]
          .sort(() => Math.random() - 0.5)
          .slice(0, display)
      : [];
  }, [mostPopularSongs, display]);

  const handleRetry = () => {
    dispatch(
      songController.getMostPopularSongs({
        payload: genreType !== "all" ? { genre: genreType } : {},
        config: getConfig(),
      })
    );
  };

  const HeadingTag = `h${heading}`;

  return (
    <div>
      {isLoading ? (
        viewMode === "grid" ? (
          <></>
        ) : (
          <>
            <MostPopularSongSkeleton />
          </>
        )
      ) : error ? (
        <>
          <ErrorAlert description={error} />
        </>
      ) : randomSongs.length > 0 ? (
        viewMode === "grid" ? (
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-start align-items-center my-1">
                <HeadingTag className="fw-bold">
                  Most Popular {genreType !== "all" ? genreType : ""} Songs
                </HeadingTag>
              </div>
              <Row className="g-4">
                {randomSongs.map((song, index) => (
                  <SongCard
                    key={index}
                    display={4}
                    viewMode="grid"
                    song={song}
                    index={index}
                  />
                ))}
              </Row>
            </Card.Body>
          </Card>
        ) : (
          <Card className="rounded rounded-4 border border-0">
            <Card.Body>
              <div className="d-flex justify-content-start align-items-center my-1">
                <HeadingTag className="fw-bold">
                  Most Popular{" "}
                  {genreType !== "all" ? _.capitalize(genreType) : ""} Songs
                </HeadingTag>
              </div>
              {randomSongs.map((song, index) => (
                <SongCard
                  key={index}
                  display={5}
                  viewMode="list"
                  song={song}
                  index={index}
                />
              ))}
              <div className="ms-auto d-flex align-items-center float-end">
                <span className="fw-bold">See All</span>
                <Button
                  variant="eigengrau"
                  size="sm"
                  className="fw-bold fs-6 mx-2 rounded-circle"
                  onClick={() => navigate(ROUTES.MAIN.SONGS)}
                >
                  <MdArrowOutward />
                </Button>
              </div>
            </Card.Body>
          </Card>
        )
      ) : (
        <p className="text-center">No popular songs available at the moment.</p>
      )}
    </div>
  );
}

export default MostPopularSongs;
