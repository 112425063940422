import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { StaticImages } from "../../../utils/constants";

const SectionHeader = ({ text }) => (
  <div className="fw-bold fs-4 my-3">{text}</div>
);

const SectionContent = ({ children }) => <p>{children}</p>;

function PrivacyPolicyPage() {
  const privacyContent = {
    title: "Privacy Policy",
    lastUpdated: "21 May 2024",
    sections: [
      {
        heading: "Introduction",
        content:
          "We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard data when you interact with our music-related website.",
      },
      {
        heading: "Information Collection",
        content:
          "We may collect personal information such as your name, email address, and preferences when you register, subscribe, or engage with features on our site. Additionally, we may gather non-personal data, like your browsing behavior and device information, to enhance your experience.",
      },
      {
        heading: "Use of Information",
        content:
          "The data we collect is used to personalize your experience, provide customer support, send updates, and improve our services. We may also use aggregated data to analyze site performance and trends.",
      },
      {
        heading: "Cookies and Tracking",
        content:
          "Our website uses cookies and similar tracking technologies to enhance functionality and monitor site traffic. You can control cookie settings through your browser, but disabling them may impact certain features.",
      },
      {
        heading: "Third-Party Services",
        content:
          "We may share limited data with trusted partners to facilitate services like payment processing and email distribution. We ensure these partners adhere to strict data protection policies.",
      },
      {
        heading: "Data Security",
        content:
          "We implement industry-standard security measures to protect your information. However, no method of transmission over the internet is entirely secure, and we cannot guarantee absolute safety.",
      },
      {
        heading: "Your Rights",
        content:
          "You have the right to access, update, or delete your personal data. Contact us at [support email] for assistance or more information.",
      },
      {
        heading: "Policy Updates",
        content:
          "We may update this Privacy Policy periodically. Any changes will be posted on this page with an updated revision date.",
      },
      {
        heading: "Contact Us",
        content:
          "For further questions or concerns, please contact us at [support email].",
      },
    ],
  };

  const seoData = getSEOConfig("privacyPolicyPage");

  return (
    <MainLayout>
      <SEOManager {...seoData} />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <div>
          <Image fluid rounded src={StaticImages.GoogleAds.DesktopAd_H_large} />
        </div>

        <Row className="m-0 p-0">
          <Col lg={9} md={9} sm={12} xs={12}>
            <div className="p-4">
              <h1 className="fw-bold my-4">Privacy Policy</h1>
              <p className="fw-bold">
                Last Updated: {privacyContent.lastUpdated}
              </p>
              <div className="privacy-content">
                {privacyContent.sections.map((section, index) => (
                  <div key={index}>
                    <SectionHeader text={section.heading} />
                    <SectionContent>{section.content}</SectionContent>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={12} xs={12}></Col>
        </Row>
      </Container>
    </MainLayout>
  );
}

export default PrivacyPolicyPage;
