import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "react-bootstrap";
import { MdArrowOutward } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import styles from "./LatestMusicNews.module.css";
import NewsCard from "../_cards/NewsCard";
import { ROUTES } from "../../routes/constants";
import { getConfig } from "../../services/apiUtils";
import { newsController } from "../../features/news/newsSlice";
import { Spin } from "antd";

const LatestMusicNews = ({ genreType = "all", display = 4 }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetching data from Redux store
  const {
    newsList = [],
    isLoading,
    error,
  } = useSelector((state) => state.news || {});

  // Validate `display` to ensure it's a positive integer
  const validDisplay = Number.isInteger(display) && display > 0 ? display : 4;

  // Memoized list of displayed news
  const displayedNews = useMemo(() => {
    return Array.isArray(newsList)
      ? [...newsList]
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .slice(0, validDisplay)
      : [];
  }, [newsList, validDisplay]);

  // Fetch news on mount
  useEffect(() => {
    const fetchNews = async () => {
      try {
        await dispatch(
          newsController.getNewsList({ payload: {}, config: getConfig() })
        );
      } catch (fetchError) {
        console.error("Failed to fetch news:", fetchError);
      }
    };

    fetchNews();
  }, [dispatch]);

  return (
    <div className="my-4">
      {/* Heading */}
      <div className="d-flex my-5">
        <h1 className="fw-bold">
          Latest {genreType !== "all" ? _.capitalize(genreType) : ""} Music News
        </h1>
      </div>

      {/* Loading, error, or displayed news */}
      {isLoading ? (
        <div
          style={{ height: "50vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <Spin tip="Loading..." size="large" />
        </div>
      ) : error ? (
        <div className="alert alert-danger" role="alert">
          Failed to load news. Please try again later.
        </div>
      ) : displayedNews.length > 0 ? (
        <div className={`d-flex overflow-x-scroll ${styles.horizontalScroll}`}>
          {displayedNews.map((news, index) => (
            <NewsCard key={news.id || index} news={news} index={index} />
          ))}
        </div>
      ) : (
        <div className="text-center text-muted">
          No news available at the moment.
        </div>
      )}

      {/* Footer */}
      <div className="ms-auto d-flex align-items-center mt-3">
        <span className="fw-bold">See All</span>
        <Button
          variant="eigengrau"
          size="sm"
          className="fw-bold fs-6 mx-2 rounded-circle"
          onClick={() => navigate(ROUTES.MAIN.NEWS)}
        >
          <MdArrowOutward />
        </Button>
      </div>
    </div>
  );
};

// PropTypes for type safety
LatestMusicNews.propTypes = {
  genreType: PropTypes.string,
  display: PropTypes.number,
};

export default LatestMusicNews;
