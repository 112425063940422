import React from "react";
import {
  Button,
  Card,
  Col,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { motion } from "framer-motion";
import { BsMusicNoteList } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../routes/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { formatStringType } from "../../utils/helpers";
import { StaticImages } from "../../utils/constants";

function PlaylistCard({
  viewMode = "grid",
  playlist,
  index,
  type = "",
  user = "all",
}) {
  const navigate = useNavigate();
  return (
    <>
      {type === "card" ? (
        <>
          <div className="rounded-4 p-2 bg-white overflow-hidden shadow-sm">
             
            <Image
              fluid
              src={playlist?.playlist_image}
              alt={playlist?.playlist_name || `Playlist ${index + 1}`}
              className="w-100 rounded-4"
            // style={{ objectFit: "cover", height: "200px" }}
            />

            <div className="p-3">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id={`tooltip-${playlist?.id}`}
                    className="text-white"
                  >
                    {playlist?.playlist_name || `Playlist ${index + 1}`}
                  </Tooltip>
                }
              >
                <div className="position-relative mb-2">
                  <h6
                    className="fw-bold mb-0 text-truncate"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {playlist?.playlist_name || `Playlist ${index + 1}`}
                  </h6>
                </div>
              </OverlayTrigger>

              <Button
                as={motion.button}
                variant="eigengrau"
                className="fw-bold"
                size="sm"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 20,
                }}
                onClick={() =>
                  navigate(
                    generateDynamicRoute(
                      user === "all"
                        ? ROUTES.MAIN.PLAYLIST_SONGS
                        : ROUTES.MAIN.USER_PLAYLIST_SONGS,
                      {
                        playlistName: formatStringType(
                          playlist?.playlist_name,
                          "kebabCase"
                        ),
                        playlistId: playlist?._id,
                      }
                    )
                  )
                }
              >
                <BsMusicNoteList className="me-2" />
                View Songs
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <motion.div
            whileHover={{
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              transition: { duration: 0.3 },
            }}
            className="p-3 border-0 rounded-3 d-flex flex-row align-items-center"
          >
            <div className="mx-3">
              <Image
                src={playlist.playlist_image}
                height={75}
                width={90}
                rounded
                alt={`Playlist ${playlist?.playlist_name || index + 1}`}
              />
            </div>

            <div className="flex-grow-1 d-flex flex-column justify-content-center">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    {playlist?.playlist_name || `Playlist ${index + 1}`}
                  </Tooltip>
                }
              >
                <Card.Text
                  className="text-truncate fw-semibold my-2 fs-6"
                  as={Col}
                  lg={8}
                  title={playlist?.playlist_name || `Playlist ${index + 1}`} // Tooltip on hover
                >
                  {playlist?.playlist_name || `Playlist ${index + 1}`}
                </Card.Text>
              </OverlayTrigger>

              <div>
                <Button
                  as={motion.button}
                  variant="eigengrau"
                  className="fw-bold"
                  size="sm"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 20,
                  }}
                  onClick={() =>
                    navigate(
                      generateDynamicRoute(
                        user === "all"
                          ? ROUTES.MAIN.PLAYLIST_SONGS
                          : ROUTES.MAIN.USER_PLAYLIST_SONGS,
                        {
                          playlistName: formatStringType(
                            playlist?.playlist_name,
                            "kebabCase"
                          ),
                          playlistId: playlist?._id,
                        }
                      )
                    )
                  }
                >
                  <BsMusicNoteList className="me-2" />
                  View Songs
                </Button>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </>
  );
}

export default PlaylistCard;
