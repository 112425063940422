import React, { useState } from "react";
import { Row, Col, Card, Image, Button } from "react-bootstrap";
import { StaticImages } from "../../utils/constants";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa6";
import { Button as AntButton, Input } from "antd";
import { SendOutlined } from "@ant-design/icons";
import styles from "./FollowUs.module.css";

const FollowUs = () => {
  const [email, setEmail] = useState("");

  const images = [
    { src: StaticImages.FollowUs.Img1, alt: "Media 1" },
    { src: StaticImages.FollowUs.Img2, alt: "Media 2" },
    { src: StaticImages.FollowUs.Img3, alt: "Media 3" },
    { src: StaticImages.FollowUs.Img4, alt: "Media 4" },
    { src: StaticImages.FollowUs.Img5, alt: "Media 5" },
    { src: StaticImages.FollowUs.Img6, alt: "Media 6" },
    { src: StaticImages.FollowUs.Img7, alt: "Media 7" },
    { src: StaticImages.FollowUs.Img8, alt: "Media 8" },
    { src: StaticImages.FollowUs.Img9, alt: "Media 9" },
  ];

  const socialIcons = [
    { icon: <FaYoutube size={20} />, alt: "YouTube" },
    { icon: <FaFacebook size={20} />, alt: "Facebook" },
    { icon: <FaInstagram size={20} />, alt: "Instagram" },
    { icon: <FaTwitter size={20} />, alt: "Twitter" },
  ];

  const handleSubscription = () => {
    console.log("Subscribed Email:", email);
    // Add API call or logic to handle subscription
    alert(`Subscribed with email: ${email}`);
    setEmail(""); // Clear the email field after subscription
  };

  return (
    <Card className="border-0 py-4">
      <Row className="d-flex align-items-stretch g-4">
        {/* Left Side: Media Gallery */}
        <Col
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <Row className="g-3 w-100">
            {images.map((image, index) => (
              <Col key={index} xs={4} sm={4} md={4} lg={4}>
                <Image
                  fluid
                  src={image.src}
                  alt={image.alt}
                  className="rounded-1"
                  style={{ height: "100%", objectFit: "cover" }}
                />
              </Col>
            ))}
          </Row>
        </Col>

        {/* Right Side: Follow Us Section */}
        <Col
          xs={12}
          md={6}
          className="d-flex flex-column justify-content-center text-white p-5 bg-sliceHeaven rounded-4"
        >
          <h2 className="fw-bold my-3 text-center">Follow Us</h2>
          <p className="my-3 text-center">
            Follow us to keep up with the latest from us and our brands, updates
            from our teams, and the daily grind from around the web.
          </p>

          {/* Social Media Icons */}
          <div className="d-flex justify-content-center gap-3 my-3">
            {socialIcons.map((social, index) => (
              <Button
                key={index}
                variant="electricBlue"
                className="py-2 text-white"
                aria-label={social.alt}
              >
                {social.icon}
              </Button>
            ))}
          </div>

          {/* Subscription Form */}
          <div className="my-3">
            <p>Subscribe Now</p>
            <div>
              <Input
                placeholder="Enter your email"
                size="large"
                suffix={
                  <AntButton
                    color="default"
                    variant="text"
                    onClick={handleSubscription}
                  >
                    <SendOutlined />
                  </AntButton>
                }
              />
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default FollowUs;
