import React from "react";
import { Container, Image } from "react-bootstrap";

import MainLayout from "../../../layouts/MainLayout";
import TopPlaylists from "../../../components/_sections/TopPlaylists";
import ExplorePlaylists from "../../../components/_sections/ExplorePlaylists";
import GenresSection from "../../../components/_sections/GenresSection";
import TopBar from "../../../components/_buttons/TopBar";
import { StaticImages } from "../../../utils/constants";

function PlaylistsPage() {
  return (
    <>
      <MainLayout>
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>

          <div className="m-2">
            <Image
              fluid
              src={StaticImages.GoogleAds.DesktopAd_H_large}
              className="w-100 rounded-3"
              alt="Home Banner"
            />
          </div>

          <div className="m-2">
            <TopPlaylists />
          </div>

          <div className="m-2">
            <ExplorePlaylists />
          </div>

          <div className="m-2">
            <Image
              fluid
              src={StaticImages.GoogleAds.DesktopAd_H_small}
              className="w-100 rounded-3"
              alt="Home Banner"
            />
          </div>

          <div className="m-2">
            <GenresSection background="sliceHeaven" color="ghostWhite" />
          </div>
        </Container>
      </MainLayout>
    </>
  );
}

export default PlaylistsPage;
