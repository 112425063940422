import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Pagination, Container, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { StaticImages } from "../../../utils/constants";
import { albumController } from "../../../features/album/albumSlice";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import TopAlbums from "../../../components/_sections/TopAlbums";
import ExploreAlbums from "../../../components/_sections/ExploreAlbums";
import TopBar from "../../../components/_buttons/TopBar";

export const ads = [
  StaticImages.GoogleAds.Ad1,
  StaticImages.GoogleAds.Ad4,
  StaticImages.GoogleAds.Ad17,
  StaticImages.GoogleAds.Ad18,
];

export const hotAlbumImages = [
  StaticImages.Album.Img1,
  StaticImages.Album.Img2,
  StaticImages.Album.Img3,
  StaticImages.Album.Img4,
];

const AlbumsPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { albumDetails } = location.state || {};

  const { albumId, albumName } = useParams();
  const { albumList, isAlbumLoading, error } = useSelector(
    (state) => state.album
  );

  const [currentPage, setCurrentPage] = useState(1);

  const fetchAlbums = async (page) => {
    if (albumId && page > 0) {
      try {
        await dispatch(
          albumController.getAlbumsList({
            payload: { albumId: albumId, page },
            config: {},
          })
        );
      } catch (err) {
        console.error("Error Fetching Albums:", err);
      }
    }
  };

  useEffect(() => {
    fetchAlbums(currentPage);
  }, [albumId, currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const seoData = getSEOConfig("albumPage", { albumName, albumId, albumList });

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <div className="m-2">
          <Image
            fluid
            src={StaticImages.GoogleAds.DesktopAd_H_large}
            className="w-100 rounded-3"
            alt="Home Banner"
          />
        </div>
        <div className="m-2">
          <TopAlbums />
        </div>
        <div className="m-2">
          <ExploreAlbums />
        </div>
        <div className="m-2">
          <Image
            fluid
            src={StaticImages.GoogleAds.DesktopAd_H_large}
            className="w-100 rounded-3"
            alt="Home Banner"
          />
        </div>
      </Container>
    </MainLayout>
  );
};

export default AlbumsPage;
