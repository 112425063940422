import { createSlice } from "@reduxjs/toolkit";
import { songThunks } from "./songThunks";

const initialState = {
  mostPopularSongs: null,
  recommendedSongs: null,
  exploreSongs: null,
  topSongs: null,
  newReleaseSongs: null,
  isLoading: false,
  error: null,
};

const songSlice = createSlice({
  name: "song",
  initialState,
  reducers: {
    resetMostPopularSongs: (state) => {
      state.mostPopularSongs = null;
    },
    resetRecommendedSongs: (state) => {
      state.recommendedSongs = null;
    },
    resetExploreSongs: (state) => {
      state.exploreSongs = null;
    },
    resetTopSongs: (state) => {
      state.topSongs = null;
    },
    resetNewReleasedSongs: (state) => {
      state.newReleaseSongs = null;
    },
  },
  extraReducers: (builder) => {
    // Generic handlers for pending, fulfilled, and rejected states
    const handlePending = (state) => {
      state.isLoading = true;
      state.error = null;
    };

    const handleRejected = (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    };

    const handleFulfilled = (state, action, key) => {
      state.isLoading = false;
      state[key] = action.payload;
    };

    // Add cases for thunks
    builder
      // Get Most Popular Songs
      .addCase(songThunks.getMostPopularSongs.pending, handlePending)
      .addCase(songThunks.getMostPopularSongs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.mostPopularSongs = action.payload.data;
      })
      .addCase(songThunks.getMostPopularSongs.rejected, handleRejected)
      // Get Recommended Songs
      .addCase(songThunks.getRecommendedSongs.pending, handlePending)
      .addCase(songThunks.getRecommendedSongs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recommendedSongs = action.payload.data;
      })
      .addCase(songThunks.getRecommendedSongs.rejected, handleRejected)
      // Get Explore Songs
      .addCase(songThunks.getExploreSongs.pending, handlePending)
      .addCase(songThunks.getExploreSongs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.exploreSongs = action.payload.data;
      })
      .addCase(songThunks.getExploreSongs.rejected, handleRejected)
      // Get Top Songs
      .addCase(songThunks.getTopSongs.pending, handlePending)
      .addCase(songThunks.getTopSongs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.topSongs = action.payload.data.topSongsList;
      })
      .addCase(songThunks.getTopSongs.rejected, handleRejected);
    // Get New Releases Songs
    builder
      .addCase(songThunks.getNewReleaseSongs.pending, handlePending)
      .addCase(songThunks.getNewReleaseSongs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.newReleaseSongs = action.payload.data.newReleaseList;
      })
      .addCase(songThunks.getNewReleaseSongs.rejected, handleRejected);
  },
});

/* Export actions and thunks */
export const {
  resetMostPopularSongs,
  resetRecommendedSongs,
  resetExploreSongs,
  resetTopSongs,
} = songSlice.actions;

export const songController = songThunks;

export default songSlice.reducer;
