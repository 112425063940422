// src/routes/commonRoutes.js
import React from "react";
import { Route } from "react-router-dom";
import { ROUTES } from "./constants";

/* Main Pages */
import HomePage from "../pages/common/HomePage/HomePage";
import FilterGenrePage from "../pages/common/FilterGenrePage/FilterGenrePage";
import ContactUsPage from "../pages/common/ContactUsPage/ContactUsPage";
import AboutUsPage from "../pages/common/AboutUsPage/AboutUsPage";
import HelpPage from "../pages/common/HelpPage/HelpPage";
import PrivacyPolicyPage from "../pages/common/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsOfUsePage from "../pages/common/TermsOfUsePage/TermsOfUsePage";
import PlaylistsPage from "../pages/common/PlaylistsPage/PlaylistsPage";
import AlbumsPage from "../pages/common/AlbumsPage/AlbumsPage";
import NewsPage from "../pages/common/NewsPage/NewsPage";
import NewsDetailPage from "../pages/common/NewsDetailPage/NewsDetailPage";
import GenresPage from "../pages/common/GenresPage/GenresPage";
import SongsPage from "../pages/common/SongsPage/SongsPage";
import ArtistsPage from "../pages/common/ArtistsPage/ArtistsPage";

import NotFoundPage from "../pages/common/NotFoundPage/NotFoundPage";
import MaintenancePage from "../pages/common/MaintenancePage/MaintenancePage";
import AddNewSongLyricsPage from "../pages/common/AddNewSongLyricsPage/AddNewSongLyricsPage";
import FilterArtistsPage from "../pages/common/FilterArtistsPage/FilterArtistsPage";
import ViewSongLyricsPage from "../pages/common/ViewSongLyricsPage/ViewSongLyricsPage";
import PlaylistSongsPage from "../pages/common/PlaylistSongsPage/PlaylistSongsPage";
import AlbumSongsPage from "../pages/common/AlbumSongsPage/AlbumSongsPage";
import SearchResultPage from "../pages/common/SearchResultPage/SearchResultPage";
import QuizPage from "../pages/common/QuizPage/QuizPage";
import ViewArtistBioPage from "../pages/common/ViewArtistBioPage/ViewArtistBioPage";
import UserMyPlaylistPage from "../pages/common/UserMyPlaylistPage/UserMyPlaylistPage";
import UserPlaylistSongsPage from "../pages/common/UserPlaylistSongsPage/UserPlaylistSongsPage";

const mainRoutes = [
  <Route key="home" path={ROUTES.MAIN.HOME} element={<HomePage />} />,
  <Route key="home" path={ROUTES.MAIN.HOME_} element={<HomePage />} />,
  <Route
    key="about-us"
    path={ROUTES.MAIN.ABOUT_US}
    element={<AboutUsPage />}
  />,
  <Route
    key="contact-us"
    path={ROUTES.MAIN.CONTACT_US}
    element={<ContactUsPage />}
  />,
  <Route
    key="privacy-policy"
    path={ROUTES.MAIN.PRIVACY_POLICY}
    element={<PrivacyPolicyPage />}
  />,
  <Route
    key="terms-of-use"
    path={ROUTES.MAIN.TERMS_OF_USE}
    element={<TermsOfUsePage />}
  />,
  <Route key="help" path={ROUTES.MAIN.HELP} element={<HelpPage />} />,
  <Route
    key="playlists"
    path={ROUTES.MAIN.PLAYLISTS}
    element={<PlaylistsPage />}
  />,
  <Route key="albums" path={ROUTES.MAIN.ALBUMS} element={<AlbumsPage />} />,
  <Route
    key="filter-genres"
    path={ROUTES.MAIN.FILTER_GENRES}
    element={<FilterGenrePage />}
  />,
  <Route key="genres" path={ROUTES.MAIN.GENRES} element={<GenresPage />} />,
  <Route key="news" path={ROUTES.MAIN.NEWS} element={<NewsPage />} />,
  <Route key="songs" path={ROUTES.MAIN.SONGS} element={<SongsPage />} />,
  <Route key="artists" path={ROUTES.MAIN.ARTISTS} element={<ArtistsPage />} />,
  <Route
    key="artists-list"
    path={ROUTES.MAIN.ARTISTS_LIST}
    element={<ArtistsPage />}
  />,
  <Route
    key="news-details"
    path={ROUTES.MAIN.NEWS_DETAILS}
    element={<NewsDetailPage />}
  />,
  <Route
    key="add-new-song-lyrics"
    path={ROUTES.MAIN.ADD_NEW_SONG_LYRICS}
    element={<AddNewSongLyricsPage />}
  />,
  <Route
    key="filter-artists"
    path={ROUTES.MAIN.FILTER_ARTISTS}
    element={<FilterArtistsPage />}
  />,
  <Route
    key="view-song-lyrics"
    path={ROUTES.MAIN.VIEW_SONG_LYRICS}
    element={<ViewSongLyricsPage />}
  />,
  <Route
    key="playlist-songs"
    path={ROUTES.MAIN.PLAYLIST_SONGS}
    element={<PlaylistSongsPage />}
  />,
  <Route
    key="album-songs"
    path={ROUTES.MAIN.ALBUM_SONGS}
    element={<AlbumSongsPage />}
  />,
  <Route
    key="search"
    path={ROUTES.MAIN.SEARCH_PAGE}
    element={<SearchResultPage />}
  />,
  <Route key="quiz" path={ROUTES.MAIN.QUIZ} element={<QuizPage />} />,
  <Route
    key="artist-bio"
    path={ROUTES.MAIN.VIEW_ARTIST_BIO}
    element={<ViewArtistBioPage />}
  />,
  <Route path={ROUTES.MAIN.USER_PLAYLISTS} element={<UserMyPlaylistPage />} />,
  <Route
    path={ROUTES.MAIN.USER_PLAYLIST_SONGS}
    element={<UserPlaylistSongsPage />}
  />,

  // Maintenance and temporary routes
  <Route
    key="maintenance"
    path={ROUTES.MAIN.MAINTENANCE}
    element={<MaintenancePage />}
  />,
  <Route
    key="not-found"
    path={ROUTES.MAIN.NOT_FOUND}
    element={<NotFoundPage />}
  />,
];

export default mainRoutes;
